import { customerRoutes, customerRedirects } from './customer-routes'
import manseRoutes from './manse-routes'
import MansePage from '@/pages/manse/index.vue'
import MypagePage from '@/pages/mypage/inquiryView.vue'
import MypagePaymentPage from '@/pages/mypage/subscrPaymentView.vue'
import ManseAppFive from '@/pages/manse_app_five/index.vue'
import ManseApp from '@/pages/manse_app/index.vue'
import AnalysisPage from '@/pages/analysis/index.vue'

export const redirects = [...customerRedirects]
export const routes = [
  {
    path: '/manse/:id/:pgmode',
    component: MansePage,
    meta: {
      layout: 'manse',
    },
  },
  {
    path: '/manse/:id',
    component: MansePage,
    meta: {
      layout: 'manse',
    },
  },
  {
    path: '/manse',
    component: MansePage,
    meta: {
      layout: 'manse',
    },
  },
  {
    path: '/manse_app_five',
    component: ManseAppFive,
    meta: {
      layout: 'empty',
    },
  },
  {
    path: '/manse_app_five/:id',
    component: ManseAppFive,
    meta: {
      layout: 'empty',
    },
  },
  {
    path: '/manse_app',
    component: ManseApp,
    meta: {
      layout: 'empty',
    },
  },
  {
    path: '/manse_app/:id',
    component: ManseApp,
    meta: {
      layout: 'empty',
    },
  },
  {
    path: '/analysis/:id',
    component: AnalysisPage,
    meta: {
      layout: 'analysis',
    },
  },
  {
    path: '/mypage/inquiryView/:boardId/:id',
    component: MypagePage,
    meta: {
      layout: 'mypage',
    },
  },
  {
    path: '/mypage/subscrPaymentView/:id',
    component: MypagePaymentPage,
    meta: {
      layout: 'mypage',
    },
  },
  ...manseRoutes,
  ...customerRoutes,
  {
    path: '/schedule_app',
    component: () => import('@/pages/schedule_app/index.vue'),
  },
]
