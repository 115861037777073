<script setup>
import useMenuLinks from '@/composables/useMenuLinks'
import useGoLink from '@/composables/useGoLink'
import useAuth from '@/composables/useAuth'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

import { computed } from 'vue'
import { useRoute } from 'vue-router'

import GnbMypageMenu from '@/components/GnbMypageMenu.vue'
import { useMyInfoStore } from '@/store/modules/myinfo'
import { usePaymentsStore } from '@/store/modules/payments'
import ConfirmComponent from '@/pages/manse/components/Confirm.vue'
import Snackbar from './components/Snackbar.vue'
import { addContentLogAPI, getLogStatsAPI } from '@/api/logServiceApi'
import { useLogsStore } from '@/store/modules/logsStore'

const route = useRoute()
const myInfoStore = useMyInfoStore()
const paymentsStore = usePaymentsStore()
const logsStore = useLogsStore()

const currentPath = computed(() => route.path)

const props = defineProps({
  iconTheme: {
    type: String,
    default: null,
  },
  menuTheme: {
    type: String,
    default: null,
  },
})

const { menuLinks } = useMenuLinks()
const { goLink } = useGoLink()
const { userInfo } = useAuth()
const store = useStore()
const router = useRouter()

const dialogAlert = ref(null)
const dialogTitle = ref('')
const dialogTitle2 = ref('')
const dialogText = ref('')
const dialogNextUrl = ref(null)

const dialogConfrim = ref(null)

const snackbar = ref(null)

const hoverLayer = ref('')

onMounted(async () => {
  await myInfoStore.fetchMyInfo()
  if (!logsStore.logContents) {
    const result = await getLogStatsAPI();
    logsStore.setLogContents(result);
  }
})

const logOut = async () => {
  // if (!window.confirm('로그아웃 하시겠습니까?')) {
  //   return
  // }
  try {
    await store.dispatch('user/logout')
    // alert('로그아웃 되었습니다.')
    await router.push('/auth/login')
    myInfoStore.resetSelectedOption()
    paymentsStore.clearPaymentsInfo()
  } catch (error) {
    alert(error.message)
  }

  //   showDialogConfirm(
  //   '로그아웃 하시겠습니까?',
  //   '',
  //   '확인',
  //   async () => {
  //     try {
  //       await store.dispatch('user/logout')
  //       await router.push('/auth/login')
  //       // triggerSnackbar('로그아웃 되었습니다')
  //     } catch (error) {
  //       alert(error.message)
  //     }
  //   }
  // )
}
const goLogout = () => {
  if (hoverLayer.value === 'logout') {
    hoverLayer.value = ''
  } else {
    hoverLayer.value = 'logout'
  }
}
const goLogin = () => {
  if (hoverLayer.value === 'login') {
    hoverLayer.value = ''
  } else {
    hoverLayer.value = 'login'
  }
}
const login = () => {
  router.push('/auth/login')
}
const goLinkDo = (pathUrl) => {
  router.push(pathUrl)
}
const join = () => {
  router.push('/auth/join')
}

const goMain = () => router.push('/main')
/** iconTheme 에 따라 로고 변경 */
const logoClass = computed(() => (props.iconTheme === 'WHITE' ? '' : 'hd__logo_b'))

/** menuTheme 에 따라 메뉴 색상 변경 */
const menuClass = computed(() => (props.menuTheme === 'WHITE' ? 'white' : ''))

const menu1 = ref(false)
const menu2 = ref(false)

/** 메뉴 active 로직 변경 */
// 상세보기에서도 저장목록에 active 스타일이 들어오도록
const isActive = (menuLink) => {
  const currentPath = route.path
  if (menuLink.link === '/saju') {
    // 현재 경로가 "/manse/:id/rs"이거나 "/saju"인 경우 active 처리
    return (
      (currentPath.startsWith('/manse/') && currentPath.endsWith('/rs')) || currentPath === '/saju'
    )
  } else if (menuLink.link === '/customer/notice') {
    return currentPath.startsWith('/customer') || currentPath.startsWith('/essay')
  }
  return currentPath === menuLink.link
}

/** 충전버튼 클릭시 페이지 이동 */
const goToChargePage = () => {
  router.push('/mypage/subscrPoint')
}

/** 컨펌다이얼로그 */
const showDialogConfirm = (title, title2 = '', text = '', onConfirm = () => {}) => {
  dialogTitle.value = title
  dialogTitle2.value = title2
  dialogText.value = text
  dialogNextUrl.value = onConfirm // 함수로 전달

  dialogConfrim.value.openConfirmDialog()
}

/** 스낵바 */
function triggerSnackbar(message) {
  snackbar.value.showSnackbar(message)
}

const menuMap = {
  "/main": "G_home",
  "/calendar": "G_calendar",
  "/manse": "G_manse",
  "/saju": "G_member",
  "/customer/notice": "G_nangman_center",
  "/guide/info": "G_service",
};
const handleMenuClick = async (menu) => {
  addContentLogAPI(menuMap[menu.link]);
}
</script>

<template>
  <div class="header-wrap">
    <div class="inner-base pc">
      <h1 :class="['hd__logo', logoClass]" @click="goMain">만세력</h1>
      <div class="nav">
        <ul class="nav-list" :class="menuClass">
          <li
            v-for="(menu, key) in menuLinks"
            :key="key"
            :class="[menuClass, { active: isActive(menu) }]"
          >
            <router-link
              :to="menu.link"
              :class="menuClass + ' ' + (menu?.className || '')"
              @click="handleMenuClick(menu)"
            >
              {{ menu.name }}
            </router-link>
            <!-- <ul class="sub-menu" v-if="menu.subMenu.length > 0">
              <li v-for="(subMenu, subKey) in menu.subMenu" :key="subKey">
                <a
                  :href="subMenu.link"
                  @click.prevent="goLink(subMenu.link)"
                  >{{ subMenu.name }}</a
                >
              </li>
            </ul> -->
          </li>
          <li>
            <VMenu v-model="menu2" transition="slide-y-transition" v-if="userInfo?.name">
              <template #activator="{ props }">
                <VBtn v-bind="props" class="btn-none" variant="text">
                  <i class="icon-mypage">로그아웃</i>
                </VBtn>
              </template>
              <VCard max-width="300" style="width: 220px">
                <div class="login-msg-box">
                  <div class="txt-box">
                    <span
                      ><span class="txt1" style="margin-right: 2px">{{
                        myInfoStore.displayName
                      }}</span
                      >님</span
                    ><span class="txt2">행복한 하루 되세요</span>
                  </div>
                  <!-- <div class="point-box st-member">
                    <i class="icon-point"></i>
                    <span class="current-point">0 P</span>
                    <button @click.stop="goToChargePage" class="charge-btn">충전</button>
                  </div> -->
                  <GnbMypageMenu />
                  <button class="btn-xs line" @click="logOut">로그아웃</button>
                </div>
              </VCard>
            </VMenu>
            <VMenu v-model="menu1" transition="slide-y-transition" v-else>
              <template #activator="{ props }">
                <!-- v-bind="props"-->
                <VBtn class="btn-none" variant="text" @click="login">
                  <i class="icon-mypage">로그인</i>
                </VBtn>
              </template>
              <!-- <VCard max-width="300" style="width: 220px; height: 280px; overflow: hidden">
                <VList></VList>
                <div class="login-msg-box">
                  <div class="txt-box">
                    <span><span class="txt1">게스트</span>님</span>
                    <span class="txt2">행복한 하루 되세요</span>
                  </div>
                  <div class="btn-box">
                    <button class="btn-xs" @click="login">로그인</button>
                    <button class="btn-xs line" @click="join">회원가입</button>
                  </div>
                  <div class="point-box">
                    <i class="icon-point"></i>
                    <span>회원가입 시, <br />낭만 포인트 100% 지급!</span>
                  </div>
                  <GnbMypageMenu />
                </div>
              </VCard> -->
            </VMenu>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <ConfirmComponent
    ref="dialogConfrim"
    :title="dialogTitle"
    :title2="dialogTitle2"
    :text="dialogText"
    :nextUrl="dialogNextUrl"
  />
  <Snackbar ref="snackbar" />
</template>
<style>
.v-btn.btn-none {
  background: transparent !important;
  width: auto !important;
  height: auto !important;
  box-shadow: none !important;
  padding: 0 !important;
}
.v-btn.btn-none:hover,
.v-btn.btn-none:focus {
  background: transparent !important;
}
</style>
