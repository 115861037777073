<script setup>
import { onMounted, watch } from 'vue'
import { useStore } from 'vuex'
import moment from 'moment'

import { DEV_MODE } from '@/main'

/** API */
import { getDailyFortuneDetailAPI, getDailyFortunePriceAPI, purchaseDailyCategoryAPI, purchaseDailyFortuneAPI, getDailyCategoryFreeCountAPI } from '@/api/calendarApi'

/** pinia */
import { usePaymentsStore } from '@/store/modules/payments'

/** composables */
import fiveMixin from '@/composables/fiveMixin'
import useFunctions from '@/composables/useFunctions'
import { useCalendar, useCalendarFilter, useGesture } from '@/composables'
import { useSnackbar } from '@/composables/useSnackbar'
import { useScrollLock } from '@/composables/useScrollLock'
import Snackbar from '@/components/common/Snackbar.vue'
import { useDailyFortune } from '@/composables/calendar/useDailyFortune'
import { useMySaju } from '@/composables/calendar/useMySaju'
import { createYinYangCount } from '@/composables/useSajuFunction'

/** 컴포넌트 */
import FullCalendar from '@fullcalendar/vue3'
import SubscribeInfo from '@/components/calendar/SubscriptionInfo.vue'
import DailyFortuneDrawer from '@/components/calendar/drawer/DailyFortuneDrawer.vue'
import SajuClockPopup from '@/components/calendar/SajuClockPopup.vue'
import CalendarSidebar from '@/components/calendar/CalendarSidebar.vue'
import CalendarHeader from '@/components/calendar/CalendarHeader.vue'
import PopMySaju from '@/components/calendar/PopMySaju.vue'
import AlertComponent from '@/pages/manse/components/Alert.vue'
import ConfirmComponent from '@/pages/manse/components/Confirm.vue'
import PointConfirm from '@/components/calendar/PointConfirm.vue'
import LoadingIndicator from '@/components/common/LoadingIndicator.vue'

/** 스타일 */
import '@/assets/nangman_style/calendar/calendar_web.scss'
import { addContentLogAPI } from '@/api/logServiceApi'

definePage({ meta: { layout: 'manse' } })

const store = useStore()
const userId = ref(null)
userId.value = store.getters['user/id']

const paymentsStore = usePaymentsStore();

const route = useRoute();
const router = useRouter();

const { getYinYang, getSkyStemFive, getEarthBranchFive, getFiveClass, getGanjiColorFive, ...five } = fiveMixin();
const { formatNumber } = useFunctions();

/** 로딩 인디케이터 상태 */
const isLoading = ref(false);

const isDailyFortuneLoading = ref(false);

const isDailyFortuneLoaded = ref(false);

const isCategoryLoading = ref(false);

/** 결제 API 응답 대기 상태 */
const isPurchasePending = ref(false);

// /** '내 사주' 로딩 상태 */
// const isMySajuLoading = ref(false);
/** '사주시계' 팝업 상태 */
const isPopSajuClock = ref(false);
/** '사주시계' 참조 */
const currentClockRef = ref(null);
/** alert 참조 */
const dialogAlertRef = ref(null);
/** confirmAlert 참조 */
const dialogConfirmRef = ref(null);
/** 포인트 결제 Alert 참조 */
const pointConfirmRef = ref(null);
const dialogTitle = ref('');
const dialogTitle2 = ref('');
const dialogSubtitle = ref('');
const dialogText = ref('');
const dialogNextUrl = ref(null);

/** 일진달력 선택 여부 - true: 일진달력, false: 역학달력 */
const isDailyFortuneCalendarOpen = ref(false)

/** 일진보기 drawer 열림 상태 */
const isDailyFortuneViewOpen = ref(false);

/** 등록된 '내 사주' 데이터 */
const sajuData = ref({});
/** 내 사주 정보 유무 확인 */
const isSajuDataExist = computed(() => Object.keys(sajuData.value).length !== 0);

const selectedDateInfo = ref(null)
const dailyLuckData = ref(null);
/** 일운보기 상세 데이터 */
// const dailyFortuneDetail = ref(null);

/** 일진달력 포인트 가격 조회 */
const getDailyFortunePrice = async () => {
  try {
    const result = await getDailyFortunePriceAPI();
    if (result) {
      dailyFortunePrice.value = result.day;
      categoryPrice.value = result.category;
      monthlyFortunePrice.value = result.month;
    }
  } catch (e) {
    console.error('일진달력 서비스별 가격 조회 실패:', e);
  }
}

const errorMessage = ref('');
const retryOpenInnerViewData = ref({info: null, actionType: null});
const retryOpenInnerView = () => openInnerView(retryOpenInnerViewData.value);

/** 날짜 클릭 시 이너뷰 열기 */
const openInnerView = async ({info, actionType}) => {
  retryOpenInnerViewData.value = {info, actionType};

  if (info === null) return;
  if (!userId.value) return;

  const date = actionType === 'opened' ? info.day : info.date;
  const dataKey = moment(date).format('YYYYMMDD');
  const yyyymmdd = moment(date).format('YYYY-MM-DD');
  
  if (info) selectedDateInfo.value = date;
  if (isDailyFortuneViewOpen) {
    try {      
      if (actionType === 'opened') {
        isDailyFortuneViewOpen.value = true;
        getDailyCategoryFreeCount(yyyymmdd);
        await getDailyFortuneDetail({yyyymmdd});
      } else if (actionType !== 'past') {
        // monthlyLuckData.value[dataKey].isOpened = true;
        // isDailyFortuneViewOpen.value = true;
        calendarApi.value.render();
      } else {
        showPointConfirm({
          title: moment(date).format('YYYY년 MM월 DD일'),
          subtitle: '운세를 구매 하시겠습니까?',
          point: formatNumber(dailyFortunePrice.value),
          confirmText: '구매',
          onConfirm: async () => await confirmPurchaseFortune({yyyymmdd, dataKey}),
        })
      }
    } catch (e) {
      
    }
  }
};

/** 날짜별 무료 열람 가능한 카테고리 갯수 요청 */
const getDailyCategoryFreeCount = async (yyyymmdd) => {
  const params = {
    activeDate: yyyymmdd,
  }

  try {
    const result = await getDailyCategoryFreeCountAPI(params);
    freeCategoryCount.value = result;
  } catch (e) {
    console.error('카테고리 별 운세 무료 카운트 조회 실패:', e);
  }
}

/** 일운보기 상세 데이터 요청 */
const getDailyFortuneDetail = async ({yyyymmdd, displayLoading = true}) => {
  if (displayLoading) {
    isDailyFortuneLoading.value = true;
    isDailyFortuneLoaded.value = false;
  }

  const params = {
    activeDate: yyyymmdd,
  };

  try {
    const result = await getDailyFortuneDetailAPI(params);
    
    if (result) {
      dailyFortuneDetail.value = result.data;
      dailyCategoryStatus.value = result.status;
      isDailyFortuneViewOpen.value = true;
      isDailyFortuneLoaded.value = true;
      retryOpenInnerViewData.value = {info: null, actionType: null};
    } else {
      isDailyFortuneLoaded.value = false;
    }
  } catch (e) {
    DEV_MODE && console.error('일운보기 상세 데이터 조회 실패:', e);
    errorMessage.value = e?.response?.data.detail || e;
    isDailyFortuneLoaded.value = false;
  } finally {
    isDailyFortuneLoading.value = false;
    isCategoryLoading.value = false;
  }
}

/** 이너뷰 닫기 */
const closeInnerView = () => {
  if (isDailyFortuneViewOpen.value) isDailyFortuneViewOpen.value = false;
};



/** 날짜 별 일진 차감 포인트 */
const dailyFortunePrice = ref(60);
/** '카테고리 별 운세' 차감될 포인트 */
const categoryPrice = ref(20);
/** 달 전체 일진 차감 포인트 */
const monthlyFortunePrice = ref(1000);

const displayPoint = ref('');
const showPointConfirm = ({title, subtitle, point, confirmText, onConfirm = () => {}}) => {
  dialogTitle.value = title;
  dialogSubtitle.value = subtitle;
  dialogText.value = confirmText;
  displayPoint.value = point;
  dialogNextUrl.value = onConfirm;
  pointConfirmRef.value.openConfirmDialog();
}

/** 확인 알림창 표시 */
const showDialogAlert = ({title, text = '', nextUrl = ''}) => {
  dialogTitle.value = title;
  dialogText.value = text;
  dialogAlertRef.value.openAlertDialog();
};

/** 확인/취소 알림창 표시 */
const showDialogConfirm = ({title, title2 = '', text = '', onConfirm = () => {}}) => {
  dialogTitle.value = title
  dialogTitle2.value = title2
  dialogText.value = text
  dialogNextUrl.value = onConfirm // 함수로 전달
  dialogConfirmRef.value.openConfirmDialog()
};

/** 로그인 알림창 표시 */
const showLoginConfirm = () => {
  showDialogConfirm({
    title: '로그인 하시면',
    title2: '일진을 보실 수 있습니다.',
    text: '로그인 하러 가기',
    onConfirm: () => router.push({
      path: '/auth/login',
      query: { to: route.fullPath}
    }),
  });
};

/** 내 사주 등록 알림창 표시 */
const showSelectConfirm = () => {
  showDialogConfirm({
    title: '내 사주 등록 후,',
    title2: '일진을 보실 수 있습니다.',
    text: '내 사주 등록',
    onConfirm: () => openPopMySaju()
  });
}

/** 달력 필터 */
const {
  isLunarChecked,
  isSeasonChecked,
  isHolidayChecked,
  isKorChecked,
  isColorChecked,
  toggleOptions,
} = useCalendarFilter({isKorCheckedDefault: true});

/** 달력 생성 */
const {
  calendarApi,
  calendarOptions,
  refCalendar,
  viewTitle,
  selectedDate,
  selectedData,
  firstSeason,
  firstSeasonMonth,
  prev,
  next,
  isToday,
  changeViewTitle,
  setPrevSelectedDate,
  getDailyFortuneCalendar,
} = useCalendar({
  isLoading,
  isLunarChecked,
  isColorChecked,
  isSeasonChecked,
  isKorChecked,
  isHolidayChecked,
  calendarType: 'web',
  isDailyFortuneViewOpen,
  isDailyFortuneCalendarOpen,
  userId,
  sajuData,
  isSajuDataExist,
  dailyFortunePrice,
  openInnerView,
  showDialogAlert,
  showLoginConfirm,
  showSelectConfirm,
});

/** 통합 제스처 핸들러 */
const {
  handleStart,
  handleMove,
  handleEnd
} = useGesture({
  onSwipeLeft: next,
  onSwipeRight: prev,
});

/** '내 사주' 등록/관리 로직 */
const {
  isMySajuLoading,
  isPopMySajuOpen,
  mySajuList,
  selectedMySajuId,
  getMySaju,
  getMySajuList,
  openPopMySaju,
  closePopMySaju,
  selectMySaju,
  confirmSelectSaju,
  setMySaju,
  goManse,
} = useMySaju({
  calendarApi,
  userId,
  sajuData,
  showDialogConfirm,
  showLoginConfirm,
});

const purchaseDailyFortune = async ({yyyymmdd, purchaseType, callback = async () => {}}) => {
  isPurchasePending.value = true;

  const [year, month, day] = yyyymmdd.split('-');
  const params = {
    year, // 필수
    month,  // 필수
    purchaseType, // "DAY", "MONTH" (필수)
    ...(purchaseType === "DAY" && { day }), // purchaseType: "DAY" 일 때
  }

  try {
    const result = await purchaseDailyFortuneAPI(params);    

    if (result) {
      paymentsStore.getOwnPoint();
      triggerSnackbar('구매가 완료되었습니다.');
      await getDailyFortuneCalendar(`${year} ${month}`);
      await callback();
      calendarApi.value.render();
    }
  } catch (e) {
    console.error('결제 실패:', e.response.data.detail);
    handlePurchaseError(e);
  } finally {
    isPurchasePending.value = false;
  }
}

const handlePurchaseError = (e) => {
  if (e.response.data.detail === '포인트가 부족합니다.') {
    showRechargeConfirm();
  }
}

/** 포인트 충전 팝업 */
const showRechargeConfirm = () => {
  showDialogConfirm({
    title: '보유 낭만 포인트가 부족합니다.',
    title2: '지금 바로 충전하시겠습니까?',
    text: '충전하기',
    onConfirm: () => router.push('/mypage/subscrPoint'),
  });
}

/** 달 전체 일진 구매 */
const purchaseMonthly = () => {
  // 로그인 체크
  if (!userId.value) {
    showLoginConfirm();
    return;
  }
  // 내 사주 체크
  if (!isSajuDataExist.value) {
    showSelectConfirm();
    return;
  }

  const currentDate = refCalendar.value.calendar.currentData.currentDate;
  
  showPointConfirm({
    title: moment(currentDate).format('YYYY년 MM월'),
    subtitle: '운세를 구매 하시겠습니까?',
    point: formatNumber(monthlyFortunePrice.value),
    confirmText: '구매',
    onConfirm: async () => {          
      await purchaseDailyFortune({
        yyyymmdd: moment(currentDate).format('YYYY-MM-DD'),
        purchaseType: 'MONTH',
      });
    },
  })
}

/** '카테고리 별 운세' 구매 */
const purchaseDailyCategory = async ({yyyymmdd, category, categoryPrice}) => {
  isPurchasePending.value = true;

  const params = {
    activeDate: yyyymmdd,  // yyyy-mm-dd
    category, // "STUDY", "WORK", "WEALTH", "LOVE", "HEALTH", "FAMILY", "MOVE", "RELATIONSHIP", "FAME", "SOCIAL"
    categoryPrice,  // 차감 포인트
  }

  try {
    const result = await purchaseDailyCategoryAPI(params);    
    if (result) {
      paymentsStore.getOwnPoint();
      triggerSnackbar('운세가 구매되었습니다.');
      await getDailyFortuneDetail({yyyymmdd, displayLoading: false});
      await getDailyCategoryDetail(moment(selectedDateInfo.value).format('YYYY-MM-DD'), category)
      setSelectedCategory(category);
    }
  } catch (e) {
    DEV_MODE && console.error('카테고리 별 운세 구매 실패', e);
    handlePurchaseError(e);
  } finally {
    isPurchasePending.value = false;
  }
}

/** 일운보기 결제 */
const confirmPurchaseFortune = async ({yyyymmdd, dataKey}) => {  
  // 결제 로직
  await purchaseDailyFortune({yyyymmdd, purchaseType: 'DAY', callback: async () => openInnerView({info: {day: yyyymmdd}, actionType: 'opened'})});
  
  // await getDailyFortuneDetail({yyyymmdd});
}

/** 일운보기 상세 화면 */
const {
  yinYangCount,
  freeCategoryCount,
  dailyFortuneDetail,
  dailyCategoryStatus,
  luckButtonDisabled,
  categoryList,
  selectedCategoryList,
  selectedCategory,
  dailyCategoryDetail,
  clickCategoryButton,
  setSelectedCategory,
  getDailyCategoryDetail,
} = useDailyFortune({
  isDailyFortuneViewOpen,
  isDailyFortuneLoading,
  isCategoryLoading,
  sajuData,
  dailyLuckData,
  categoryPrice,
  selectedDateInfo,
  showPointConfirm,
  getDailyFortuneDetail,
  purchaseDailyCategory,
});

/** 열려있는 팝업/모달 상태 확인 */
const isAnyPopupOpen = computed(() => 
  isPopSajuClock.value
  || isPopMySajuOpen.value
  || isDailyFortuneViewOpen.value
  || isLoading.value
  || (dialogAlertRef.value?.isAlertDialogVisible ?? false)
  || (dialogConfirmRef.value?.isConfirmDialogVisible ?? false)
  || (pointConfirmRef.value?.isConfirmDialogVisible ?? false)
);

/** 스크롤 제어 */
const {} = useScrollLock({activeScrollLock: isAnyPopupOpen});

/** 스낵바 */
const { snackbarRef, triggerSnackbar } = useSnackbar();

onMounted(async () => {
  if (DEV_MODE) {

    getDailyFortunePrice();
    if (userId.value) {
      paymentsStore.getSubscription();
      paymentsStore.getOwnPoint();
    }
    
  } else {
    router.push('/main')
  }
});

watch(() => isDailyFortuneViewOpen.value, (isOpen) => {
  if (!isOpen) {
    // 일운 보기 닫을 때 초기화
    setSelectedCategory(null);
  }
});

/** 일운 조회 */
const openDailyFortune = () => {
  // 일진달력 클릭했을 때, 내 사주 정보 확인
  if (userId.value && !isSajuDataExist.value) {
    getMySaju();
  }
  isDailyFortuneCalendarOpen.value = true;
}

/** TODAY 버튼 클릭 */
const today = () => {
  refCalendar.value.getApi().gotoDate(new Date());
  setPrevSelectedDate();
  selectedDate.value = moment().format('YYYY-MM-DD');
  changeViewTitle();
}
/** 사주시계 팝업 상태관리 */
const setSajuClock = (state) => {
  if (state) addContentLogAPI('C_saju_watch');
  isPopSajuClock.value = state;
}

/** 일운보기 상세 기본 형식 */
const DEFAULT_DAILY_FORTUNE_DETAIL = {
  iljuSky: '',
  iljuEarth: '',
  iljuSkyKor: '',
  iljuEarthKor: '',
  iljuSkyClass: '',
  iljuEarthClass: '',
  iljuSkyFiveChn: '',
  iljuSkyFiveKor: '',
  iljuEarthFiveChn: '',
  iljuEarthFiveKor: '',
  iljuSkyYinyang: '',
  iljuEarthYinyang: '',
  갑자의미: '',
  점수: '',
  확률: '',
};

/** 일운보기 상세 데이터 객체 */
const dailyDetailObject = computed(() => {
  if (!dailyFortuneDetail.value) return DEFAULT_DAILY_FORTUNE_DETAIL;

  const detail = dailyFortuneDetail.value;
  const [iljuSky, iljuEarth] = selectedData.value?.dayju.split('') || ['', ''];
  const [iljuSkyKor, iljuEarthKor] = selectedData.value?.handayju.split('') || ['', ''];

  const object = {
    iljuSky: iljuSky || '',
    iljuEarth: iljuEarth || '',
    iljuSkyKor: iljuSkyKor || '',
    iljuEarthKor: iljuEarthKor || '',
    iljuSkyClass: iljuSky ? getFiveClass(getGanjiColorFive(iljuSky)) : '',
    iljuEarthClass: iljuEarth ? getFiveClass(getGanjiColorFive(iljuEarth)) : '',
    iljuSkyFiveChn: iljuSky ? getSkyStemFive(iljuSky).chn : '',
    iljuSkyFiveKor: iljuSky ? getSkyStemFive(iljuSky).kor : '',
    iljuEarthFiveChn: iljuEarth ? getEarthBranchFive(iljuEarth).chn : '',
    iljuEarthFiveKor: iljuEarth ? getEarthBranchFive(iljuEarth).kor : '',
    iljuSkyYinyang: iljuSky ? getYinYang(iljuSky, 'sky') : '',
    iljuEarthYinyang: iljuEarth ? getYinYang(iljuEarth, 'earth') : '',
    갑자의미: detail['갑자의미'],
    점수: parseInt(detail['일운종합']['일운']['실제점수']),
    확률: parseInt(detail['일운종합']['일운']['달성확율']),
  };

  return object;
});
</script>

<template>
  <div class="calendar-wrap v2">
    <SubscribeInfo :userId="userId"/>

    <DailyFortuneDrawer
      v-show="isDailyFortuneViewOpen"
      v-model:isDailyFortuneViewOpen="isDailyFortuneViewOpen"
      :selectedDateInfo="selectedDateInfo"
      :dailyFortuneDetail="dailyFortuneDetail"
      :dailyCategoryDetail="dailyCategoryDetail"
      :dailyDetailObject="dailyDetailObject"
      :sajuData="sajuData"
      :dailyCategoryStatus="dailyCategoryStatus"
      :isDailyFortuneLoading="isDailyFortuneLoading"
      :isDailyFortuneLoaded="isDailyFortuneLoaded"
      :isCategoryLoading="isCategoryLoading"
      :errorMessage="errorMessage"
      :selectedCategoryList="selectedCategoryList"
      :selectedCategory="selectedCategory"
      :categoryList="categoryList"
      :freeCategoryCount="freeCategoryCount"
      :categoryPrice="categoryPrice"
      :luckButtonDisabled="luckButtonDisabled"
      @clickCategoryButton="clickCategoryButton"
      @closeInnerView="closeInnerView"
      @retryOpenInnerView="retryOpenInnerView"
    />

    <div class="content-main mb-large">
      <section class="inner-base-calendar v2">
        <div style="height: 20px"></div>
        <div class="calendar-sidebar-layout">
          <CalendarSidebar
            :isLoading="isMySajuLoading"
            :isDailyFortuneCalendarOpen="isDailyFortuneCalendarOpen"
            :toggleOptions="toggleOptions"
            :sajuData="sajuData"
            :monthlyPrice="monthlyFortunePrice"
            @openDailyFortune="openDailyFortune"
            @closeDailyFortune="isDailyFortuneCalendarOpen = false"
            @openPopMySaju="openPopMySaju"
            @purchaseMonthly="purchaseMonthly"
          />

          <div class="calendar-main">
            <CalendarHeader
              :calendarType="'v2'"
              :title="viewTitle"
              :isToday="isToday()"
              :solarTerm="firstSeason"
              :solarTermMonth="firstSeasonMonth"
              :isColorChecked="isColorChecked"
              @prev="prev"
              @next="next"
              @today="today"
              @clock="setSajuClock(true)"
            />

            <div class="sec-sch-body">
              <v-row
                class="fill-height"
                @mousedown="handleStart"
                @mousemove="handleMove"
                @mouseup="handleEnd"
                @touchstart="handleStart"
                @touchmove="handleMove"
                @touchend="handleEnd"
              >
                <v-col>
                  <v-sheet height="700">
                    <FullCalendar
                      ref="refCalendar"
                      class="full-calendar web"
                      :options="calendarOptions"
                    />
                  </v-sheet>
                </v-col>
              </v-row>
            </div>
          </div>
        </div>
      </section>
    </div>

    <SajuClockPopup
      v-if="isPopSajuClock"
      ref="currentClockRef"
      @closeClock="setSajuClock(false)"
    />

    <PopMySaju
      :visible="isPopMySajuOpen"
      :mySajuList="mySajuList"
      :selectedMySajuId="selectedMySajuId"
      @close="closePopMySaju"
      @route="goManse"
      @select="selectMySaju"
      @confirm="confirmSelectSaju"
    />

    <AlertComponent
      ref="dialogAlertRef"
      :title="dialogTitle"
      :text="dialogText"
    />

    <ConfirmComponent
      ref="dialogConfirmRef"
      :title="dialogTitle"
      :title2="dialogTitle2"
      :text="dialogText"
      :nextUrl="dialogNextUrl"
    />

    <PointConfirm
      ref="pointConfirmRef"
      :title="dialogTitle"
      :subtitle="dialogSubtitle"
      :point="displayPoint"
      :nextUrl="dialogNextUrl"
      :confirm-text="'구매'"
    />
    
    <Snackbar ref="snackbarRef" />

    <LoadingIndicator v-if="isLoading"/>
  </div>
</template>