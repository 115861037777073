export const monthlyLuckData = ref({
  '20250101': {
    'cg_hg': '경오',
    'cg_hj': '庚午',
    'score': 2.56,
    'ggj_at': {'cc': '경', 'gg': '오', 'ggj': ['병', '기', '정']},
    'sfb_at': {'cc': '편관', 'gg': '상관', 'ggj': ['식신', '편재', '식신']},
    'fe_cnt': {'목': 1.0, '화': 2.0, '토': 2.0, '금': 4.0, '수': 1.0},
    'emoji': {
      'level': 2,
      'word': '흐림',
      'remark': '흐린 날씨처럼 약간의 변화를 준비하며 신중하게 상황을 살피는 단계입니다.',
      'emoji': '🌥️'
    },
    'isOpened': {
      STUDY: false,
      WORK: false,
      WEALTH: false,
      LOVE: false,
      HEALTH: false,
      FAMILY: false,
      MOVE: false,
      RELATIONSHIP: false,
      FAME: false,
      SOCIAL: false,
    },
  },
  '20250102': {
    'cg_hg': '신미',
    'cg_hj': '辛未',
    'score': 3.34,
    'ggj_at': {'cc': '신', 'gg': '미', 'ggj': ['정', '을', '기']},
    'sfb_at': {'cc': '정관', 'gg': '편재', 'ggj': ['식신', '비견', '편재']},
    'fe_cnt': {'목': 1.0, '화': 1.0, '토': 3.0, '금': 4.0, '수': 1.0},
    'emoji': {
      'level': 3,
      'word': '맑음',
      'remark': '맑고 쾌청한 하늘처럼 안정적이고 조화로운 상태입니다.',
      'emoji': '🌤️'
    },
    'isOpened': {
      STUDY: false,
      WORK: false,
      WEALTH: false,
      LOVE: false,
      HEALTH: false,
      FAMILY: false,
      MOVE: false,
      RELATIONSHIP: false,
      FAME: false,
      SOCIAL: false,
    },
  },
  '20250103': {
    'cg_hg': '임신',
    'cg_hj': '壬申',
    'score': 3.84,
    'ggj_at': {'cc': '임', 'gg': '신', 'ggj': ['무', '임', '경']},
    'sfb_at': {'cc': '정인', 'gg': '정관', 'ggj': ['편재', '정인', '편관']},
    'fe_cnt': {'목': 1.0, '화': 1.0, '토': 2.0, '금': 4.0, '수': 2.0},
    'emoji': {
      'level': 4,
      'word': '따뜻',
      'remark': '따뜻한 햇살 아래 활기를 되찾으며 생명력을 발휘하는 상태입니다.',
      'emoji': '☀️'
    },
    'isOpened': {
      STUDY: false,
      WORK: false,
      WEALTH: false,
      LOVE: false,
      HEALTH: false,
      FAMILY: false,
      MOVE: false,
      RELATIONSHIP: false,
      FAME: false,
      SOCIAL: false,
    },
  },
  '20250104': {
    'cg_hg': '계유',
    'cg_hj': '癸酉',
    'score': 3.84,
    'ggj_at': {'cc': '계', 'gg': '유', 'ggj': ['경', '신']},
    'sfb_at': {'cc': '정인', 'gg': '편관', 'ggj': ['편관', '정관']},
    'fe_cnt': {'목': 1.0, '화': 1.0, '토': 2.0, '금': 4.0, '수': 2.0},
    'emoji': {
      'level': 4,
      'word': '따뜻',
      'remark': '따뜻한 햇살 아래 활기를 되찾으며 생명력을 발휘하는 상태입니다.',
      'emoji': '☀️'
    },
    'isOpened': {
      STUDY: false,
      WORK: false,
      WEALTH: false,
      LOVE: false,
      HEALTH: false,
      FAMILY: false,
      MOVE: false,
      RELATIONSHIP: false,
      FAME: false,
      SOCIAL: false,
    },
  },
  '20250105': {
    'cg_hg': '갑술',
    'cg_hj': '甲戌',
    'score': 3.04,
    'ggj_at': {'cc': '갑', 'gg': '술', 'ggj': ['신', '정', '무']},
    'sfb_at': {'cc': '비견', 'gg': '정재', 'ggj': ['정관', '식신', '편재']},
    'fe_cnt': {'목': 2.0, '화': 1.0, '토': 3.0, '금': 3.0, '수': 1.0},
    'emoji': {
      'level': 3,
      'word': '맑음',
      'remark': '맑고 쾌청한 하늘처럼 안정적이고 조화로운 상태입니다.',
      'emoji': '🌤️'
    },
    'isOpened': {
      STUDY: false,
      WORK: false,
      WEALTH: false,
      LOVE: false,
      HEALTH: false,
      FAMILY: false,
      MOVE: false,
      RELATIONSHIP: false,
      FAME: false,
      SOCIAL: false,
    },
  },
  '20250106': {
    'cg_hg': '을해',
    'cg_hj': '乙亥',
    'score': 3.24,
    'ggj_at': {'cc': '을', 'gg': '해', 'ggj': ['무', '갑', '임']},
    'sfb_at': {'cc': '비견', 'gg': '정인', 'ggj': ['편재', '비견', '정인']},
    'fe_cnt': {'목': 2.0, '화': 1.0, '토': 2.0, '금': 3.0, '수': 2.0},
    'emoji': {
      'level': 3,
      'word': '맑음',
      'remark': '맑고 쾌청한 하늘처럼 안정적이고 조화로운 상태입니다.',
      'emoji': '🌤️'
    },
    'isOpened': {
      STUDY: false,
      WORK: false,
      WEALTH: false,
      LOVE: false,
      HEALTH: false,
      FAMILY: false,
      MOVE: false,
      RELATIONSHIP: false,
      FAME: false,
      SOCIAL: false,
    },
  },
  '20250107': {
    'cg_hg': '병자',
    'cg_hj': '丙子',
    'score': 3.12,
    'ggj_at': {'cc': '병', 'gg': '자', 'ggj': ['임', '계']},
    'sfb_at': {'cc': '식신', 'gg': '편인', 'ggj': ['정인', '정인']},
    'fe_cnt': {'목': 1.0, '화': 2.0, '토': 2.0, '금': 3.0, '수': 2.0},
    'emoji': {
      'level': 3,
      'word': '맑음',
      'remark': '맑고 쾌청한 하늘처럼 안정적이고 조화로운 상태입니다.',
      'emoji': '🌤️'
    },
    'isOpened': {
      STUDY: false,
      WORK: false,
      WEALTH: false,
      LOVE: false,
      HEALTH: false,
      FAMILY: false,
      MOVE: false,
      RELATIONSHIP: false,
      FAME: false,
      SOCIAL: false,
    },
  },
  '20250108': {
    'cg_hg': '정축',
    'cg_hj': '丁丑',
    'score': 3.12,
    'ggj_at': {'cc': '정', 'gg': '축', 'ggj': ['계', '신', '기']},
    'sfb_at': {'cc': '식신', 'gg': '편재', 'ggj': ['정인', '정관', '편재']},
    'fe_cnt': {'목': 1.0, '화': 2.0, '토': 3.0, '금': 3.0, '수': 1.0},
    'emoji': {
      'level': 3,
      'word': '맑음',
      'remark': '맑고 쾌청한 하늘처럼 안정적이고 조화로운 상태입니다.',
      'emoji': '🌤️'
    },
    'isOpened': {
      STUDY: false,
      WORK: false,
      WEALTH: false,
      LOVE: false,
      HEALTH: false,
      FAMILY: false,
      MOVE: false,
      RELATIONSHIP: false,
      FAME: false,
      SOCIAL: false,
    },
  },
  '20250109': {
    'cg_hg': '무인',
    'cg_hj': '戊寅',
    'score': 2.69,
    'ggj_at': {'cc': '무', 'gg': '인', 'ggj': ['무', '병', '갑']},
    'sfb_at': {'cc': '편재', 'gg': '비겁', 'ggj': ['편재', '식신', '비견']},
    'fe_cnt': {'목': 2.0, '화': 1.0, '토': 3.0, '금': 3.0, '수': 1.0},
    'emoji': {
      'level': 2,
      'word': '흐림',
      'remark': '흐린 날씨처럼 약간의 변화를 준비하며 신중하게 상황을 살피는 단계입니다.',
      'emoji': '🌥️'
    },
    'isOpened': {
      STUDY: false,
      WORK: false,
      WEALTH: false,
      LOVE: false,
      HEALTH: false,
      FAMILY: false,
      MOVE: false,
      RELATIONSHIP: false,
      FAME: false,
      SOCIAL: false,
    },
  },
  '20250110': {
    'cg_hg': '기묘',
    'cg_hj': '己卯',
    'score': 2.84,
    'ggj_at': {'cc': '기', 'gg': '묘', 'ggj': ['갑', '을', '진']},
    'sfb_at': {'cc': '편재', 'gg': '비견', 'ggj': ['비견', '비견', '정재']},
    'fe_cnt': {'목': 2.0, '화': 1.0, '토': 3.0, '금': 3.0, '수': 1.0},
    'emoji': {
      'level': 2,
      'word': '흐림',
      'remark': '흐린 날씨처럼 약간의 변화를 준비하며 신중하게 상황을 살피는 단계입니다.',
      'emoji': '🌥️'
    },
    'isOpened': {
      STUDY: false,
      WORK: false,
      WEALTH: false,
      LOVE: false,
      HEALTH: false,
      FAMILY: false,
      MOVE: false,
      RELATIONSHIP: false,
      FAME: false,
      SOCIAL: false,
    },
  },
  '20250111': {
    'cg_hg': '경진',
    'cg_hj': '庚辰',
    'score': 3.54,
    'ggj_at': {'cc': '경', 'gg': '진', 'ggj': ['을', '계', '무']},
    'sfb_at': {'cc': '편관', 'gg': '정재', 'ggj': ['비견', '정인', '편재']},
    'fe_cnt': {'목': 1.0, '화': 1.0, '토': 3.0, '금': 4.0, '수': 1.0},
    'emoji': {
      'level': 3,
      'word': '맑음',
      'remark': '맑고 쾌청한 하늘처럼 안정적이고 조화로운 상태입니다.',
      'emoji': '🌤️'
    },
    'isOpened': {
      STUDY: false,
      WORK: false,
      WEALTH: false,
      LOVE: false,
      HEALTH: false,
      FAMILY: false,
      MOVE: false,
      RELATIONSHIP: false,
      FAME: false,
      SOCIAL: false,
    },
  },
  '20250112': {
    'cg_hg': '신사',
    'cg_hj': '申巳',
    'score': 2.71,
    'ggj_at': {'cc': '신', 'gg': '사', 'ggj': ['무', '경', '병']},
    'sfb_at': {'cc': '정관', 'gg': '식신', 'ggj': ['편재', '편관', '식신']},
    'fe_cnt': {'목': 1.0, '화': 2.0, '토': 2.0, '금': 4.0, '수': 1.0},
    'emoji': {
      'level': 2,
      'word': '흐림',
      'remark': '흐린 날씨처럼 약간의 변화를 준비하며 신중하게 상황을 살피는 단계입니다.',
      'emoji': '🌥️'
    },
    'isOpened': {
      STUDY: false,
      WORK: false,
      WEALTH: false,
      LOVE: false,
      HEALTH: false,
      FAMILY: false,
      MOVE: false,
      RELATIONSHIP: false,
      FAME: false,
      SOCIAL: false,
    },
  },
  '20250113': {
    'cg_hg': '임오',
    'cg_hj': '壬午',
    'score': 2.56,
    'ggj_at': {'cc': '임', 'gg': '오', 'ggj': ['병', '기', '정']},
    'sfb_at': {'cc': '정인', 'gg': '상관', 'ggj': ['식신', '편재', '식신']},
    'fe_cnt': {'목': 1.0, '화': 2.0, '토': 2.0, '금': 3.0, '수': 2.0},
    'emoji': {
      'level': 2,
      'word': '흐림',
      'remark': '흐린 날씨처럼 약간의 변화를 준비하며 신중하게 상황을 살피는 단계입니다.',
      'emoji': '🌥️'
    },
    'isOpened': {
      STUDY: false,
      WORK: false,
      WEALTH: false,
      LOVE: false,
      HEALTH: false,
      FAMILY: false,
      MOVE: false,
      RELATIONSHIP: false,
      FAME: false,
      SOCIAL: false,
    },
  },
  '20250114': {
    'cg_hg': '계미',
    'cg_hj': '癸未',
    'score': 3.19,
    'ggj_at': {'cc': '계', 'gg': '미', 'ggj': ['정', '을', '기']},
    'sfb_at': {'cc': '정인', 'gg': '편재', 'ggj': ['식신', '비견', '편재']},
    'fe_cnt': {'목': 1.0, '화': 1.0, '토': 3.0, '금': 3.0, '수': 2.0},
    'emoji': {
      'level': 3,
      'word': '맑음',
      'remark': '맑고 쾌청한 하늘처럼 안정적이고 조화로운 상태입니다.',
      'emoji': '🌤️'
    },
    'isOpened': {
      STUDY: false,
      WORK: false,
      WEALTH: false,
      LOVE: false,
      HEALTH: false,
      FAMILY: false,
      MOVE: false,
      RELATIONSHIP: false,
      FAME: false,
      SOCIAL: false,
    },
  },
  '20250115': {
    'cg_hg': '갑신',
    'cg_hj': '甲申',
    'score': 3.74,
    'ggj_at': {'cc': '갑', 'gg': '신', 'ggj': ['무', '임', '경']},
    'sfb_at': {'cc': '비견', 'gg': '정관', 'ggj': ['편재', '정인', '편관']},
    'fe_cnt': {'목': 2.0, '화': 1.0, '토': 2.0, '금': 4.0, '수': 1.0},
    'emoji': {
      'level': 4,
      'word': '따뜻',
      'remark': '따뜻한 햇살 아래 활기를 되찾으며 생명력을 발휘하는 상태입니다.',
      'emoji': '☀️'
    },
    'isOpened': {
      STUDY: false,
      WORK: false,
      WEALTH: false,
      LOVE: false,
      HEALTH: false,
      FAMILY: false,
      MOVE: false,
      RELATIONSHIP: false,
      FAME: false,
      SOCIAL: false,
    },
  },
  '20250116': {
    'cg_hg': '을유',
    'cg_hj': '乙酉',
    'score': 3.59,
    'ggj_at': {'cc': '을', 'gg': '유', 'ggj': ['경', '신']},
    'sfb_at': {'cc': '비견', 'gg': '편관', 'ggj': ['편관', '정관']},
    'fe_cnt': {'목': 2.0, '화': 1.0, '토': 2.0, '금': 4.0, '수': 1.0},
    'emoji': {
      'level': 3,
      'word': '맑음',
      'remark': '맑고 쾌청한 하늘처럼 안정적이고 조화로운 상태입니다.',
      'emoji': '🌤️'
    },
    'isOpened': {
      STUDY: false,
      WORK: false,
      WEALTH: false,
      LOVE: false,
      HEALTH: false,
      FAMILY: false,
      MOVE: false,
      RELATIONSHIP: false,
      FAME: false,
      SOCIAL: false,
    },
  },
  '20250117': {
    'cg_hg': '병술',
    'cg_hj': '丙戌',
    'score': 2.77,
    'ggj_at': {'cc': '병', 'gg': '술', 'ggj': ['신', '정', '무']},
    'sfb_at': {'cc': '식신', 'gg': '정재', 'ggj': ['정관', '식신', '편재']},
    'fe_cnt': {'목': 1.0, '화': 2.0, '토': 3.0, '금': 3.0, '수': 1.0},
    'emoji': {
      'level': 2,
      'word': '흐림',
      'remark': '흐린 날씨처럼 약간의 변화를 준비하며 신중하게 상황을 살피는 단계입니다.',
      'emoji': '🌥️'
    },
    'isOpened': {
      STUDY: false,
      WORK: false,
      WEALTH: false,
      LOVE: false,
      HEALTH: false,
      FAMILY: false,
      MOVE: false,
      RELATIONSHIP: false,
      FAME: false,
      SOCIAL: false,
    },
  },
  '20250118': {
    'cg_hg': '정해',
    'cg_hj': '丁亥',
    'score': 3.12,
    'ggj_at': {'cc': '정', 'gg': '해', 'ggj': ['무', '갑', '임']},
    'sfb_at': {'cc': '식신', 'gg': '정인', 'ggj': ['편재', '비견', '정인']},
    'fe_cnt': {'목': 1.0, '화': 2.0, '토': 2.0, '금': 3.0, '수': 2.0},
    'emoji': {
      'level': 3,
      'word': '맑음',
      'remark': '맑고 쾌청한 하늘처럼 안정적이고 조화로운 상태입니다.',
      'emoji': '🌤️'
    },
    'isOpened': {
      STUDY: false,
      WORK: false,
      WEALTH: false,
      LOVE: false,
      HEALTH: false,
      FAMILY: false,
      MOVE: false,
      RELATIONSHIP: false,
      FAME: false,
      SOCIAL: false,
    },
  },
  '20250119': {
    'cg_hg': '무자',
    'cg_hj': '戊子',
    'score': 3.39,
    'ggj_at': {'cc': '무', 'gg': '자', 'ggj': ['임', '계']},
    'sfb_at': {'cc': '편재', 'gg': '편인', 'ggj': ['정인', '정인']},
    'fe_cnt': {'목': 1.0, '화': 1.0, '토': 3.0, '금': 3.0, '수': 2.0},
    'emoji': {
      'level': 3,
      'word': '맑음',
      'remark': '맑고 쾌청한 하늘처럼 안정적이고 조화로운 상태입니다.',
      'emoji': '🌤️'
    },
    'isOpened': {
      STUDY: false,
      WORK: false,
      WEALTH: false,
      LOVE: false,
      HEALTH: false,
      FAMILY: false,
      MOVE: false,
      RELATIONSHIP: false,
      FAME: false,
      SOCIAL: false,
    },
  },
  '20250120': {
    'cg_hg': '기축',
    'cg_hj': '己丑',
    'score': 3.54,
    'ggj_at': {'cc': '기', 'gg': '축', 'ggj': ['계', '신', '기']},
    'sfb_at': {'cc': '편재', 'gg': '편재', 'ggj': ['정인', '정관', '편재']},
    'fe_cnt': {'목': 1.0, '화': 1.0, '토': 4.0, '금': 3.0, '수': 1.0},
    'emoji': {
      'level': 3,
      'word': '맑음',
      'remark': '맑고 쾌청한 하늘처럼 안정적이고 조화로운 상태입니다.',
      'emoji': '🌤️'
    },
    'isOpened': {
      STUDY: false,
      WORK: false,
      WEALTH: false,
      LOVE: false,
      HEALTH: false,
      FAMILY: false,
      MOVE: false,
      RELATIONSHIP: false,
      FAME: false,
      SOCIAL: false,
    },
  },
  '20250121': {
    'cg_hg': '경인',
    'cg_hj': '庚寅',
    'score': 2.84,
    'ggj_at': {'cc': '경', 'gg': '인', 'ggj': ['무', '병', '갑']},
    'sfb_at': {'cc': '편관', 'gg': '비겁', 'ggj': ['편재', '식신', '비견']},
    'fe_cnt': {'목': 2.0, '화': 1.0, '토': 2.0, '금': 4.0, '수': 1.0},
    'emoji': {
      'level': 2,
      'word': '흐림',
      'remark': '흐린 날씨처럼 약간의 변화를 준비하며 신중하게 상황을 살피는 단계입니다.',
      'emoji': '🌥️'
    },
    'isOpened': {
      STUDY: false,
      WORK: false,
      WEALTH: false,
      LOVE: false,
      HEALTH: false,
      FAMILY: false,
      MOVE: false,
      RELATIONSHIP: false,
      FAME: false,
      SOCIAL: false,
    },
  },
  '20250122': {
    'cg_hg': '신묘',
    'cg_hj': '辛卯',
    'score': 2.99,
    'ggj_at': {'cc': '신', 'gg': '묘', 'ggj': ['갑', '을', '진']},
    'sfb_at': {'cc': '정관', 'gg': '비견', 'ggj': ['비견', '비견', '정재']},
    'fe_cnt': {'목': 2.0, '화': 1.0, '토': 2.0, '금': 4.0, '수': 1.0},
    'emoji': {
      'level': 2,
      'word': '흐림',
      'remark': '흐린 날씨처럼 약간의 변화를 준비하며 신중하게 상황을 살피는 단계입니다.',
      'emoji': '🌥️'
    },
    'isOpened': {
      STUDY: false,
      WORK: false,
      WEALTH: false,
      LOVE: false,
      HEALTH: false,
      FAMILY: false,
      MOVE: false,
      RELATIONSHIP: false,
      FAME: false,
      SOCIAL: false,
    },
  },
  '20250123': {
    'cg_hg': '임진',
    'cg_hj': '壬辰',
    'score': 3.54,
    'ggj_at': {'cc': '임', 'gg': '진', 'ggj': ['을', '계', '무']},
    'sfb_at': {'cc': '정인', 'gg': '정재', 'ggj': ['비견', '정인', '편재']},
    'fe_cnt': {'목': 1.0, '화': 1.0, '토': 3.0, '금': 3.0, '수': 2.0},
    'emoji': {
      'level': 3,
      'word': '맑음',
      'remark': '맑고 쾌청한 하늘처럼 안정적이고 조화로운 상태입니다.',
      'emoji': '🌤️'
    },
    'isOpened': {
      STUDY: false,
      WORK: false,
      WEALTH: false,
      LOVE: false,
      HEALTH: false,
      FAMILY: false,
      MOVE: false,
      RELATIONSHIP: false,
      FAME: false,
      SOCIAL: false,
    },
  },
  '20250124': {
    'cg_hg': '계사',
    'cg_hj': '癸巳',
    'score': 2.56,
    'ggj_at': {'cc': '계', 'gg': '사', 'ggj': ['무', '경', '병']},
    'sfb_at': {'cc': '정인', 'gg': '식신', 'ggj': ['편재', '편관', '식신']},
    'fe_cnt': {'목': 1.0, '화': 2.0, '토': 2.0, '금': 3.0, '수': 2.0},
    'emoji': {
      'level': 2,
      'word': '흐림',
      'remark': '흐린 날씨처럼 약간의 변화를 준비하며 신중하게 상황을 살피는 단계입니다.',
      'emoji': '🌥️'
    },
    'isOpened': {
      STUDY: false,
      WORK: false,
      WEALTH: false,
      LOVE: false,
      HEALTH: false,
      FAMILY: false,
      MOVE: false,
      RELATIONSHIP: false,
      FAME: false,
      SOCIAL: false,
    },
  },
  '20250125': {
    'cg_hg': '갑오',
    'cg_hj': '甲午',
    'score': 2.41,
    'ggj_at': {'cc': '갑', 'gg': '오', 'ggj': ['병', '기', '정']},
    'sfb_at': {'cc': '비견', 'gg': '상관', 'ggj': ['식신', '편재', '식신']},
    'fe_cnt': {'목': 2.0, '화': 2.0, '토': 2.0, '금': 3.0, '수': 1.0},
    'emoji': {
      'level': 2,
      'word': '흐림',
      'remark': '흐린 날씨처럼 약간의 변화를 준비하며 신중하게 상황을 살피는 단계입니다.',
      'emoji': '🌥️'
    }
  },
  '20250126': {
    'cg_hg': '을미',
    'cg_hj': '乙未',
    'score': 2.89,
    'ggj_at': {'cc': '을', 'gg': '미', 'ggj': ['정', '을', '기']},
    'sfb_at': {'cc': '비견', 'gg': '편재', 'ggj': ['식신', '비견', '편재']},
    'fe_cnt': {'목': 2.0, '화': 1.0, '토': 3.0, '금': 3.0, '수': 1.0},
    'emoji': {
      'level': 2,
      'word': '흐림',
      'remark': '흐린 날씨처럼 약간의 변화를 준비하며 신중하게 상황을 살피는 단계입니다.',
      'emoji': '🌥️'
    },
    'isOpened': {
      STUDY: false,
      WORK: false,
      WEALTH: false,
      LOVE: false,
      HEALTH: false,
      FAMILY: false,
      MOVE: false,
      RELATIONSHIP: false,
      FAME: false,
      SOCIAL: false,
    },
  },
  '20250127': {
    'cg_hg': '병신',
    'cg_hj': '丙申',
    'score': 3.47,
    'ggj_at': {'cc': '병', 'gg': '신', 'ggj': ['무', '임', '경']},
    'sfb_at': {'cc': '식신', 'gg': '정관', 'ggj': ['편재', '정인', '편관']},
    'fe_cnt': {'목': 1.0, '화': 2.0, '토': 2.0, '금': 4.0, '수': 1.0},
    'emoji': {
      'level': 3,
      'word': '맑음',
      'remark': '맑고 쾌청한 하늘처럼 안정적이고 조화로운 상태입니다.',
      'emoji': '🌤️'
    },
    'isOpened': {
      STUDY: false,
      WORK: false,
      WEALTH: false,
      LOVE: false,
      HEALTH: false,
      FAMILY: false,
      MOVE: false,
      RELATIONSHIP: false,
      FAME: false,
      SOCIAL: false,
    },
  },
  '20250128': {
    'cg_hg': '정유',
    'cg_hj': '丁酉',
    'score': 3.47,
    'ggj_at': {'cc': '정', 'gg': '유', 'ggj': ['경', '신']},
    'sfb_at': {'cc': '식신', 'gg': '편관', 'ggj': ['편관', '정관']},
    'fe_cnt': {'목': 1.0, '화': 2.0, '토': 2.0, '금': 4.0, '수': 1.0},
    'emoji': {
      'level': 3,
      'word': '맑음',
      'remark': '맑고 쾌청한 하늘처럼 안정적이고 조화로운 상태입니다.',
      'emoji': '🌤️'
    },
    'isOpened': {
      STUDY: false,
      WORK: false,
      WEALTH: false,
      LOVE: false,
      HEALTH: false,
      FAMILY: false,
      MOVE: false,
      RELATIONSHIP: false,
      FAME: false,
      SOCIAL: false,
    },
  },
  '20250129': {
    'cg_hg': '무술',
    'cg_hj': '戊戌',
    'score': 3.04,
    'ggj_at': {'cc': '무', 'gg': '술', 'ggj': ['신', '정', '무']},
    'sfb_at': {'cc': '편재', 'gg': '정재', 'ggj': ['정관', '식신', '편재']},
    'fe_cnt': {'목': 1.0, '화': 1.0, '토': 4.0, '금': 3.0, '수': 1.0},
    'emoji': {
      'level': 3,
      'word': '맑음',
      'remark': '맑고 쾌청한 하늘처럼 안정적이고 조화로운 상태입니다.',
      'emoji': '🌤️'
    },
    'isOpened': {
      STUDY: false,
      WORK: false,
      WEALTH: false,
      LOVE: false,
      HEALTH: false,
      FAMILY: false,
      MOVE: false,
      RELATIONSHIP: false,
      FAME: false,
      SOCIAL: false,
    },
  },
  '20250130': {
    'cg_hg': '기해',
    'cg_hj': '己亥',
    'score': 3.54,
    'ggj_at': {'cc': '기', 'gg': '해', 'ggj': ['무', '갑', '임']},
    'sfb_at': {'cc': '편재', 'gg': '정인', 'ggj': ['편재', '비견', '정인']},
    'fe_cnt': {'목': 1.0, '화': 1.0, '토': 3.0, '금': 3.0, '수': 2.0},
    'emoji': {
      'level': 3,
      'word': '맑음',
      'remark': '맑고 쾌청한 하늘처럼 안정적이고 조화로운 상태입니다.',
      'emoji': '🌤️'
    },
    'isOpened': {
      STUDY: false,
      WORK: false,
      WEALTH: false,
      LOVE: false,
      HEALTH: false,
      FAMILY: false,
      MOVE: false,
      RELATIONSHIP: false,
      FAME: false,
      SOCIAL: false,
    },
  },
  '20250131': {
    'cg_hg': '경자',
    'cg_hj': '庚子',
    'score': 3.54,
    'ggj_at': {'cc': '경', 'gg': '자', 'ggj': ['임', '계']},
    'sfb_at': {'cc': '편관', 'gg': '편인', 'ggj': ['정인', '정인']},
    'fe_cnt': {'목': 1.0, '화': 1.0, '토': 2.0, '금': 4.0, '수': 2.0},
    'emoji': {
      'level': 3,
      'word': '맑음',
      'remark': '맑고 쾌청한 하늘처럼 안정적이고 조화로운 상태입니다.',
      'emoji': '🌤️'
    },
    'isOpened': {
      STUDY: false,
      WORK: false,
      WEALTH: false,
      LOVE: false,
      HEALTH: false,
      FAMILY: false,
      MOVE: false,
      RELATIONSHIP: false,
      FAME: false,
      SOCIAL: false,
    },
  }})

// == 일운 요약 ==
export const dailyPersonalData = {
  "일운": "손흥민님 일운 분석",
  "사자성어": {
    "gd_bd": "기운",
    "group_name": "식상",
    "four_word": "조변석개(朝變夕改)",
    "four_mean": "자주 바뀌는 계획을 정리해야 할 날",
    "four_message": "변화는 발전의 기회가 될 수 있지만, 오늘은 한 가지에 집중하며 우선순위를 명확히 설정하는 것이 중요합니다. 창의적인 아이디어를 구체적으로 실현 가능한 형태로 만들어보세요. 꾸준한 실행이 당신을 더 큰 성공으로 이끌 것입니다.",
    "keyword": "변화, 집중, 실현성"
  },
  "일운종합": {
    "일운": {
      "score": 2.84,
      "probs": 65.85
    }
  },
  "일일운세": {
    "일운": {
      "header": {
        "score": 2,
        "raw_score": 2.84,
        "probs": 65.85,
        "score_emoji_lvl": "😟(불안)",
        "score_emoji_txt": "마음이 흔들리고 안정되지 않아 걱정이 있는 상태입니다.",
        "score_prob_text": "이 운세의 지속 가능성은 65.85%로, 긍정과 부정이 혼재된 상태이나 관심과 노력에 따라 좋은 결과가 예상됩니다."
      },
      "summary": {
        "desc_title": "일운",
        "desc_root": "다소 불안정한 하루로, 문제 해결에 주의를 기울여야 하는 시기입니다.",
        "desc_appl": "오늘은 예상치 못한 장애물로 인해 진행 중인 일에 차질이 생길 수 있습니다. 이로 인해 심리적 스트레스를 느낄 수 있으나, 차분하게 대처하면 문제를 해결하며 자신의 역량을 확장할 기회를 만들 수 있습니다. 주변 사람들과의 관계에서도 갈등이 발생할 가능성이 있지만, 이를 통해 상호 이해와 신뢰를 높일 계기로 삼을 수 있습니다. 도전적인 상황이지만 이를 긍정적으로 받아들이면 새로운 배움을 얻고 개인적 성장을 이룰 수 있는 하루입니다."
      },
      "good_feature": {
        "keywords": "소통, 감정 나눔",
        "description": "문제를 해결하는 과정에서 자신에 대한 신뢰를 회복할 수 있으며, 이를 통해 성취감을 느낄 수 있는 시기입니다. 어려움을 겪더라도 이를 극복하면서 개인적인 성장을 이룰 수 있습니다.",
        "recommendation": "문제를 성장의 기회로 삼고, 문제를 해결하면서 자신의 능력을 확장시키는 시간을 가질 필요가 있습니다."
      },
      "bad_feature": {
        "keywords": "소통 기회 부족, 갈등",
        "description": "예상치 못한 문제들로 인해 큰 스트레스를 받을 수 있으며, 상황이 악화될 가능성이 있습니다. 문제를 해결하지 못할 경우 큰 좌절감을 느낄 수 있으며, 이로 인해 심리적인 부담이 커질 수 있습니다.",
        "recommendation": "예상치 못한 문제에 대비하여 차분하게 대응하는 능력을 키우고, 주변의 도움을 요청해 문제를 효율적으로 해결하는 방법을 모색해야 합니다."
      }
    }
  },
  "생활종합": {
    "학습운": {
      "score": 3.0,
      "probs": 72.5
    },
    "직업운": {
      "score": 1.5,
      "probs": 55.02
    },
    "애정운": {
      "score": 3.0,
      "probs": 72.5
    },
    "가족운": {
      "score": 3.3,
      "probs": 83.62
    },
    "재물운": {
      "score": 4.8,
      "probs": 90.0
    },
    "건강운": {
      "score": 3.0,
      "probs": 72.5
    },
    "이동운": {
      "score": 4.8,
      "probs": 90.0
    },
    "인간관계운": {
      "score": 1.5,
      "probs": 55.02
    },
    "명예운": {
      "score": 3.3,
      "probs": 83.62
    },
    "사회공헌운": {
      "score": 3.0,
      "probs": 72.5
    }
  },
  "생활운세": {
    "학습운": {
      "header": {
        "score": 3,
        "raw_score": 3.0,
        "probs": 72.5,
        "score_emoji_lvl": "🌕(조화로움)",
        "score_emoji_txt": "기운이 자연스럽게 흐르며 조화를 이루는 안정된 상태입니다.",
        "score_prob_text": "이 운세의 지속 가능성은 72.5%로, 긍정적인 기운이 있는 편입니다."
      },
      "summary": {
        "desc_title": "학습운",
        "desc_root": "학습에서 안정적인 성과를 기대할 수 있습니다.",
        "desc_appl": "현재 성과를 꾸준히 유지하기 위해서는 일정한 학습 패턴을 유지하는 것이 중요합니다. 새로운 학습 내용을 받아들이기보다는 복습과 반복 학습을 통해 기존 지식을 강화하는 전략이 필요합니다. 성실함과 꾸준함을 바탕으로 학업의 질을 높일 수 있으며, 성적 하락 없이 현재의 성과를 유지할 수 있습니다."
      },
      "good_feature": {
        "keywords": "성실함, 안정성",
        "description": "학습 성실도가 높아 성적이 유지되고, 하락 없이 안정적인 상태를 유지합니다.",
        "recommendation": "새로운 학습 목표를 설정하고, 학습 의욕을 되찾기 위한 동기 부여가 필요합니다."
      },
      "bad_feature": {
        "keywords": "동기 부족, 성장 정체",
        "description": "성적 향상에 대한 동기 부여가 부족하며, 큰 성장이 어려울 수 있습니다.",
        "recommendation": "성장 정체를 극복하기 위해 학습 방법을 다양화하고, 새로운 도전을 시도하는 것이 필요합니다."
      }
    },
    "직업운": {
      "header": {
        "score": 1,
        "raw_score": 1.5,
        "probs": 55.02,
        "score_emoji_lvl": "🕯️(정체됨)",
        "score_emoji_txt": "기운이 멈추고 정체되어 흐르지 않는 상태로, 에너지 회복과 준비가 필요한 시기입니다.",
        "score_prob_text": "이 운세의 지속 가능성은 55.02%로, 부정적인 기운이 있으나 배품의 미덕이 있었다면 무난해 질 수 있습니다."
      },
      "summary": {
        "desc_title": "직업운",
        "desc_root": "직장에서의 성과가 저조할 수 있으며 업무에서 어려움을 겪을 수 있습니다.",
        "desc_appl": "업무에서 새로운 접근법을 모색하면 변화를 만들어낼 수 있는 기회입니다. 지금까지와는 다른 방식으로 문제를 해결하거나, 새로운 아이디어를 도입하여 성과를 높일 수 있습니다. 특히, 업무 방식을 개선함으로써 보다 효율적이고 창의적인 결과를 도출할 가능성이 큽니다."
      },
      "good_feature": {
        "keywords": "새로운 도전",
        "description": "성과에 대한 부담이 적어 새로운 도전이나 시도를 할 수 있는 기회입니다.",
        "recommendation": "업무에서 작은 성과를 통해 자신감을 회복하고, 새로운 목표를 설정하여 도전하는 것이 필요합니다."
      },
      "bad_feature": {
        "keywords": "성과 저조, 자신감 하락",
        "description": "성과 부족으로 인해 자신감이 떨어지거나 동기 부여가 어려울 수 있습니다.",
        "recommendation": "자신감을 회복하기 위한 성과 측정 방식을 변경하고, 새로운 기술을 익혀 업무 효율을 높이는 것이 중요합니다."
      }
    },
    "애정운": {
      "header": {
        "score": 3,
        "raw_score": 3.0,
        "probs": 72.5,
        "score_emoji_lvl": "🌕(조화로움)",
        "score_emoji_txt": "기운이 자연스럽게 흐르며 조화를 이루는 안정된 상태입니다.",
        "score_prob_text": "이 운세의 지속 가능성은 72.5%로, 긍정적인 기운이 있는 편입니다."
      },
      "summary": {
        "desc_title": "애정운",
        "desc_root": "감정적 소통이 다소 부족해질 수 있습니다.",
        "desc_appl": "관계에서는 큰 변화가 없지만, 소통이 다소 부족해질 수 있는 시기입니다. 상대방과의 교류가 원활하지 않을 수 있으므로, 대화를 통해 소통을 원활하게 만드는 노력이 필요합니다. 그렇지 않으면 관계에서 약간의 거리감이 생길 수 있습니다."
      },
      "good_feature": {
        "keywords": "안정성, 갈등 감소",
        "description": "안정된 관계를 유지할 수 있으며, 파트너와의 갈등이 줄어들 수 있습니다.",
        "recommendation": "서로에 대한 감정적 소통을 더욱 강화하여 관계를 발전시키는 것이 필요합니다."
      },
      "bad_feature": {
        "keywords": "소통 부족, 관계 정체",
        "description": "감정적 소통의 빈도나 깊이가 부족해질 수 있어 관계가 정체될 수 있습니다.",
        "recommendation": "더 깊은 대화를 통해 상대방의 감정을 이해하고, 관계를 개선할 수 있는 노력이 필요합니다."
      }
    },
    "가족운": {
      "header": {
        "score": 3,
        "raw_score": 3.3,
        "probs": 83.62,
        "score_emoji_lvl": "🌕(조화로움)",
        "score_emoji_txt": "기운이 자연스럽게 흐르며 조화를 이루는 안정된 상태입니다.",
        "score_prob_text": "이 운세의 지속 가능성은 83.62%로, 긍정적인 기운이 있는 편입니다."
      },
      "summary": {
        "desc_title": "가족운",
        "desc_root": "가족과의 관계가 안정적으로 유지되지만, 특별한 변화는 없습니다.",
        "desc_appl": "큰 갈등이 발생하지는 않지만, 소통이 원활하지 않아 일부 의사소통의 부족함을 느낄 수 있는 상황입니다. 이러한 상황에서는 세부적인 감정 교류가 필요하며, 이를 통해 상호 이해를 증진하고 관계를 개선할 수 있는 노력이 요구됩니다."
      },
      "good_feature": {
        "keywords": "안정성, 갈등 감소",
        "description": "안정적인 관계를 유지할 수 있으며, 가족 간의 갈등이 크게 발생하지 않습니다.",
        "recommendation": "소소한 가족 행사나 대화를 통해 가족 간의 감정을 공유하고 관계를 개선하는 것이 필요합니다."
      },
      "bad_feature": {
        "keywords": "소통 부족, 감정적 거리",
        "description": "감정적 소통이 줄어들어 가족과의 유대가 약해질 수 있습니다.",
        "recommendation": "더 자주 가족과의 시간을 보내며, 감정적 유대감을 회복할 수 있는 활동을 계획해야 합니다."
      }
    },
    "재물운": {
      "header": {
        "score": 5,
        "raw_score": 4.8,
        "probs": 90.0,
        "score_emoji_lvl": "✨(역동적임)",
        "score_emoji_txt": "강렬하고 모든 것을 움직이며 변화를 일으키는 역동적인 상태입니다.",
        "score_prob_text": "이 운세의 지속 가능성은 90.0%로, 강한 긍정적 기운으로 좋은 결과를 기대할 수 있는 상태입니다."
      },
      "summary": {
        "desc_title": "재물운",
        "desc_root": "금전적인 성공과 재정적 안정이 매우 좋아집니다.",
        "desc_appl": "투자나 재테크 활동을 통해 기대 이상의 성과를 얻으며, 금전적인 여유가 생기고 이를 바탕으로 경제적 안정을 이루는 시기입니다. 적절한 투자 판단과 지속적인 노력을 통해 재정적 이익을 극대화할 수 있으며, 이를 통해 미래를 대비한 자산을 구축할 수 있습니다."
      },
      "good_feature": {
        "keywords": "재정적 안정, 투자 성공",
        "description": "재정적으로 안정되어 큰 투자를 하거나 재산을 불릴 수 있는 기회입니다.",
        "recommendation": "장기적인 재정 계획을 세우고, 감정적 만족감도 함께 추구하는 것이 필요합니다."
      },
      "bad_feature": {
        "keywords": "감정적 공허, 과도한 욕심",
        "description": "지나치게 금전적 성과에 집착하여 감정적인 만족감을 놓칠 수 있습니다.",
        "recommendation": "재정적 성과뿐만 아니라 개인적인 성취나 행복을 위한 활동을 균형 있게 계획하는 것이 좋습니다."
      }
    },
    "건강운": {
      "header": {
        "score": 3,
        "raw_score": 3.0,
        "probs": 72.5,
        "score_emoji_lvl": "🌕(조화로움)",
        "score_emoji_txt": "기운이 자연스럽게 흐르며 조화를 이루는 안정된 상태입니다.",
        "score_prob_text": "이 운세의 지속 가능성은 72.5%로, 긍정적인 기운이 있는 편입니다."
      },
      "summary": {
        "desc_title": "건강운",
        "desc_root": "건강 상태가 안정적이지만 특별한 변화는 없습니다.",
        "desc_appl": "일상생활에서 특별한 건강 문제는 없으나 면역력이나 체력이 다소 떨어질 수 있는 시기입니다. 무리한 활동이나 과도한 스트레스를 피하는 것이 필요하며, 적절한 휴식과 건강 관리로 이 시기를 잘 넘길 수 있습니다."
      },
      "good_feature": {
        "keywords": "안정성, 면역력 유지",
        "description": "큰 건강 문제는 발생하지 않으며, 기본적인 체력과 면역력이 유지됩니다.",
        "recommendation": "꾸준한 운동과 규칙적인 생활 습관을 통해 체력을 강화하고 건강을 유지해야 합니다."
      },
      "bad_feature": {
        "keywords": "체력 저하, 운동 부족",
        "description": "운동 부족이나 불규칙한 생활로 인해 체력이 감소할 수 있습니다.",
        "recommendation": "운동량을 늘리고 규칙적인 생활을 통해 체력 저하를 막고, 건강 상태를 개선해야 합니다."
      }
    },
    "이동운": {
      "header": {
        "score": 5,
        "raw_score": 4.8,
        "probs": 90.0,
        "score_emoji_lvl": "✨(역동적임)",
        "score_emoji_txt": "강렬하고 모든 것을 움직이며 변화를 일으키는 역동적인 상태입니다.",
        "score_prob_text": "이 운세의 지속 가능성은 90.0%로, 강한 긍정적 기운으로 좋은 결과를 기대할 수 있는 상태입니다."
      },
      "summary": {
        "desc_title": "이동운",
        "desc_root": "이동이나 여행에서 긍정적인 결과를 얻을 수 있습니다.",
        "desc_appl": "이사, 출장, 또는 여행이 계획대로 순조롭게 진행되며, 새로운 환경에 빠르게 적응하면서 그로 인한 새로운 기회를 잡을 수 있는 시기입니다. 이 과정에서 예상치 못한 긍정적인 변화나 기회를 발견할 가능성도 존재합니다."
      },
      "good_feature": {
        "keywords": "새로운 기회, 도전",
        "description": "새로운 환경에서의 도전과 성장이 기대되며, 이동 과정에서 좋은 인연을 만날 가능성이 큽니다.",
        "recommendation": "이동 후 충분한 휴식을 취하며, 새로운 환경에 적응하는 시간을 가지는 것이 중요합니다."
      },
      "bad_feature": {
        "keywords": "피로감, 무리",
        "description": "지나친 이동으로 인해 피로감을 느낄 수 있습니다.",
        "recommendation": "이동 전에 철저한 계획을 세워, 체력과 에너지를 적절히 분배하는 것이 필요합니다."
      }
    },
    "인간관계운": {
      "header": {
        "score": 1,
        "raw_score": 1.5,
        "probs": 55.02,
        "score_emoji_lvl": "🕯️(정체됨)",
        "score_emoji_txt": "기운이 멈추고 정체되어 흐르지 않는 상태로, 에너지 회복과 준비가 필요한 시기입니다.",
        "score_prob_text": "이 운세의 지속 가능성은 55.02%로, 부정적인 기운이 있으나 배품의 미덕이 있었다면 무난해 질 수 있습니다."
      },
      "summary": {
        "desc_title": "인간관계운",
        "desc_root": "인간관계에서 소통의 어려움을 겪을 수 있지만, 이를 회복할 기회를 찾을 수 있습니다.",
        "desc_appl": "관계가 잠시 소원해질 수 있지만, 이 과정에서 서로에 대한 더 깊은 이해와 신뢰를 쌓을 수 있는 기회를 얻을 수 있습니다. 이를 통해 미래에는 더욱 긴밀한 관계로 발전할 수 있는 토대를 마련하게 됩니다."
      },
      "good_feature": {
        "keywords": "관계 회복, 신뢰 형성",
        "description": "관계 회복을 위한 좋은 대화의 기회가 마련되며, 이를 통해 인간관계를 개선할 수 있습니다.",
        "recommendation": "소통의 기회를 자주 마련하고, 감정을 나누는 대화를 통해 관계를 회복할 수 있습니다."
      },
      "bad_feature": {
        "keywords": "소원한 관계, 소통 어려움",
        "description": "잠시 관계가 소원해질 수 있지만, 이를 회복하는 과정에서 성장할 수 있습니다.",
        "recommendation": "인간관계에서 오해를 풀기 위해 대화와 이해를 바탕으로 서로의 감정을 존중하는 노력이 필요합니다."
      }
    },
    "명예운": {
      "header": {
        "score": 3,
        "raw_score": 3.3,
        "probs": 83.62,
        "score_emoji_lvl": "🌕(조화로움)",
        "score_emoji_txt": "기운이 자연스럽게 흐르며 조화를 이루는 안정된 상태입니다.",
        "score_prob_text": "이 운세의 지속 가능성은 83.62%로, 긍정적인 기운이 있는 편입니다."
      },
      "summary": {
        "desc_title": "명예운",
        "desc_root": "명예나 평판이 안정적으로 유지됩니다.",
        "desc_appl": "큰 변화는 없지만, 현재의 사회적 평판을 유지하며 안정적인 위치에서 활동을 이어갈 수 있습니다. 특별한 성과나 변화는 없을 수 있지만, 평온한 상태가 지속될 가능성이 큽니다."
      },
      "good_feature": {
        "keywords": "안정성, 평판 유지",
        "description": "큰 변화 없이 현재의 평판을 유지하며, 사회적으로 안정적인 위치를 가질 수 있습니다.",
        "recommendation": "현재의 위치를 유지하면서도 꾸준한 자기 계발을 통해 명성을 높이는 것이 필요합니다."
      },
      "bad_feature": {
        "keywords": "명예 상승의 정체",
        "description": "명성을 더 높이는 데 다소 시간이 걸릴 수 있습니다.",
        "recommendation": "정기적인 사회 활동이나 프로젝트를 통해 새로운 기회를 모색하는 것이 중요합니다."
      }
    },
    "사회공헌운": {
      "header": {
        "score": 3,
        "raw_score": 3.0,
        "probs": 72.5,
        "score_emoji_lvl": "🌕(조화로움)",
        "score_emoji_txt": "기운이 자연스럽게 흐르며 조화를 이루는 안정된 상태입니다.",
        "score_prob_text": "이 운세의 지속 가능성은 72.5%로, 긍정적인 기운이 있는 편입니다."
      },
      "summary": {
        "desc_title": "사회공헌운",
        "desc_root": "사회 공헌 활동이 안정적으로 유지됩니다.",
        "desc_appl": "꾸준한 자선 활동이나 기부를 통해 사회적 기여를 지속적으로 유지할 수 있으며, 이를 통해 사회적 위치를 안정적으로 유지할 수 있습니다. 이러한 활동은 개인의 이미지를 긍정적으로 형성하는 데 중요한 역할을 합니다."
      },
      "good_feature": {
        "keywords": "안정성, 성취감",
        "description": "큰 변화 없이 사회 공헌 활동을 유지하며, 안정적인 성취감을 얻을 수 있습니다.",
        "recommendation": "안정적인 활동을 유지하면서도 새로운 공헌 기회를 모색하는 것이 필요합니다."
      },
      "bad_feature": {
        "keywords": "새로운 기회 부족",
        "description": "새로운 기회를 찾는 데 다소 어려움을 겪을 수 있습니다.",
        "recommendation": "자선 활동의 범위를 넓히고, 새로운 사회적 프로젝트에 참여하는 것이 좋습니다."
      }
    },
  },
  "성향통계": {
    "stats": {
      "EI": {
        "rst": "E",
        "pnt": 5.0,
        "rto": 79.34
      },
      "NS": {
        "rst": "S",
        "pnt": 4.37,
        "rto": 71.75
      },
      "FT": {
        "rst": "F",
        "pnt": 3.88,
        "rto": 65.03
      },
      "PJ": {
        "rst": "P",
        "pnt": 3.64,
        "rto": 62.26
      }
    },
    "score": {
      "학습운": 3.0,
      "직업운": 1.5,
      "애정운": 3.0,
      "가족운": 3.3,
      "재물운": 4.8,
      "건강운": 3.0,
      "이동운": 4.8,
      "인간관계운": 1.5,
      "명예운": 3.3,
      "사회공헌운": 3.0
    }
  },
  "생활추천": {
    "학습운": {
      "운세종류": "학습운",
      "운세기간": "일운",
      "현재상황": "학습운이 안정적인 날입니다. 꾸준한 노력으로 성과를 유지할 수 있습니다.\n오늘은 다른 사람들과 교류하며 에너지를 얻고 그리고 현실적인 계획을 세워 성향이 두드러지는 날입니다. 이러한 특성들을 잘 활용하되, 균형 잡힌 접근이 필요한 때입니다.",
      "맞춤제안": [
        "활발하고 활동적인 특성이 강합니다. 다양한 방법 중 개인 학습 진행이 긍정적인 영향을 줄 수 있습니다. 동료나 가족과 협력하며 학습 기회를 만들어보세요."
      ],
      "활동제안": {
        "추천활동": ["스터디 그룹 운영"],
        "보조활동": ["연구 모임 참여"],
        "주의활동": ["단독 연구에만 집중"]
      },
      "자기관리": {
        "주의사항": ["세미나와 외부 활동에 치중하며 학습 목표 지연"],
        "멘탈관리": ["스터디 그룹 참여"],
        "점검항목": {
          "일일": ["오늘의 학습 주제 선정 및 공부 시간 계획"]
        }
      }
    },
    "직업운": {
      "운세종류": "직업운",
      "운세기간": "일운",
      "현재상황": "직업운이 저조한 날입니다. 현상 유지에 집중하고 리스크는 최소화하세요.\n오늘은 다른 사람들과 교류하며 에너지를 얻고 그리고 현실적인 계획을 세워 성향이 두드러지는 날입니다. 이러한 특성들을 잘 활용하되, 균형 잡힌 접근이 필요한 때입니다.",
      "맞춤제안": [
        "활발하고 활동적인 특성이 강합니다. 다양한 방법 중 독립적인 프로젝트 진행이 긍정적인 영향을 줄 수 있습니다. 팀워크를 강화하며 프로젝트를 성공적으로 이끌어보세요."
      ],
      "활동제안": {
        "추천활동": ["팀 리더 역할"],
        "보조활동": ["대규모 프로젝트 기획"],
        "주의활동": ["독단적인 의사결정"]
      },
      "자기관리": {
        "주의사항": ["과도한 네트워킹으로 핵심 업무 소홀"],
        "멘탈관리": ["팀원과의 편안한 대화"],
        "점검항목": {
          "일일": ["오늘의 핵심 업무 3가지 선정"]
        }
      }
    },
    "애정운": {
      "운세종류": "애정운",
      "운세기간": "일운",
      "현재상황": "애정운이 무난한 날입니다. 오늘은 관계를 잘 유지하는데 집중하세요.\n오늘은 다른 사람들과 교류하며 에너지를 얻고 그리고 현실적인 계획을 세워 성향이 두드러지는 날입니다. 이러한 특성들을 잘 활용하되, 균형 잡힌 접근이 필요한 때입니다.",
      "맞춤제안": [
        "활발하고 활동적인 특성이 강합니다. 다른 접근법으로 신중한 관계 조율을 시도해보시는 것도 좋은 선택입니다. 다양한 사회적 활동으로 새로운 사람을 만나보세요."
      ],
      "활동제안": {
        "추천활동": ["단체 활동에서 새로운 만남 시도"],
        "보조활동": ["네트워킹 이벤트 참여"],
        "주의활동": ["지나친 외향적 태도로 피로감 유발"]
      },
      "자기관리": {
        "주의사항": ["과도한 소통으로 상대 피로감 유발"],
        "멘탈관리": ["사회적 모임에서 적극적인 소통"],
        "점검항목": {
          "일일": ["파트너와의 대화 시간 확보"]
        }
      }
    },
    "가족운": {
      "운세종류": "가족운",
      "운세기간": "일운",
      "현재상황": "가족운이 안정적인 날입니다. 평화로운 가족 생활을 유지할 수 있습니다.\n오늘은 다른 사람들과 교류하며 에너지를 얻고 그리고 현실적인 계획을 세워 성향이 두드러지는 날입니다. 이러한 특성들을 잘 활용하되, 균형 잡힌 접근이 필요한 때입니다.",
      "맞춤제안": [
        "활발하고 활동적인 특성이 강합니다. 다른 접근법으로 개인적인 시간 조율을 시도해보시는 것도 좋은 선택입니다. 가족 간의 소통을 늘리며 협력적인 분위기를 조성하세요."
      ],
      "활동제안": {
        "추천활동": ["가족 행사 기획"],
        "보조활동": ["가족 모임 주도"],
        "주의활동": ["가족 행사에 소홀"]
      },
      "자기관리": {
        "주의사항": ["가족과의 지나친 활동으로 피로 누적"],
        "멘탈관리": ["가족 간 공동 프로젝트 기획"],
        "점검항목": {
          "일일": ["가족 구성원에게 짧은 감사 인사 전하기"]
        }
      }
    },
    "재물운": {
      "운세종류": "재물운",
      "운세기간": "일운",
      "현재상황": "재물운이 매우 좋은 날입니다. 투자나 수익 창출의 기회가 많을 것 같습니다.\n오늘은 다른 사람들과 교류하며 에너지를 얻고 그리고 현실적인 계획을 세워 성향이 두드러지는 날입니다. 이러한 특성들을 잘 활용하되, 균형 잡힌 접근이 필요한 때입니다.",
      "맞춤제안": [
        "활발하고 활동적인 특성이 강합니다. 다른 접근법으로 재정 목표 점검을 시도해보시는 것도 좋은 선택입니다. 네트워크를 활용해 재정적인 협력을 이끌어보세요."
      ],
      "활동제안": {
        "추천활동": ["다양한 재테크 모임 참여"],
        "보조활동": ["재정 관련 세미나 참석"],
        "주의활동": ["즉흥적인 투자"]
      },
      "자기관리": {
        "주의사항": ["충동적인 투자로 손실 위험"],
        "멘탈관리": ["투자 관련 네트워킹"],
        "점검항목": {
          "일일": ["지출 내역을 기록하며 관리"]
        }
      }
    },
    "건강운": {
      "운세종류": "건강운",
      "운세기간": "일운",
      "현재상황": "건강운이 평이한 날입니다. 기존의 건강 관리 루틴을 잘 유지하세요.\n오늘은 다른 사람들과 교류하며 에너지를 얻고 그리고 현실적인 계획을 세워 성향이 두드러지는 날입니다. 이러한 특성들을 잘 활용하되, 균형 잡힌 접근이 필요한 때입니다.",
      "맞춤제안": [
        "활발하고 활동적인 특성이 강합니다. 다른 접근법으로 개인 건강 데이터 관리를 시도해보시는 것도 좋은 선택입니다. 동료와 함께 운동하며 협력을 통해 동기를 유지하세요."
      ],
      "활동제안": {
        "추천활동": ["활동적인 운동 프로그램 참여"],
        "보조활동": ["스포츠 동호회 활동"],
        "주의활동": ["무리한 운동으로 체력 고갈"]
      },
      "자기관리": {
        "주의사항": ["과도한 외부 활동으로 체력 소모"],
        "멘탈관리": ["그룹 운동 클래스 참여"],
        "점검항목": {
          "일일": ["수분 섭취 충분히 하기"]
        }
      }
    },
    "이동운": {
      "운세종류": "이동운",
      "운세기간": "일운",
      "현재상황": "이동운이 매우 좋은 날입니다. 여행이나 새로운 환경으로의 변화가 긍정적입니다.\n오늘은 다른 사람들과 교류하며 에너지를 얻고 그리고 현실적인 계획을 세워 성향이 두드러지는 날입니다. 이러한 특성들을 잘 활용하되, 균형 잡힌 접근이 필요한 때입니다.",
      "맞춤제안": [
        "활발하고 활동적인 특성이 강합니다. 다른 접근법으로 개인적인 변화 준비를 시도해보시는 것도 좋은 선택입니다. 새로운 지역에서 사람들과 활발히 소통해보세요."
      ],
      "활동제안": {
        "추천활동": ["출장 계획 수립"],
        "보조활동": ["이동 중 네트워킹 강화"],
        "주의활동": ["즉흥적인 이동으로 시간 낭비"]
      },
      "자기관리": {
        "주의사항": ["즉흥적 이동 계획으로 시간과 자원 낭비"],
        "멘탈관리": ["단체 여행 모임 주관"],
        "점검항목": {
          "일일": ["출퇴근 경로 확인 및 준비"]
        }
      }
    },
    "인간관계운": {
      "운세종류": "인간관계운",
      "운세기간": "일운",
      "현재상황": "인간관계운이 약한 날입니다. 불필요한 갈등을 피하고 신중한 소통이 필요합니다.\n오늘은 다른 사람들과 교류하며 에너지를 얻고 그리고 현실적인 계획을 세워 성향이 두드러지는 날입니다. 이러한 특성들을 잘 활용하되, 균형 잡힌 접근이 필요한 때입니다.",
      "맞춤제안": [
        "활발하고 활동적인 특성이 강합니다. 다양한 방법 중 개인적인 관계 유지가 긍정적인 영향을 줄 수 있습니다. 다양한 활동으로 사람들과 친밀감을 쌓아보세요."
      ],
      "활동제안": {
        "추천활동": ["사회적 모임 주최"],
        "보조활동": ["팀 프로젝트 참여"],
        "주의활동": ["과도한 활동으로 피로 누적"]
      },
      "자기관리": {
        "주의사항": ["과도한 네트워킹으로 시간 관리 실패"],
        "멘탈관리": ["네트워킹 이벤트 참여"],
        "점검항목": {
          "일일": ["직업 내 동료와 대화"]
        }
      }
    },
    "명예운": {
      "운세종류": "명예운",
      "운세기간": "일운",
      "현재상황": "명예운이 안정적인 날입니다. 오늘은의 위치에서 책임을 다하도록 하세요.\n오늘은 다른 사람들과 교류하며 에너지를 얻고 그리고 현실적인 계획을 세워 성향이 두드러지는 날입니다. 이러한 특성들을 잘 활용하되, 균형 잡힌 접근이 필요한 때입니다.",
      "맞춤제안": [
        "활발하고 활동적인 특성이 강합니다. 다른 접근법으로 개인적 성과 발표를 시도해보시는 것도 좋은 선택입니다. 사회적 활동으로 명성을 높여보세요."
      ],
      "활동제안": {
        "추천활동": ["사회적 네트워킹 주도"],
        "보조활동": ["직업 내 리더십 발휘"],
        "주의활동": ["과도한 주목으로 부정적 이미지 형성"]
      },
      "자기관리": {
        "주의사항": ["과도한 활동으로 핵심 업무 소홀"],
        "멘탈관리": ["대규모 프로젝트 주도"],
        "점검항목": {
          "일일": ["업무에서의 성과 점검"]
        }
      }
    },
    "사회공헌운": {
      "운세종류": "사회공헌운",
      "운세기간": "일운",
      "현재상황": "사회공헌운이 평이한 날입니다. 지속적인 활동으로 가치를 이어가세요.\n오늘은 다른 사람들과 교류하며 에너지를 얻고 그리고 현실적인 계획을 세워 성향이 두드러지는 날입니다. 이러한 특성들을 잘 활용하되, 균형 잡힌 접근이 필요한 때입니다.",
      "맞춤제안": [
        "활발하고 활동적인 특성이 강합니다. 조용한 공헌 활동과 같은 활동이 새로운 균형을 찾는 데 도움이 될 것입니다. 사회적 활동을 주도하며 공동체에 긍정적인 영향을 미쳐보세요."
      ],
      "활동제안": {
        "추천활동": ["지역 사회 캠페인 주관"],
        "보조활동": ["회사 봉사활동 기획"],
        "주의활동": ["주목받으려는 과도한 활동"]
      },
      "자기관리": {
        "주의사항": ["과도한 네트워킹으로 실질적 기여 부족"],
        "멘탈관리": ["대규모 자선 행사 주최"],
        "점검항목": {
          "일일": ["사회적 활동 일정 확인"]
        }
      }
    }
  }
}

// {
//   "success": true,
//   "data": {
//     "명주정보": {
//       "성명": "손흥민",
//       "나이": 34,
//       "남여": "남자",
//       "시기": "청년",
//       "생일": "1992-07-08 00:08"
//     },
//     "월별일운": {
//       "20250101": {
//         "일운갑자": "경오",
//         "일운갑자한자": "庚午",
//         "갑자의미": {
//           "상징": "하얀 말의 날",
//           "의미": "자유와 독립"
//         },
//         "일운점수": 2.56,
//         "일운지장간": {
//           "cc": "경",
//           "gg": "오",
//           "ggj": [
//             "병",
//             "기",
//             "정"
//           ]
//         },
//         "십성지장간": {
//           "cc": "편관",
//           "gg": "상관",
//           "ggj": [
//             "식신",
//             "편재",
//             "식신"
//           ]
//         },
//         "오행갯수": {
//           "목": 1,
//           "화": 2,
//           "토": 2,
//           "금": 4,
//           "수": 1
//         },
//         "일운해석": {
//           "level": 2,
//           "word": "흐림",
//           "remark": "흐린 날씨처럼 약간의 변화를 준비하며 신중하게 상황을 살피는 단계입니다.",
//           "emoji": "🌥️"
//         }
//       },
//       "20250102": {
//         "일운갑자": "신미",
//         "일운갑자한자": "辛未",
//         "갑자의미": {
//           "상징": "하얀 양의 날",
//           "의미": "평화와 헌신"
//         },
//         "일운점수": 3.34,
//         "일운지장간": {
//           "cc": "신",
//           "gg": "미",
//           "ggj": [
//             "정",
//             "을",
//             "기"
//           ]
//         },
//         "십성지장간": {
//           "cc": "정관",
//           "gg": "편재",
//           "ggj": [
//             "식신",
//             "비견",
//             "편재"
//           ]
//         },
//         "오행갯수": {
//           "목": 1,
//           "화": 1,
//           "토": 3,
//           "금": 4,
//           "수": 1
//         },
//         "일운해석": {
//           "level": 3,
//           "word": "맑음",
//           "remark": "맑고 쾌청한 하늘처럼 안정적이고 조화로운 상태입니다.",
//           "emoji": "🌤️"
//         }
//       },
//       "20250103": {
//         "일운갑자": "임신",
//         "일운갑자한자": "壬申",
//         "갑자의미": {
//           "상징": "검은 원숭이의 날",
//           "의미": "창의성과 지혜"
//         },
//         "일운점수": 3.84,
//         "일운지장간": {
//           "cc": "임",
//           "gg": "신",
//           "ggj": [
//             "무",
//             "임",
//             "경"
//           ]
//         },
//         "십성지장간": {
//           "cc": "정인",
//           "gg": "정관",
//           "ggj": [
//             "편재",
//             "정인",
//             "편관"
//           ]
//         },
//         "오행갯수": {
//           "목": 1,
//           "화": 1,
//           "토": 2,
//           "금": 4,
//           "수": 2
//         },
//         "일운해석": {
//           "level": 4,
//           "word": "따뜻",
//           "remark": "따뜻한 햇살 아래 활기를 되찾으며 생명력을 발휘하는 상태입니다.",
//           "emoji": "☀️"
//         }
//       },
//       "20250104": {
//         "일운갑자": "계유",
//         "일운갑자한자": "癸酉",
//         "갑자의미": {
//           "상징": "검은 닭의 날",
//           "의미": "성실과 완성"
//         },
//         "일운점수": 3.84,
//         "일운지장간": {
//           "cc": "계",
//           "gg": "유",
//           "ggj": [
//             "경",
//             "신"
//           ]
//         },
//         "십성지장간": {
//           "cc": "정인",
//           "gg": "편관",
//           "ggj": [
//             "편관",
//             "정관"
//           ]
//         },
//         "오행갯수": {
//           "목": 1,
//           "화": 1,
//           "토": 2,
//           "금": 4,
//           "수": 2
//         },
//         "일운해석": {
//           "level": 4,
//           "word": "따뜻",
//           "remark": "따뜻한 햇살 아래 활기를 되찾으며 생명력을 발휘하는 상태입니다.",
//           "emoji": "☀️"
//         }
//       },
//       "20250105": {
//         "일운갑자": "갑술",
//         "일운갑자한자": "甲戌",
//         "갑자의미": {
//           "상징": "푸른 개의 날",
//           "의미": "충성심과 책임"
//         },
//         "일운점수": 3.04,
//         "일운지장간": {
//           "cc": "갑",
//           "gg": "술",
//           "ggj": [
//             "신",
//             "정",
//             "무"
//           ]
//         },
//         "십성지장간": {
//           "cc": "비견",
//           "gg": "정재",
//           "ggj": [
//             "정관",
//             "식신",
//             "편재"
//           ]
//         },
//         "오행갯수": {
//           "목": 2,
//           "화": 1,
//           "토": 3,
//           "금": 3,
//           "수": 1
//         },
//         "일운해석": {
//           "level": 3,
//           "word": "맑음",
//           "remark": "맑고 쾌청한 하늘처럼 안정적이고 조화로운 상태입니다.",
//           "emoji": "🌤️"
//         }
//       },
//       "20250106": {
//         "일운갑자": "을해",
//         "일운갑자한자": "乙亥",
//         "갑자의미": {
//           "상징": "푸른 돼지의 날",
//           "의미": "풍요와 안정"
//         },
//         "일운점수": 3.24,
//         "일운지장간": {
//           "cc": "을",
//           "gg": "해",
//           "ggj": [
//             "무",
//             "갑",
//             "임"
//           ]
//         },
//         "십성지장간": {
//           "cc": "비견",
//           "gg": "정인",
//           "ggj": [
//             "편재",
//             "비견",
//             "정인"
//           ]
//         },
//         "오행갯수": {
//           "목": 2,
//           "화": 1,
//           "토": 2,
//           "금": 3,
//           "수": 2
//         },
//         "일운해석": {
//           "level": 3,
//           "word": "맑음",
//           "remark": "맑고 쾌청한 하늘처럼 안정적이고 조화로운 상태입니다.",
//           "emoji": "🌤️"
//         }
//       },
//       "20250107": {
//         "일운갑자": "병자",
//         "일운갑자한자": "丙子",
//         "갑자의미": {
//           "상징": "붉은 쥐의 날",
//           "의미": "활력과 지혜"
//         },
//         "일운점수": 3.12,
//         "일운지장간": {
//           "cc": "병",
//           "gg": "자",
//           "ggj": [
//             "임",
//             "계"
//           ]
//         },
//         "십성지장간": {
//           "cc": "식신",
//           "gg": "편인",
//           "ggj": [
//             "정인",
//             "정인"
//           ]
//         },
//         "오행갯수": {
//           "목": 1,
//           "화": 2,
//           "토": 2,
//           "금": 3,
//           "수": 2
//         },
//         "일운해석": {
//           "level": 3,
//           "word": "맑음",
//           "remark": "맑고 쾌청한 하늘처럼 안정적이고 조화로운 상태입니다.",
//           "emoji": "🌤️"
//         }
//       },
//       "20250108": {
//         "일운갑자": "정축",
//         "일운갑자한자": "丁丑",
//         "갑자의미": {
//           "상징": "붉은 소의 날",
//           "의미": "근면과 성실"
//         },
//         "일운점수": 3.12,
//         "일운지장간": {
//           "cc": "정",
//           "gg": "축",
//           "ggj": [
//             "계",
//             "신",
//             "기"
//           ]
//         },
//         "십성지장간": {
//           "cc": "식신",
//           "gg": "편재",
//           "ggj": [
//             "정인",
//             "정관",
//             "편재"
//           ]
//         },
//         "오행갯수": {
//           "목": 1,
//           "화": 2,
//           "토": 3,
//           "금": 3,
//           "수": 1
//         },
//         "일운해석": {
//           "level": 3,
//           "word": "맑음",
//           "remark": "맑고 쾌청한 하늘처럼 안정적이고 조화로운 상태입니다.",
//           "emoji": "🌤️"
//         }
//       },
//       "20250109": {
//         "일운갑자": "무인",
//         "일운갑자한자": "戊寅",
//         "갑자의미": {
//           "상징": "황금 호랑이의 날",
//           "의미": "용맹과 성장"
//         },
//         "일운점수": 2.69,
//         "일운지장간": {
//           "cc": "무",
//           "gg": "인",
//           "ggj": [
//             "무",
//             "병",
//             "갑"
//           ]
//         },
//         "십성지장간": {
//           "cc": "편재",
//           "gg": "비겁",
//           "ggj": [
//             "편재",
//             "식신",
//             "비견"
//           ]
//         },
//         "오행갯수": {
//           "목": 2,
//           "화": 1,
//           "토": 3,
//           "금": 3,
//           "수": 1
//         },
//         "일운해석": {
//           "level": 2,
//           "word": "흐림",
//           "remark": "흐린 날씨처럼 약간의 변화를 준비하며 신중하게 상황을 살피는 단계입니다.",
//           "emoji": "🌥️"
//         }
//       },
//       "20250110": {
//         "일운갑자": "기묘",
//         "일운갑자한자": "己卯",
//         "갑자의미": {
//           "상징": "황금 토끼의 날",
//           "의미": "균형과 평화"
//         },
//         "일운점수": 2.84,
//         "일운지장간": {
//           "cc": "기",
//           "gg": "묘",
//           "ggj": [
//             "갑",
//             "을",
//             "진"
//           ]
//         },
//         "십성지장간": {
//           "cc": "편재",
//           "gg": "비견",
//           "ggj": [
//             "비견",
//             "비견",
//             "정재"
//           ]
//         },
//         "오행갯수": {
//           "목": 2,
//           "화": 1,
//           "토": 3,
//           "금": 3,
//           "수": 1
//         },
//         "일운해석": {
//           "level": 2,
//           "word": "흐림",
//           "remark": "흐린 날씨처럼 약간의 변화를 준비하며 신중하게 상황을 살피는 단계입니다.",
//           "emoji": "🌥️"
//         }
//       },
//       "20250111": {
//         "일운갑자": "경진",
//         "일운갑자한자": "庚辰",
//         "갑자의미": {
//           "상징": "하얀 용의 날",
//           "의미": "위엄과 성공"
//         },
//         "일운점수": 3.54,
//         "일운지장간": {
//           "cc": "경",
//           "gg": "진",
//           "ggj": [
//             "을",
//             "계",
//             "무"
//           ]
//         },
//         "십성지장간": {
//           "cc": "편관",
//           "gg": "정재",
//           "ggj": [
//             "비견",
//             "정인",
//             "편재"
//           ]
//         },
//         "오행갯수": {
//           "목": 1,
//           "화": 1,
//           "토": 3,
//           "금": 4,
//           "수": 1
//         },
//         "일운해석": {
//           "level": 3,
//           "word": "맑음",
//           "remark": "맑고 쾌청한 하늘처럼 안정적이고 조화로운 상태입니다.",
//           "emoji": "🌤️"
//         }
//       },
//       "20250112": {
//         "일운갑자": "신사",
//         "일운갑자한자": "申巳",
//         "갑자의미": {
//           "상징": "하얀 뱀의 날",
//           "의미": "지혜와 신비"
//         },
//         "일운점수": 2.71,
//         "일운지장간": {
//           "cc": "신",
//           "gg": "사",
//           "ggj": [
//             "무",
//             "경",
//             "병"
//           ]
//         },
//         "십성지장간": {
//           "cc": "정관",
//           "gg": "식신",
//           "ggj": [
//             "편재",
//             "편관",
//             "식신"
//           ]
//         },
//         "오행갯수": {
//           "목": 1,
//           "화": 2,
//           "토": 2,
//           "금": 4,
//           "수": 1
//         },
//         "일운해석": {
//           "level": 2,
//           "word": "흐림",
//           "remark": "흐린 날씨처럼 약간의 변화를 준비하며 신중하게 상황을 살피는 단계입니다.",
//           "emoji": "🌥️"
//         }
//       },
//       "20250113": {
//         "일운갑자": "임오",
//         "일운갑자한자": "壬午",
//         "갑자의미": {
//           "상징": "검은 말의 날",
//           "의미": "열정과 추진력"
//         },
//         "일운점수": 2.56,
//         "일운지장간": {
//           "cc": "임",
//           "gg": "오",
//           "ggj": [
//             "병",
//             "기",
//             "정"
//           ]
//         },
//         "십성지장간": {
//           "cc": "정인",
//           "gg": "상관",
//           "ggj": [
//             "식신",
//             "편재",
//             "식신"
//           ]
//         },
//         "오행갯수": {
//           "목": 1,
//           "화": 2,
//           "토": 2,
//           "금": 3,
//           "수": 2
//         },
//         "일운해석": {
//           "level": 2,
//           "word": "흐림",
//           "remark": "흐린 날씨처럼 약간의 변화를 준비하며 신중하게 상황을 살피는 단계입니다.",
//           "emoji": "🌥️"
//         }
//       },
//       "20250114": {
//         "일운갑자": "계미",
//         "일운갑자한자": "癸未",
//         "갑자의미": {
//           "상징": "검은 양의 날",
//           "의미": "조화와 안정"
//         },
//         "일운점수": 3.19,
//         "일운지장간": {
//           "cc": "계",
//           "gg": "미",
//           "ggj": [
//             "정",
//             "을",
//             "기"
//           ]
//         },
//         "십성지장간": {
//           "cc": "정인",
//           "gg": "편재",
//           "ggj": [
//             "식신",
//             "비견",
//             "편재"
//           ]
//         },
//         "오행갯수": {
//           "목": 1,
//           "화": 1,
//           "토": 3,
//           "금": 3,
//           "수": 2
//         },
//         "일운해석": {
//           "level": 3,
//           "word": "맑음",
//           "remark": "맑고 쾌청한 하늘처럼 안정적이고 조화로운 상태입니다.",
//           "emoji": "🌤️"
//         }
//       },
//       "20250115": {
//         "일운갑자": "갑신",
//         "일운갑자한자": "甲申",
//         "갑자의미": {
//           "상징": "푸른 원숭이의 날",
//           "의미": "기민함과 창의"
//         },
//         "일운점수": 3.74,
//         "일운지장간": {
//           "cc": "갑",
//           "gg": "신",
//           "ggj": [
//             "무",
//             "임",
//             "경"
//           ]
//         },
//         "십성지장간": {
//           "cc": "비견",
//           "gg": "정관",
//           "ggj": [
//             "편재",
//             "정인",
//             "편관"
//           ]
//         },
//         "오행갯수": {
//           "목": 2,
//           "화": 1,
//           "토": 2,
//           "금": 4,
//           "수": 1
//         },
//         "일운해석": {
//           "level": 4,
//           "word": "따뜻",
//           "remark": "따뜻한 햇살 아래 활기를 되찾으며 생명력을 발휘하는 상태입니다.",
//           "emoji": "☀️"
//         }
//       },
//       "20250116": {
//         "일운갑자": "을유",
//         "일운갑자한자": "乙酉",
//         "갑자의미": {
//           "상징": "푸른 닭의 날",
//           "의미": "신뢰와 성실"
//         },
//         "일운점수": 3.59,
//         "일운지장간": {
//           "cc": "을",
//           "gg": "유",
//           "ggj": [
//             "경",
//             "신"
//           ]
//         },
//         "십성지장간": {
//           "cc": "비견",
//           "gg": "편관",
//           "ggj": [
//             "편관",
//             "정관"
//           ]
//         },
//         "오행갯수": {
//           "목": 2,
//           "화": 1,
//           "토": 2,
//           "금": 4,
//           "수": 1
//         },
//         "일운해석": {
//           "level": 3,
//           "word": "맑음",
//           "remark": "맑고 쾌청한 하늘처럼 안정적이고 조화로운 상태입니다.",
//           "emoji": "🌤️"
//         }
//       },
//       "20250117": {
//         "일운갑자": "병술",
//         "일운갑자한자": "丙戌",
//         "갑자의미": {
//           "상징": "붉은 개의 날",
//           "의미": "충성심과 열정"
//         },
//         "일운점수": 2.77,
//         "일운지장간": {
//           "cc": "병",
//           "gg": "술",
//           "ggj": [
//             "신",
//             "정",
//             "무"
//           ]
//         },
//         "십성지장간": {
//           "cc": "식신",
//           "gg": "정재",
//           "ggj": [
//             "정관",
//             "식신",
//             "편재"
//           ]
//         },
//         "오행갯수": {
//           "목": 1,
//           "화": 2,
//           "토": 3,
//           "금": 3,
//           "수": 1
//         },
//         "일운해석": {
//           "level": 2,
//           "word": "흐림",
//           "remark": "흐린 날씨처럼 약간의 변화를 준비하며 신중하게 상황을 살피는 단계입니다.",
//           "emoji": "🌥️"
//         }
//       },
//       "20250118": {
//         "일운갑자": "정해",
//         "일운갑자한자": "丁亥",
//         "갑자의미": {
//           "상징": "붉은 돼지의 날",
//           "의미": "풍요와 생명"
//         },
//         "일운점수": 3.12,
//         "일운지장간": {
//           "cc": "정",
//           "gg": "해",
//           "ggj": [
//             "무",
//             "갑",
//             "임"
//           ]
//         },
//         "십성지장간": {
//           "cc": "식신",
//           "gg": "정인",
//           "ggj": [
//             "편재",
//             "비견",
//             "정인"
//           ]
//         },
//         "오행갯수": {
//           "목": 1,
//           "화": 2,
//           "토": 2,
//           "금": 3,
//           "수": 2
//         },
//         "일운해석": {
//           "level": 3,
//           "word": "맑음",
//           "remark": "맑고 쾌청한 하늘처럼 안정적이고 조화로운 상태입니다.",
//           "emoji": "🌤️"
//         }
//       },
//       "20250119": {
//         "일운갑자": "무자",
//         "일운갑자한자": "戊子",
//         "갑자의미": {
//           "상징": "황금 쥐의 날",
//           "의미": "번영과 지혜"
//         },
//         "일운점수": 3.39,
//         "일운지장간": {
//           "cc": "무",
//           "gg": "자",
//           "ggj": [
//             "임",
//             "계"
//           ]
//         },
//         "십성지장간": {
//           "cc": "편재",
//           "gg": "편인",
//           "ggj": [
//             "정인",
//             "정인"
//           ]
//         },
//         "오행갯수": {
//           "목": 1,
//           "화": 1,
//           "토": 3,
//           "금": 3,
//           "수": 2
//         },
//         "일운해석": {
//           "level": 3,
//           "word": "맑음",
//           "remark": "맑고 쾌청한 하늘처럼 안정적이고 조화로운 상태입니다.",
//           "emoji": "🌤️"
//         }
//       },
//       "20250120": {
//         "일운갑자": "기축",
//         "일운갑자한자": "己丑",
//         "갑자의미": {
//           "상징": "황금 소의 날",
//           "의미": "안정과 책임"
//         },
//         "일운점수": 3.54,
//         "일운지장간": {
//           "cc": "기",
//           "gg": "축",
//           "ggj": [
//             "계",
//             "신",
//             "기"
//           ]
//         },
//         "십성지장간": {
//           "cc": "편재",
//           "gg": "편재",
//           "ggj": [
//             "정인",
//             "정관",
//             "편재"
//           ]
//         },
//         "오행갯수": {
//           "목": 1,
//           "화": 1,
//           "토": 4,
//           "금": 3,
//           "수": 1
//         },
//         "일운해석": {
//           "level": 3,
//           "word": "맑음",
//           "remark": "맑고 쾌청한 하늘처럼 안정적이고 조화로운 상태입니다.",
//           "emoji": "🌤️"
//         }
//       },
//       "20250121": {
//         "일운갑자": "경인",
//         "일운갑자한자": "庚寅",
//         "갑자의미": {
//           "상징": "하얀 호랑이의 날",
//           "의미": "용기와 도전"
//         },
//         "일운점수": 2.84,
//         "일운지장간": {
//           "cc": "경",
//           "gg": "인",
//           "ggj": [
//             "무",
//             "병",
//             "갑"
//           ]
//         },
//         "십성지장간": {
//           "cc": "편관",
//           "gg": "비겁",
//           "ggj": [
//             "편재",
//             "식신",
//             "비견"
//           ]
//         },
//         "오행갯수": {
//           "목": 2,
//           "화": 1,
//           "토": 2,
//           "금": 4,
//           "수": 1
//         },
//         "일운해석": {
//           "level": 2,
//           "word": "흐림",
//           "remark": "흐린 날씨처럼 약간의 변화를 준비하며 신중하게 상황을 살피는 단계입니다.",
//           "emoji": "🌥️"
//         }
//       },
//       "20250122": {
//         "일운갑자": "신묘",
//         "일운갑자한자": "辛卯",
//         "갑자의미": {
//           "상징": "하얀 토끼의 날",
//           "의미": "평화와 순수"
//         },
//         "일운점수": 2.99,
//         "일운지장간": {
//           "cc": "신",
//           "gg": "묘",
//           "ggj": [
//             "갑",
//             "을",
//             "진"
//           ]
//         },
//         "십성지장간": {
//           "cc": "정관",
//           "gg": "비견",
//           "ggj": [
//             "비견",
//             "비견",
//             "정재"
//           ]
//         },
//         "오행갯수": {
//           "목": 2,
//           "화": 1,
//           "토": 2,
//           "금": 4,
//           "수": 1
//         },
//         "일운해석": {
//           "level": 2,
//           "word": "흐림",
//           "remark": "흐린 날씨처럼 약간의 변화를 준비하며 신중하게 상황을 살피는 단계입니다.",
//           "emoji": "🌥️"
//         }
//       },
//       "20250123": {
//         "일운갑자": "임진",
//         "일운갑자한자": "壬辰",
//         "갑자의미": {
//           "상징": "검은 용의 날",
//           "의미": "권위와 재생"
//         },
//         "일운점수": 3.54,
//         "일운지장간": {
//           "cc": "임",
//           "gg": "진",
//           "ggj": [
//             "을",
//             "계",
//             "무"
//           ]
//         },
//         "십성지장간": {
//           "cc": "정인",
//           "gg": "정재",
//           "ggj": [
//             "비견",
//             "정인",
//             "편재"
//           ]
//         },
//         "오행갯수": {
//           "목": 1,
//           "화": 1,
//           "토": 3,
//           "금": 3,
//           "수": 2
//         },
//         "일운해석": {
//           "level": 3,
//           "word": "맑음",
//           "remark": "맑고 쾌청한 하늘처럼 안정적이고 조화로운 상태입니다.",
//           "emoji": "🌤️"
//         }
//       },
//       "20250124": {
//         "일운갑자": "계사",
//         "일운갑자한자": "癸巳",
//         "갑자의미": {
//           "상징": "검은 뱀의 날",
//           "의미": "신중함과 지혜"
//         },
//         "일운점수": 2.56,
//         "일운지장간": {
//           "cc": "계",
//           "gg": "사",
//           "ggj": [
//             "무",
//             "경",
//             "병"
//           ]
//         },
//         "십성지장간": {
//           "cc": "정인",
//           "gg": "식신",
//           "ggj": [
//             "편재",
//             "편관",
//             "식신"
//           ]
//         },
//         "오행갯수": {
//           "목": 1,
//           "화": 2,
//           "토": 2,
//           "금": 3,
//           "수": 2
//         },
//         "일운해석": {
//           "level": 2,
//           "word": "흐림",
//           "remark": "흐린 날씨처럼 약간의 변화를 준비하며 신중하게 상황을 살피는 단계입니다.",
//           "emoji": "🌥️"
//         }
//       },
//       "20250125": {
//         "일운갑자": "갑오",
//         "일운갑자한자": "甲午",
//         "갑자의미": {
//           "상징": "푸른 말의 날",
//           "의미": "진취적 열정"
//         },
//         "일운점수": 2.41,
//         "일운지장간": {
//           "cc": "갑",
//           "gg": "오",
//           "ggj": [
//             "병",
//             "기",
//             "정"
//           ]
//         },
//         "십성지장간": {
//           "cc": "비견",
//           "gg": "상관",
//           "ggj": [
//             "식신",
//             "편재",
//             "식신"
//           ]
//         },
//         "오행갯수": {
//           "목": 2,
//           "화": 2,
//           "토": 2,
//           "금": 3,
//           "수": 1
//         },
//         "일운해석": {
//           "level": 2,
//           "word": "흐림",
//           "remark": "흐린 날씨처럼 약간의 변화를 준비하며 신중하게 상황을 살피는 단계입니다.",
//           "emoji": "🌥️"
//         }
//       },
//       "20250126": {
//         "일운갑자": "을미",
//         "일운갑자한자": "乙未",
//         "갑자의미": {
//           "상징": "푸른 양의 날",
//           "의미": "조화와 성장"
//         },
//         "일운점수": 2.89,
//         "일운지장간": {
//           "cc": "을",
//           "gg": "미",
//           "ggj": [
//             "정",
//             "을",
//             "기"
//           ]
//         },
//         "십성지장간": {
//           "cc": "비견",
//           "gg": "편재",
//           "ggj": [
//             "식신",
//             "비견",
//             "편재"
//           ]
//         },
//         "오행갯수": {
//           "목": 2,
//           "화": 1,
//           "토": 3,
//           "금": 3,
//           "수": 1
//         },
//         "일운해석": {
//           "level": 2,
//           "word": "흐림",
//           "remark": "흐린 날씨처럼 약간의 변화를 준비하며 신중하게 상황을 살피는 단계입니다.",
//           "emoji": "🌥️"
//         }
//       },
//       "20250127": {
//         "일운갑자": "병신",
//         "일운갑자한자": "丙申",
//         "갑자의미": {
//           "상징": "붉은 원숭이의 날",
//           "의미": "창의와 열정"
//         },
//         "일운점수": 3.47,
//         "일운지장간": {
//           "cc": "병",
//           "gg": "신",
//           "ggj": [
//             "무",
//             "임",
//             "경"
//           ]
//         },
//         "십성지장간": {
//           "cc": "식신",
//           "gg": "정관",
//           "ggj": [
//             "편재",
//             "정인",
//             "편관"
//           ]
//         },
//         "오행갯수": {
//           "목": 1,
//           "화": 2,
//           "토": 2,
//           "금": 4,
//           "수": 1
//         },
//         "일운해석": {
//           "level": 3,
//           "word": "맑음",
//           "remark": "맑고 쾌청한 하늘처럼 안정적이고 조화로운 상태입니다.",
//           "emoji": "🌤️"
//         }
//       },
//       "20250128": {
//         "일운갑자": "정유",
//         "일운갑자한자": "丁酉",
//         "갑자의미": {
//           "상징": "붉은 닭의 날",
//           "의미": "성실과 헌신"
//         },
//         "일운점수": 3.47,
//         "일운지장간": {
//           "cc": "정",
//           "gg": "유",
//           "ggj": [
//             "경",
//             "신"
//           ]
//         },
//         "십성지장간": {
//           "cc": "식신",
//           "gg": "편관",
//           "ggj": [
//             "편관",
//             "정관"
//           ]
//         },
//         "오행갯수": {
//           "목": 1,
//           "화": 2,
//           "토": 2,
//           "금": 4,
//           "수": 1
//         },
//         "일운해석": {
//           "level": 3,
//           "word": "맑음",
//           "remark": "맑고 쾌청한 하늘처럼 안정적이고 조화로운 상태입니다.",
//           "emoji": "🌤️"
//         }
//       },
//       "20250129": {
//         "일운갑자": "무술",
//         "일운갑자한자": "戊戌",
//         "갑자의미": {
//           "상징": "황금 개의 날",
//           "의미": "충성심과 안정"
//         },
//         "일운점수": 3.04,
//         "일운지장간": {
//           "cc": "무",
//           "gg": "술",
//           "ggj": [
//             "신",
//             "정",
//             "무"
//           ]
//         },
//         "십성지장간": {
//           "cc": "편재",
//           "gg": "정재",
//           "ggj": [
//             "정관",
//             "식신",
//             "편재"
//           ]
//         },
//         "오행갯수": {
//           "목": 1,
//           "화": 1,
//           "토": 4,
//           "금": 3,
//           "수": 1
//         },
//         "일운해석": {
//           "level": 3,
//           "word": "맑음",
//           "remark": "맑고 쾌청한 하늘처럼 안정적이고 조화로운 상태입니다.",
//           "emoji": "🌤️"
//         }
//       },
//       "20250130": {
//         "일운갑자": "기해",
//         "일운갑자한자": "己亥",
//         "갑자의미": {
//           "상징": "황금 돼지의 날",
//           "의미": "풍요와 성실"
//         },
//         "일운점수": 3.54,
//         "일운지장간": {
//           "cc": "기",
//           "gg": "해",
//           "ggj": [
//             "무",
//             "갑",
//             "임"
//           ]
//         },
//         "십성지장간": {
//           "cc": "편재",
//           "gg": "정인",
//           "ggj": [
//             "편재",
//             "비견",
//             "정인"
//           ]
//         },
//         "오행갯수": {
//           "목": 1,
//           "화": 1,
//           "토": 3,
//           "금": 3,
//           "수": 2
//         },
//         "일운해석": {
//           "level": 3,
//           "word": "맑음",
//           "remark": "맑고 쾌청한 하늘처럼 안정적이고 조화로운 상태입니다.",
//           "emoji": "🌤️"
//         }
//       },
//       "20250131": {
//         "일운갑자": "경자",
//         "일운갑자한자": "庚子",
//         "갑자의미": {
//           "상징": "하얀 쥐의 날",
//           "의미": "지혜와 추진력"
//         },
//         "일운점수": 3.54,
//         "일운지장간": {
//           "cc": "경",
//           "gg": "자",
//           "ggj": [
//             "임",
//             "계"
//           ]
//         },
//         "십성지장간": {
//           "cc": "편관",
//           "gg": "편인",
//           "ggj": [
//             "정인",
//             "정인"
//           ]
//         },
//         "오행갯수": {
//           "목": 1,
//           "화": 1,
//           "토": 2,
//           "금": 4,
//           "수": 2
//         },
//         "일운해석": {
//           "level": 3,
//           "word": "맑음",
//           "remark": "맑고 쾌청한 하늘처럼 안정적이고 조화로운 상태입니다.",
//           "emoji": "🌤️"
//         }
//       }
//     }
//   }
// }