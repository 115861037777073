<script setup>
import IconOrder from '@/components/svg/IconOrder.vue'
import IconFold from '@/components/svg/IconFold.vue'
import IconFoldDown from '@/components/svg/IconFoldDown.vue'

import { defineProps } from 'vue'

import { Personality01 } from '@/components/analysis'

const props = defineProps({
  selectedDescNav: {
    type: String,
  },
})

const toggleBirthClass = ref('open')
const toggleBirthClassTxt = ref('접기')
const toggleBirth = () => {
  if (toggleBirthClass.value === '') {
    toggleBirthClass.value = 'open'
    toggleBirthClassTxt.value = '접기'
  } else {
    toggleBirthClass.value = ''
    toggleBirthClassTxt.value = '보기'
  }
}
</script>
<template>
  <div class="eight-box" :class="toggleBirthClass">
    <div class="name-box">
      <div class="txt1-box">
        <span class="name">손흥민님의 사주 원국</span>
        <button class="btn-person-order"><IconOrder /> <span>순서</span></button>
      </div>
      <div class="txt2-box">
        <button class="btn-person-fold" @click="toggleBirth()">
          {{ toggleBirthClassTxt }}
          <IconFold v-if="toggleBirthClass === 'open'" />
          <IconFoldDown v-if="toggleBirthClass === ''" />
        </button>
      </div>
    </div>
    <div class="eight-birth-box">
      <div class="sky-box">
        <div class="s-tit">천간</div>
        <div class="text-box txt-big">
          <div class="s-box">
            <div class="ss-tit">시간</div>
            <span class="txt-round xl five01 chnkor CHNKOR"
              ><em class="chn">癸</em><em class="txt-main">계</em></span
            >
          </div>
          <div class="s-box">
            <div class="ss-tit">일간</div>
            <span class="txt-round xl five02 chnkor CHNKOR"
              ><em class="chn">癸</em><em class="txt-main">계</em></span
            >
          </div>
          <div class="s-box">
            <div class="ss-tit">월간</div>
            <span class="txt-round xl five03 chnkor CHNKOR"
              ><em class="chn">癸</em><em class="txt-main">계</em></span
            >
          </div>
          <div class="s-box">
            <div class="ss-tit">년간</div>
            <span class="txt-round xl five04 chnkor CHNKOR"
              ><em class="chn">癸</em><em class="txt-main">계</em></span
            >
          </div>
        </div>
      </div>

      <div class="earth-box">
        <div class="s-tit">지지</div>
        <div class="text-box txt-big">
          <div class="s-box">
            <div class="ss-tit">시간</div>
            <span class="txt-round xl five01 chnkor CHNKOR"
              ><em class="chn">癸</em><em class="txt-main">계</em></span
            >
          </div>
          <div class="s-box">
            <div class="ss-tit">일간</div>
            <span class="txt-round xl five02 chnkor CHNKOR"
              ><em class="chn">癸</em><em class="txt-main">계</em></span
            >
          </div>
          <div class="s-box">
            <div class="ss-tit">월간</div>
            <span class="txt-round xl five03 chnkor CHNKOR"
              ><em class="chn">癸</em><em class="txt-main">계</em></span
            >
          </div>
          <div class="s-box">
            <div class="ss-tit">년간</div>
            <span class="txt-round xl five04 chnkor CHNKOR"
              ><em class="chn">癸</em><em class="txt-main">계</em></span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="gap-large"></div>
  <div class="five-grape-box">
    <div class="round-tit-box"><span class="no">1</span><span>오행의 분포</span></div>
    <VRow>
      <Personality01 :mode="'daily'" />
    </VRow>
  </div>
  <div class="gap-large"></div>
  <div class="">
    <div class="round-tit-box"><span class="no">2</span><span>성향분석의 결과</span></div>
    <div class="person-tab-0102-box">
      <div class="tit-box">
        <div class="txt fnt-color-E">E</div>
        <div class="txt fnt-color-S">S</div>
        <div class="txt fnt-color-F">F</div>
        <div class="txt fnt-color-P">P</div>
      </div>
      <div class="keyword st-blue">
        <span>사교적</span>
        <span>긍정적</span>
        <span>감정 표현</span>
      </div>
    </div>
    <div class="person-tab-0102-02-box">
      <div class="txt rs-box">
        <span class="txt1 fnt-color-E">E</span>
        <span class="txt2">외향적 성향</span>
        <span class="txt3">64.6%</span>
      </div>
      <div class="rs-box">
        <span class="txt1 fnt-color-S">S</span>
        <span class="txt2">감각적 성향</span>
        <span class="txt3">65.4%</span>
      </div>
      <div class="rs-box">
        <span class="txt1 fnt-color-F">F</span>
        <span class="txt2">감정적 성향</span>
        <span class="txt3">73.0%</span>
      </div>
      <div class="rs-box">
        <span class="txt1 fnt-color-P">P</span>
        <span class="txt2">판단적 성향</span>
        <span class="txt3">64.8%</span>
      </div>
    </div>
  </div>
  <div class="gap-large"></div>
  <div class="">
    <div class="round-tit-box"><span class="no">3</span><span>오행으로 본 ESTP의 강점</span></div>
    <div class="person-tab-0103">
      <div class="col-box bg-color-E">
        <div class="tit-circle E">E</div>
        <div class="txt1">관계방식</div>
        <ul class="txt2">
          <li class="fnt-color-E">책임감</li>
          <li class="fnt-color-E">사회적 안정</li>
          <li class="fnt-color-E">조직 적응력</li>
        </ul>
      </div>
      <div class="col-box bg-color-S">
        <div class="tit-circle S">S</div>
        <div class="txt1">정보수집</div>
        <ul class="txt2">
          <li class="fnt-color-S">단순함</li>
          <li class="fnt-color-S">성장</li>
          <li class="fnt-color-S">호기심</li>
        </ul>
      </div>
      <div class="col-box bg-color-F">
        <div class="tit-circle F">F</div>
        <div class="txt1">판단기준</div>
        <ul class="txt2">
          <li class="fnt-color-F">생존력</li>
          <li class="fnt-color-F">회복력</li>
          <li class="fnt-color-F">위기극복</li>
        </ul>
      </div>
      <div class="col-box bg-color-P">
        <div class="tit-circle P">P</div>
        <div class="txt1">발전방향</div>
        <ul class="txt2">
          <li class="fnt-color-P">생존력</li>
          <li class="fnt-color-P">회복력</li>
          <li class="fnt-color-P">위기극복</li>
        </ul>
      </div>
    </div>
  </div>
</template>
