<script setup>
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import ArticleApi from '@/api/ArticleApi'
import BoardApi from '@/api/BoardApi'
import { BoardTitleBox, Pagination, PopSearchNoneBox, SearchBox } from '@/components/board'
import { addContentLogAPI } from '@/api/logServiceApi'

const props = defineProps({
  board: {
    type: String,
    default: '',
  },
  boardId: {
    type: Number,
    default: 0,
  },
})

const router = useRouter()
const store = useStore()

const memberId = ref(null)
const memberInfo = ref(null)
const paramData = ref({
  birthStart: null,
  startDate: null,
  endDate: null,
  genderOption: 0,
  page: 1,
  searchKeywords: '',
  sortOption: null,
  userGroupId: null,
  fiveEles: null,
  size: 10,
  sort: null,
  userId: null,
  categoryId: null,
  systemId: import.meta.env.VITE_APP_SYSTEMID,
})
const totalPages = ref(0)
const totalElements = ref(0)
const searchKeywords = ref('')
/** 게시물 목록 */
const listData = reactive({})
/** 카테고리 목록 */
const categoryList = ref(null)
/** FAQ 컨텐츠 인덱스 */
const faqCurrentIndex = ref(null)
/** 검색결과 없음 팝업 상태 */
const isPopSearchNoneBox = ref(false)

onMounted(async () => {
  store.dispatch('saveCurrentBoard', props.board);

  const currentBoardPage = store.state.boards.currentBoardPage;
  if (currentBoardPage) {
    paramData.value.page = currentBoardPage    
  }
  if (props.board == 'event') {
    paramData.value.size = 9
  }
  await getCategory(props.boardId)
  await getList()
})

watch(
  () => props.boardId,
  async (newVal, oldVal) => {
    // 게시판 이동했을 때
    if (newVal !== oldVal) {
      clearData()
      await getCategory(newVal)
      await getList()
    }
  },
)

/** paramData 및 listData 초기화 */
const clearData = () => {
  paramData.value = {
    birthStart: null,
    startDate: null,
    endDate: null,
    genderOption: 0,
    page: 1,
    searchKeywords: '',
    sortOption: null,
    userGroupId: null,
    fiveEles: null,
    size: 10,
    sort: null,
    userId: null,
    categoryId: null,
  }
  listData.value = []
  searchKeywords.value = ''
  categoryList.value = null
}

/** 게시판 카테고리 요청 */
const getCategory = async (boardId) => {
  if (props.board !== 'faq') return

  try {
    categoryList.value = await BoardApi().categoryById(boardId)
    if (categoryList.value) paramData.value.categoryId = categoryList.value[0]?.categoryId
  } catch (e) {
    alert(e)
  }
}

/** 게시판의 게시물 목록 요청 */
const getList = async () => {
  if (props.boardId) {
    // 1:1문의 또는 명리상담 작성글 호출 시에만
    if (props.board === 'inquiry' || props.board === 'counseling') {
      memberInfo.value = store.getters;
      memberId.value = memberInfo.value['user/id'];

      if (memberId.value) {
        paramData.value.userId = memberId.value;
      } else {
        if (confirm('로그인 후 이용 가능합니다. 로그인 페이지로 이동하시겠습니까?')) {
          router.push('/auth/login');
        } else {
          router.go(-1);
          return;
        }
      }
    } else {
      // paramData.value.size = -1;
    };

    paramData.value.searchKeywords = searchKeywords.value;

    try {
      const result = await ArticleApi().list(props.boardId, paramData.value);

      if (result) {
        if (searchKeywords.value && result.content.length === 0) {
          setPopSearchNoneBox(true);
          return;
        };
        listData.value = [...result.content];
        totalPages.value = result.totalPages;
        totalElements.value = result.totalElements;
      }
    } catch (error) {
      alert(error);
    };
  }
};

/** 검색어 업데이트 */
const updateSearchKeywords = (newValue) => (searchKeywords.value = newValue);

/** 이벤트 게시물 이미지 추출 */
const extractFirstImageSrc = (htmlContent) => {
  const div = document.createElement('div');
  div.innerHTML = htmlContent;
  const img = div.querySelector('img');
  return img ? img.src : null;
};

const boardMap = {
  "notice": "N_notice_article",
  "event": "N_event_article",
  "faq": "N_faq_article",
};
/** 게시물로 이동 */
const goView = (id) => {  
  if (boardMap[props.board]) addContentLogAPI({
    contentType: boardMap[props.board],
    servicePk: id,
  });
  router.push(`/customer/${props.board}/${id}`);
}

/** 1:1문의 또는 명리상담 작성하기 버튼  */
const writeInquiry = () => router.push(`/customer/${props.board}/write`);

/** 자주묻는질문 카테고리 선택 */
const clickCategory = (cate) => {
  faqCurrentIndex.value = null;
  paramData.value.categoryId = cate;
  paramData.value.searchKeywords = '';
  searchKeywords.value = '';
  getList();
};

/** 자주묻는질문 컨텐츠 열기 */
const toggleContent = ({item, index}) => {
  if (faqCurrentIndex.value === index) faqCurrentIndex.value = null;
  else {
    addContentLogAPI({
      contentType: boardMap['faq'],
      servicePk: item.articleId,
    })
    faqCurrentIndex.value = index;
  }
};

/** 검색결과 없음 팝업 상태관리 */
const setPopSearchNoneBox = (state) => (isPopSearchNoneBox.value = state);

/** 페이지 변경 */
const changePage = (page) => {
  paramData.value.page = page
  getList()
  store.dispatch('saveCurrentBoardPage', page);
}

const divStyle = (item) => {
  if (item.attachFiles && item.attachFiles.length) return null
  return {
    width: '100%',
    height: '100%',
    borderRadius: '8px',
    // background: 'rgba(0, 0, 0, 0.05)',
  };
};
</script>

<template>
  <div class="">
    <BoardTitleBox :board="board">
      <template v-slot:add-on>
        <SearchBox
          :placeholder="placeholder"
          :search-keywords="searchKeywords"
          @update:searchKeywords="updateSearchKeywords"
          @search="getList"
        />
      </template>
    </BoardTitleBox>

    <div v-if="board === 'inquiry' || board === 'counseling'" class="board-inquiry-box">
      <div class="tit-box">
        <div class="txt1">운영 시간 안내</div>
        <div class="txt2">답변시간 평일 13:00 - 18:00 (주말, 공휴일 휴무)</div>
        <div class="txt3">답변 시간 이후 접수 건은 운영시간 내 순차적으로 답변해 드립니다.</div>
      </div>
      <button class="btn-do" @click="writeInquiry()">
        {{ board === 'inquiry' ? '1:1 문의하기' : '명리상담' }}
      </button>
    </div>

    <div v-if="board === 'faq'" class="category-list-box">
      <button
        v-if="categoryList"
        v-for="category in categoryList"
        :key="category.categoryId"
        :class="{ active: paramData.categoryId === category.categoryId }"
        class="btn-category"
        @click="clickCategory(category.categoryId)"
      >
        {{ category.categoryCtn }}
      </button>
    </div>

    <div
      v-if="board !== 'faq' && board !== 'inquiry' && board !== 'event' && board !== 'counseling'"
      class="board-list-box"
    >
      <table>
        <colgroup>
          <col width="*" />
          <col width="150px" />
        </colgroup>
        <thead>
          <tr>
            <th>제목</th>
            <th>등록일</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in listData.value" :key="item.id">
            <td class="al-tit" @click="goView(item.articleId)">
              <span class="tit">{{ item.articleTtl }}</span>
            </td>
            <td class="al-center">
              <span class="date">{{ formatCustomDate(item.createdAt) }}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div v-if="board === 'event'" class="grid-container">
      <div
        v-for="(item, index) in listData.value"
        :key="index"
        class="item"
        @click="goView(item.articleId)"
      >
        <div class="img-box-lg" >
          <img
            v-if="item.articleCtn && extractFirstImageSrc(item.articleCtn)"
            :src="extractFirstImageSrc(item.articleCtn)"
            style="max-height: none;"
          />
          <div v-else :style="divStyle(item)"></div>
        </div>
        
        <div class="txt-tit">
          {{ item.articleTtl }}
        </div>
        <div class="txt-date">
          {{ formatCustomDate(item.createdAt) }}
        </div>
      </div>
    </div>

    <div v-if="board === 'inquiry' || board === 'counseling'" class="board-list-box">
      <table>
        <colgroup>
          <col width="120px" />
          <col width="*" />
          <col width="120px" />
          <col width="120px" />
          <col width="120px" />
        </colgroup>
        <thead>
          <tr>
            <th>카테고리</th>
            <th>제목</th>
            <th>작성자</th>
            <th>등록일</th>
            <th>답변상태</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in listData.value" :key="item.id">
            <td class="al-center">
              {{ item.boardCategory?.categoryCtn }}
            </td>
            <td class="al-tit" @click="goView(item.articleId)">
              {{ item.articleTtl }}
            </td>
            <td class="al-center">
              <span class="st-name">{{ item.userNickName }}</span>
            </td>
            <td class="al-center st-date">
              <span class="st-date">{{ formatCustomDate(item.createdAt) }}</span>
            </td>
            <td class="al-center st-state" :class="{ finish: item.comments?.length > 0 }">
              <span class="st-state">{{
                item.comments?.length > 0 ? '답변완료' : '답변대기'
              }}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div v-if="board === 'faq'" class="faq-list-wrap">
      <div v-for="(item, index) in listData.value" :key="item.id" class="faq-list-row">
        <div class="tit" :class="{ open: faqCurrentIndex === index }" @click="toggleContent({item, index})">
          {{ item.articleTtl }}
        </div>
        <div class="cont" :class="{ open: faqCurrentIndex === index }">
          <div v-html="item.articleCtn" />
        </div>
      </div>
    </div>

    <Pagination
      v-if="totalElements > 0 && listData.value.length > 0 && board !== 'faq'"
      :total-pages="totalPages"
      :current-page="paramData.page"
      @page-changed="changePage"
    />

    <PopSearchNoneBox v-if="isPopSearchNoneBox" @close="setPopSearchNoneBox(false)" />
  </div>
</template>
