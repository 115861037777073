import _definePage_default_0 from '/var/www/d25_f_web_svcs/src/pages/analysis/index.vue?definePage&vue'
import { _mergeRouteRecord } from 'unplugin-vue-router/runtime'
import _definePage_default_2 from '/var/www/d25_f_web_svcs/src/pages/auth/callback.vue?definePage&vue'
import _definePage_default_3 from '/var/www/d25_f_web_svcs/src/pages/auth/find-id.vue?definePage&vue'
import _definePage_default_4 from '/var/www/d25_f_web_svcs/src/pages/auth/finish.vue?definePage&vue'
import _definePage_default_5 from '/var/www/d25_f_web_svcs/src/pages/auth/join.vue?definePage&vue'
import _definePage_default_6 from '/var/www/d25_f_web_svcs/src/pages/auth/login.vue?definePage&vue'
import _definePage_default_7 from '/var/www/d25_f_web_svcs/src/pages/auth/reset.vue?definePage&vue'
import _definePage_default_8 from '/var/www/d25_f_web_svcs/src/pages/blank/index.vue?definePage&vue'
import _definePage_default_9 from '/var/www/d25_f_web_svcs/src/pages/calendar/index.vue?definePage&vue'
import _definePage_default_10 from '/var/www/d25_f_web_svcs/src/pages/calendar/dev.vue?definePage&vue'
import _definePage_default_11 from '/var/www/d25_f_web_svcs/src/pages/customer/index.vue?definePage&vue'
import _definePage_default_12 from '/var/www/d25_f_web_svcs/src/pages/documents/goToAppStore.vue?definePage&vue'
import _definePage_default_13 from '/var/www/d25_f_web_svcs/src/pages/documents/Privacy.vue?definePage&vue'
import _definePage_default_14 from '/var/www/d25_f_web_svcs/src/pages/documents/ServiceIntro.vue?definePage&vue'
import _definePage_default_15 from '/var/www/d25_f_web_svcs/src/pages/documents/Usage.vue?definePage&vue'
import _definePage_default_16 from '/var/www/d25_f_web_svcs/src/pages/error/error01.vue?definePage&vue'
import _definePage_default_17 from '/var/www/d25_f_web_svcs/src/pages/guide/components/GuideProductDetail.vue?definePage&vue'
import _definePage_default_18 from '/var/www/d25_f_web_svcs/src/pages/guide/components/GuideProductList.vue?definePage&vue'
import _definePage_default_19 from '/var/www/d25_f_web_svcs/src/pages/guide/info.vue?definePage&vue'
import _definePage_default_20 from '/var/www/d25_f_web_svcs/src/pages/guide/month.vue?definePage&vue'
import _definePage_default_21 from '/var/www/d25_f_web_svcs/src/pages/guide/product.vue?definePage&vue'
import _definePage_default_22 from '/var/www/d25_f_web_svcs/src/pages/guide/productDetail.vue?definePage&vue'
import _definePage_default_23 from '/var/www/d25_f_web_svcs/src/pages/guide/subsrc.vue?definePage&vue'
import _definePage_default_24 from '/var/www/d25_f_web_svcs/src/pages/Inspire.vue?definePage&vue'
import _definePage_default_25 from '/var/www/d25_f_web_svcs/src/pages/main/index.vue?definePage&vue'
import _definePage_default_26 from '/var/www/d25_f_web_svcs/src/pages/manse/index.vue?definePage&vue'
import _definePage_default_27 from '/var/www/d25_f_web_svcs/src/pages/manse/index_back_241217.vue?definePage&vue'
import _definePage_default_28 from '/var/www/d25_f_web_svcs/src/pages/manse/print.vue?definePage&vue'
import _definePage_default_29 from '/var/www/d25_f_web_svcs/src/pages/manse/printpro.vue?definePage&vue'
import _definePage_default_30 from '/var/www/d25_f_web_svcs/src/pages/manse_app/index.vue?definePage&vue'
import _definePage_default_31 from '/var/www/d25_f_web_svcs/src/pages/manse_app_five/index.vue?definePage&vue'
import _definePage_default_32 from '/var/www/d25_f_web_svcs/src/pages/mypage/index.vue?definePage&vue'
import _definePage_default_33 from '/var/www/d25_f_web_svcs/src/pages/mypage/components/subscrGuide.vue?definePage&vue'
import _definePage_default_34 from '/var/www/d25_f_web_svcs/src/pages/mypage/components/subscrList.vue?definePage&vue'
import _definePage_default_35 from '/var/www/d25_f_web_svcs/src/pages/mypage/faq.vue?definePage&vue'
import _definePage_default_36 from '/var/www/d25_f_web_svcs/src/pages/mypage/inquiry.vue?definePage&vue'
import _definePage_default_37 from '/var/www/d25_f_web_svcs/src/pages/mypage/inquiryGuest.vue?definePage&vue'
import _definePage_default_38 from '/var/www/d25_f_web_svcs/src/pages/mypage/inquiryList.vue?definePage&vue'
import _definePage_default_39 from '/var/www/d25_f_web_svcs/src/pages/mypage/inquiryView.vue?definePage&vue'
import _definePage_default_40 from '/var/www/d25_f_web_svcs/src/pages/mypage/moreinfo.vue?definePage&vue'
import _definePage_default_41 from '/var/www/d25_f_web_svcs/src/pages/mypage/myinfo.vue?definePage&vue'
import _definePage_default_42 from '/var/www/d25_f_web_svcs/src/pages/mypage/profile.vue?definePage&vue'
import _definePage_default_43 from '/var/www/d25_f_web_svcs/src/pages/mypage/snsPw.vue?definePage&vue'
import _definePage_default_44 from '/var/www/d25_f_web_svcs/src/pages/mypage/subscr.vue?definePage&vue'
import _definePage_default_45 from '/var/www/d25_f_web_svcs/src/pages/mypage/subscrFinish.vue?definePage&vue'
import _definePage_default_46 from '/var/www/d25_f_web_svcs/src/pages/mypage/subscrFinishBill.vue?definePage&vue'
import _definePage_default_47 from '/var/www/d25_f_web_svcs/src/pages/mypage/subscrFinishBillEnd.vue?definePage&vue'
import _definePage_default_48 from '/var/www/d25_f_web_svcs/src/pages/mypage/subscrFinishBillFail.vue?definePage&vue'
import _definePage_default_49 from '/var/www/d25_f_web_svcs/src/pages/mypage/subscrFinishFail.vue?definePage&vue'
import _definePage_default_50 from '/var/www/d25_f_web_svcs/src/pages/mypage/subscrFinishMonth.vue?definePage&vue'
import _definePage_default_51 from '/var/www/d25_f_web_svcs/src/pages/mypage/subscrFinishMonthEnd.vue?definePage&vue'
import _definePage_default_52 from '/var/www/d25_f_web_svcs/src/pages/mypage/subscrFinishMonthFail.vue?definePage&vue'
import _definePage_default_53 from '/var/www/d25_f_web_svcs/src/pages/mypage/subscrFinishPoint.vue?definePage&vue'
import _definePage_default_54 from '/var/www/d25_f_web_svcs/src/pages/mypage/subscrFinishPointEnd.vue?definePage&vue'
import _definePage_default_55 from '/var/www/d25_f_web_svcs/src/pages/mypage/subscrFinishPointFail.vue?definePage&vue'
import _definePage_default_56 from '/var/www/d25_f_web_svcs/src/pages/mypage/subscrGuest.vue?definePage&vue'
import _definePage_default_57 from '/var/www/d25_f_web_svcs/src/pages/mypage/subscrPayment.vue?definePage&vue'
import _definePage_default_58 from '/var/www/d25_f_web_svcs/src/pages/mypage/subscrPaymentView.vue?definePage&vue'
import _definePage_default_59 from '/var/www/d25_f_web_svcs/src/pages/mypage/subscrPoint.vue?definePage&vue'
import _definePage_default_60 from '/var/www/d25_f_web_svcs/src/pages/mypage/subscrRepo.vue?definePage&vue'
import _definePage_default_61 from '/var/www/d25_f_web_svcs/src/pages/mypage/subscrRepoMonth.vue?definePage&vue'
import _definePage_default_62 from '/var/www/d25_f_web_svcs/src/pages/saju/index.vue?definePage&vue'
import _definePage_default_63 from '/var/www/d25_f_web_svcs/src/pages/schedule_app/index.vue?definePage&vue'
import _definePage_default_64 from '/var/www/d25_f_web_svcs/src/pages/schedule_app/dev.vue?definePage&vue'
import _definePage_default_65 from '/var/www/d25_f_web_svcs/src/pages/withdrawal/withdrawComplete.vue?definePage&vue'

export const routes = [
  {
    path: '/analysis',
    /* internal name: 'analysis' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: '',
        name: 'analysis',
        component: () => import('/var/www/d25_f_web_svcs/src/pages/analysis/index.vue'),
        /* no children */
      },
  _definePage_default_0
  ),
      {
        path: 'components',
        /* internal name: 'analysis-components' */
        /* no component */
        children: [
          {
            path: 'ContentsList',
            name: 'analysis-components-contents-list',
            component: () => import('/var/www/d25_f_web_svcs/src/pages/analysis/components/ContentsList.vue'),
            /* no children */
          },
          {
            path: 'fiveTab',
            name: 'analysis-components-five-tab',
            component: () => import('/var/www/d25_f_web_svcs/src/pages/analysis/components/fiveTab.vue'),
            /* no children */
          },
          {
            path: 'fiveTabBasic',
            name: 'analysis-components-five-tab-basic',
            component: () => import('/var/www/d25_f_web_svcs/src/pages/analysis/components/fiveTabBasic.vue'),
            /* no children */
          },
          {
            path: 'fiveTabDetail',
            name: 'analysis-components-five-tab-detail',
            component: () => import('/var/www/d25_f_web_svcs/src/pages/analysis/components/fiveTabDetail.vue'),
            /* no children */
          },
          {
            path: 'fiveTabImage',
            name: 'analysis-components-five-tab-image',
            component: () => import('/var/www/d25_f_web_svcs/src/pages/analysis/components/fiveTabImage.vue'),
            /* no children */
          },
          {
            path: 'personalitySub01',
            name: 'analysis-components-personality-sub01',
            component: () => import('/var/www/d25_f_web_svcs/src/pages/analysis/components/personalitySub01.vue'),
            /* no children */
          },
          {
            path: 'personalitySub02',
            name: 'analysis-components-personality-sub02',
            component: () => import('/var/www/d25_f_web_svcs/src/pages/analysis/components/personalitySub02.vue'),
            /* no children */
          },
          {
            path: 'personalitySub03',
            name: 'analysis-components-personality-sub03',
            component: () => import('/var/www/d25_f_web_svcs/src/pages/analysis/components/personalitySub03.vue'),
            /* no children */
          },
          {
            path: 'personalitySub04',
            name: 'analysis-components-personality-sub04',
            component: () => import('/var/www/d25_f_web_svcs/src/pages/analysis/components/personalitySub04.vue'),
            /* no children */
          },
          {
            path: 'personalitySub05',
            name: 'analysis-components-personality-sub05',
            component: () => import('/var/www/d25_f_web_svcs/src/pages/analysis/components/personalitySub05.vue'),
            /* no children */
          },
          {
            path: 'personalityTab',
            name: 'analysis-components-personality-tab',
            component: () => import('/var/www/d25_f_web_svcs/src/pages/analysis/components/personalityTab.vue'),
            /* no children */
          },
          {
            path: 'personalityTabBasic',
            name: 'analysis-components-personality-tab-basic',
            component: () => import('/var/www/d25_f_web_svcs/src/pages/analysis/components/personalityTabBasic.vue'),
            /* no children */
          },
          {
            path: 'personalityTabDetail',
            name: 'analysis-components-personality-tab-detail',
            component: () => import('/var/www/d25_f_web_svcs/src/pages/analysis/components/personalityTabDetail.vue'),
            /* no children */
          },
          {
            path: 'personalityTabImage',
            name: 'analysis-components-personality-tab-image',
            component: () => import('/var/www/d25_f_web_svcs/src/pages/analysis/components/personalityTabImage.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'dashboard',
        name: 'analysis-dashboard',
        component: () => import('/var/www/d25_f_web_svcs/src/pages/analysis/dashboard.vue'),
        /* no children */
      },
      {
        path: 'five',
        name: 'analysis-five',
        component: () => import('/var/www/d25_f_web_svcs/src/pages/analysis/five.vue'),
        /* no children */
      },
      {
        path: 'personality',
        name: 'analysis-personality',
        component: () => import('/var/www/d25_f_web_svcs/src/pages/analysis/personality.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/auth',
    /* internal name: 'auth' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: 'callback',
        name: 'auth-callback',
        component: () => import('/var/www/d25_f_web_svcs/src/pages/auth/callback.vue'),
        /* no children */
      },
  _definePage_default_2
  ),
  _mergeRouteRecord(
      {
        path: 'find-id',
        name: 'auth-find-id',
        component: () => import('/var/www/d25_f_web_svcs/src/pages/auth/find-id.vue'),
        /* no children */
      },
  _definePage_default_3
  ),
  _mergeRouteRecord(
      {
        path: 'finish',
        name: 'auth-finish',
        component: () => import('/var/www/d25_f_web_svcs/src/pages/auth/finish.vue'),
        /* no children */
      },
  _definePage_default_4
  ),
  _mergeRouteRecord(
      {
        path: 'join',
        name: 'auth-join',
        component: () => import('/var/www/d25_f_web_svcs/src/pages/auth/join.vue'),
        /* no children */
      },
  _definePage_default_5
  ),
  _mergeRouteRecord(
      {
        path: 'login',
        name: 'auth-login',
        component: () => import('/var/www/d25_f_web_svcs/src/pages/auth/login.vue'),
        /* no children */
      },
  _definePage_default_6
  ),
  _mergeRouteRecord(
      {
        path: 'reset',
        name: 'auth-reset',
        component: () => import('/var/www/d25_f_web_svcs/src/pages/auth/reset.vue'),
        /* no children */
      },
  _definePage_default_7
  )
    ],
  },
  {
    path: '/blank',
    /* internal name: 'blank' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: '',
        name: 'blank',
        component: () => import('/var/www/d25_f_web_svcs/src/pages/blank/index.vue'),
        /* no children */
      },
  _definePage_default_8
  )
    ],
  },
  {
    path: '/calendar',
    /* internal name: 'calendar' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: '',
        name: 'calendar',
        component: () => import('/var/www/d25_f_web_svcs/src/pages/calendar/index.vue'),
        /* no children */
      },
  _definePage_default_9
  ),
  _mergeRouteRecord(
      {
        path: 'dev',
        name: 'calendar-dev',
        component: () => import('/var/www/d25_f_web_svcs/src/pages/calendar/dev.vue'),
        /* no children */
      },
  _definePage_default_10
  )
    ],
  },
  {
    path: '/customer',
    /* internal name: 'customer' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: '',
        name: 'customer',
        component: () => import('/var/www/d25_f_web_svcs/src/pages/customer/index.vue'),
        /* no children */
      },
  _definePage_default_11
  )
    ],
  },
  {
    path: '/documents',
    /* internal name: 'documents' */
    /* no component */
    children: [
      {
        path: 'components',
        /* internal name: 'documents-components' */
        /* no component */
        children: [
          {
            path: 'PopSummerTime',
            name: 'documents-components-pop-summer-time',
            component: () => import('/var/www/d25_f_web_svcs/src/pages/documents/components/PopSummerTime.vue'),
            /* no children */
          },
          {
            path: 'SajuPalza',
            name: 'documents-components-saju-palza',
            component: () => import('/var/www/d25_f_web_svcs/src/pages/documents/components/SajuPalza.vue'),
            /* no children */
          },
          {
            path: 'Services',
            name: 'documents-components-services',
            component: () => import('/var/www/d25_f_web_svcs/src/pages/documents/components/Services.vue'),
            /* no children */
          }
        ],
      },
  _mergeRouteRecord(
      {
        path: 'goToAppStore',
        name: 'documents-go-to-app-store',
        component: () => import('/var/www/d25_f_web_svcs/src/pages/documents/goToAppStore.vue'),
        /* no children */
      },
  _definePage_default_12
  ),
  _mergeRouteRecord(
      {
        path: 'Privacy',
        name: 'documents-privacy',
        component: () => import('/var/www/d25_f_web_svcs/src/pages/documents/Privacy.vue'),
        /* no children */
      },
  _definePage_default_13
  ),
  _mergeRouteRecord(
      {
        path: 'ServiceIntro',
        name: 'documents-service-intro',
        component: () => import('/var/www/d25_f_web_svcs/src/pages/documents/ServiceIntro.vue'),
        /* no children */
      },
  _definePage_default_14
  ),
  _mergeRouteRecord(
      {
        path: 'Usage',
        name: 'documents-usage',
        component: () => import('/var/www/d25_f_web_svcs/src/pages/documents/Usage.vue'),
        /* no children */
      },
  _definePage_default_15
  )
    ],
  },
  {
    path: '/error',
    /* internal name: 'error' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: 'error01',
        name: 'error-error01',
        component: () => import('/var/www/d25_f_web_svcs/src/pages/error/error01.vue'),
        /* no children */
      },
  _definePage_default_16
  )
    ],
  },
  {
    path: '/essay',
    /* internal name: 'essay' */
    /* no component */
    children: [
      {
        path: '',
        name: 'essay',
        component: () => import('/var/www/d25_f_web_svcs/src/pages/essay/index.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/guide',
    /* internal name: 'guide' */
    /* no component */
    children: [
      {
        path: 'components',
        /* internal name: 'guide-components' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: 'GuideProductDetail',
            name: 'guide-components-guide-product-detail',
            component: () => import('/var/www/d25_f_web_svcs/src/pages/guide/components/GuideProductDetail.vue'),
            /* no children */
          },
  _definePage_default_17
  ),
  _mergeRouteRecord(
          {
            path: 'GuideProductList',
            name: 'guide-components-guide-product-list',
            component: () => import('/var/www/d25_f_web_svcs/src/pages/guide/components/GuideProductList.vue'),
            /* no children */
          },
  _definePage_default_18
  )
        ],
      },
  _mergeRouteRecord(
      {
        path: 'info',
        name: 'guide-info',
        component: () => import('/var/www/d25_f_web_svcs/src/pages/guide/info.vue'),
        /* no children */
      },
  _definePage_default_19
  ),
  _mergeRouteRecord(
      {
        path: 'month',
        name: 'guide-month',
        component: () => import('/var/www/d25_f_web_svcs/src/pages/guide/month.vue'),
        /* no children */
      },
  _definePage_default_20
  ),
  _mergeRouteRecord(
      {
        path: 'product',
        name: 'guide-product',
        component: () => import('/var/www/d25_f_web_svcs/src/pages/guide/product.vue'),
        /* no children */
      },
  _definePage_default_21
  ),
  _mergeRouteRecord(
      {
        path: 'productDetail',
        name: 'guide-product-detail',
        component: () => import('/var/www/d25_f_web_svcs/src/pages/guide/productDetail.vue'),
        /* no children */
      },
  _definePage_default_22
  ),
  _mergeRouteRecord(
      {
        path: 'subsrc',
        name: 'guide-subsrc',
        component: () => import('/var/www/d25_f_web_svcs/src/pages/guide/subsrc.vue'),
        /* no children */
      },
  _definePage_default_23
  )
    ],
  },
  _mergeRouteRecord(
  {
    path: '/Inspire',
    name: 'inspire',
    component: () => import('/var/www/d25_f_web_svcs/src/pages/Inspire.vue'),
    /* no children */
  },
  _definePage_default_24
  ),
  {
    path: '/main',
    /* internal name: 'main' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: '',
        name: 'main',
        component: () => import('/var/www/d25_f_web_svcs/src/pages/main/index.vue'),
        /* no children */
      },
  _definePage_default_25
  )
    ],
  },
  {
    path: '/manse',
    /* internal name: 'manse' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: '',
        name: 'manse',
        component: () => import('/var/www/d25_f_web_svcs/src/pages/manse/index.vue'),
        /* no children */
      },
  _definePage_default_26
  ),
      {
        path: 'components',
        /* internal name: 'manse-components' */
        /* no component */
        children: [
          {
            path: 'Agree',
            name: 'manse-components-agree',
            component: () => import('/var/www/d25_f_web_svcs/src/pages/manse/components/Agree.vue'),
            /* no children */
          },
          {
            path: 'Alert',
            name: 'manse-components-alert',
            component: () => import('/var/www/d25_f_web_svcs/src/pages/manse/components/Alert.vue'),
            /* no children */
          },
          {
            path: 'ComLatest',
            name: 'manse-components-com-latest',
            component: () => import('/var/www/d25_f_web_svcs/src/pages/manse/components/ComLatest.vue'),
            /* no children */
          },
          {
            path: 'Confirm',
            name: 'manse-components-confirm',
            component: () => import('/var/www/d25_f_web_svcs/src/pages/manse/components/Confirm.vue'),
            /* no children */
          },
          {
            path: 'FiveProBodyComponent',
            name: 'manse-components-five-pro-body-component',
            component: () => import('/var/www/d25_f_web_svcs/src/pages/manse/components/FiveProBodyComponent.vue'),
            /* no children */
          },
          {
            path: 'FiveProTopComponent',
            name: 'manse-components-five-pro-top-component',
            component: () => import('/var/www/d25_f_web_svcs/src/pages/manse/components/FiveProTopComponent.vue'),
            /* no children */
          },
          {
            path: 'FiveStarAppComponent',
            name: 'manse-components-five-star-app-component',
            component: () => import('/var/www/d25_f_web_svcs/src/pages/manse/components/FiveStarAppComponent.vue'),
            /* no children */
          },
          {
            path: 'FiveStartComponent',
            name: 'manse-components-five-start-component',
            component: () => import('/var/www/d25_f_web_svcs/src/pages/manse/components/FiveStartComponent.vue'),
            /* no children */
          },
          {
            path: 'IlganPagingBtn',
            name: 'manse-components-ilgan-paging-btn',
            component: () => import('/var/www/d25_f_web_svcs/src/pages/manse/components/IlganPagingBtn.vue'),
            /* no children */
          },
          {
            path: 'IlganPopContent',
            name: 'manse-components-ilgan-pop-content',
            component: () => import('/var/www/d25_f_web_svcs/src/pages/manse/components/IlganPopContent.vue'),
            /* no children */
          },
          {
            path: 'IlganPopIntro',
            name: 'manse-components-ilgan-pop-intro',
            component: () => import('/var/www/d25_f_web_svcs/src/pages/manse/components/IlganPopIntro.vue'),
            /* no children */
          },
          {
            path: 'IlganPopOutro',
            name: 'manse-components-ilgan-pop-outro',
            component: () => import('/var/www/d25_f_web_svcs/src/pages/manse/components/IlganPopOutro.vue'),
            /* no children */
          },
          {
            path: 'ilganPopup',
            /* internal name: 'manse-components-ilgan-popup' */
            /* no component */
            children: [
              {
                path: 'page1',
                name: 'manse-components-ilgan-popup-page1',
                component: () => import('/var/www/d25_f_web_svcs/src/pages/manse/components/ilganPopup/page1.vue'),
                /* no children */
              },
              {
                path: 'page2',
                name: 'manse-components-ilgan-popup-page2',
                component: () => import('/var/www/d25_f_web_svcs/src/pages/manse/components/ilganPopup/page2.vue'),
                /* no children */
              },
              {
                path: 'page3',
                name: 'manse-components-ilgan-popup-page3',
                component: () => import('/var/www/d25_f_web_svcs/src/pages/manse/components/ilganPopup/page3.vue'),
                /* no children */
              },
              {
                path: 'page4',
                name: 'manse-components-ilgan-popup-page4',
                component: () => import('/var/www/d25_f_web_svcs/src/pages/manse/components/ilganPopup/page4.vue'),
                /* no children */
              },
              {
                path: 'page5',
                name: 'manse-components-ilgan-popup-page5',
                component: () => import('/var/www/d25_f_web_svcs/src/pages/manse/components/ilganPopup/page5.vue'),
                /* no children */
              },
              {
                path: 'page6',
                name: 'manse-components-ilgan-popup-page6',
                component: () => import('/var/www/d25_f_web_svcs/src/pages/manse/components/ilganPopup/page6.vue'),
                /* no children */
              }
            ],
          },
          {
            path: 'ManseBirthMain',
            name: 'manse-components-manse-birth-main',
            component: () => import('/var/www/d25_f_web_svcs/src/pages/manse/components/ManseBirthMain.vue'),
            /* no children */
          },
          {
            path: 'ManseCard',
            name: 'manse-components-manse-card',
            component: () => import('/var/www/d25_f_web_svcs/src/pages/manse/components/ManseCard.vue'),
            /* no children */
          },
          {
            path: 'ManseCardItem',
            name: 'manse-components-manse-card-item',
            component: () => import('/var/www/d25_f_web_svcs/src/pages/manse/components/ManseCardItem.vue'),
            /* no children */
          },
          {
            path: 'ManseCardItemEtc',
            name: 'manse-components-manse-card-item-etc',
            component: () => import('/var/www/d25_f_web_svcs/src/pages/manse/components/ManseCardItemEtc.vue'),
            /* no children */
          },
          {
            path: 'ManseCardItemSal',
            name: 'manse-components-manse-card-item-sal',
            component: () => import('/var/www/d25_f_web_svcs/src/pages/manse/components/ManseCardItemSal.vue'),
            /* no children */
          },
          {
            path: 'ManseCardItemSix',
            name: 'manse-components-manse-card-item-six',
            component: () => import('/var/www/d25_f_web_svcs/src/pages/manse/components/ManseCardItemSix.vue'),
            /* no children */
          },
          {
            path: 'ManseDaeunMain',
            name: 'manse-components-manse-daeun-main',
            component: () => import('/var/www/d25_f_web_svcs/src/pages/manse/components/ManseDaeunMain.vue'),
            /* no children */
          },
          {
            path: 'PopArea',
            name: 'manse-components-pop-area',
            component: () => import('/var/www/d25_f_web_svcs/src/pages/manse/components/PopArea.vue'),
            /* no children */
          },
          {
            path: 'PopCardDetail',
            name: 'manse-components-pop-card-detail',
            component: () => import('/var/www/d25_f_web_svcs/src/pages/manse/components/PopCardDetail.vue'),
            /* no children */
          },
          {
            path: 'PopFiveStar',
            name: 'manse-components-pop-five-star',
            component: () => import('/var/www/d25_f_web_svcs/src/pages/manse/components/PopFiveStar.vue'),
            /* no children */
          },
          {
            path: 'PopGanji',
            name: 'manse-components-pop-ganji',
            component: () => import('/var/www/d25_f_web_svcs/src/pages/manse/components/PopGanji.vue'),
            /* no children */
          },
          {
            path: 'PopIlgan',
            name: 'manse-components-pop-ilgan',
            component: () => import('/var/www/d25_f_web_svcs/src/pages/manse/components/PopIlgan.vue'),
            /* no children */
          },
          {
            path: 'PopMansePrint',
            name: 'manse-components-pop-manse-print',
            component: () => import('/var/www/d25_f_web_svcs/src/pages/manse/components/PopMansePrint.vue'),
            /* no children */
          },
          {
            path: 'PopName',
            name: 'manse-components-pop-name',
            component: () => import('/var/www/d25_f_web_svcs/src/pages/manse/components/PopName.vue'),
            /* no children */
          },
          {
            path: 'PopQrBox',
            name: 'manse-components-pop-qr-box',
            component: () => import('/var/www/d25_f_web_svcs/src/pages/manse/components/PopQrBox.vue'),
            /* no children */
          },
          {
            path: 'PopShareBox',
            name: 'manse-components-pop-share-box',
            component: () => import('/var/www/d25_f_web_svcs/src/pages/manse/components/PopShareBox.vue'),
            /* no children */
          },
          {
            path: 'PrintItemComponent',
            name: 'manse-components-print-item-component',
            component: () => import('/var/www/d25_f_web_svcs/src/pages/manse/components/PrintItemComponent.vue'),
            /* no children */
          },
          {
            path: 'PrintItemComponentFour',
            name: 'manse-components-print-item-component-four',
            component: () => import('/var/www/d25_f_web_svcs/src/pages/manse/components/PrintItemComponentFour.vue'),
            /* no children */
          },
          {
            path: 'Snackbar',
            name: 'manse-components-snackbar',
            component: () => import('/var/www/d25_f_web_svcs/src/pages/manse/components/Snackbar.vue'),
            /* no children */
          }
        ],
      },
  _mergeRouteRecord(
      {
        path: 'index_back_241217',
        name: 'manse-index-back241217',
        component: () => import('/var/www/d25_f_web_svcs/src/pages/manse/index_back_241217.vue'),
        /* no children */
      },
  _definePage_default_27
  ),
  _mergeRouteRecord(
      {
        path: 'print',
        name: 'manse-print',
        component: () => import('/var/www/d25_f_web_svcs/src/pages/manse/print.vue'),
        /* no children */
      },
  _definePage_default_28
  ),
  _mergeRouteRecord(
      {
        path: 'printpro',
        name: 'manse-printpro',
        component: () => import('/var/www/d25_f_web_svcs/src/pages/manse/printpro.vue'),
        /* no children */
      },
  _definePage_default_29
  )
    ],
  },
  {
    path: '/manse_app',
    /* internal name: 'manse-app' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: '',
        name: 'manse-app',
        component: () => import('/var/www/d25_f_web_svcs/src/pages/manse_app/index.vue'),
        /* no children */
      },
  _definePage_default_30
  )
    ],
  },
  {
    path: '/manse_app_five',
    /* internal name: 'manse-app-five' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: '',
        name: 'manse-app-five',
        component: () => import('/var/www/d25_f_web_svcs/src/pages/manse_app_five/index.vue'),
        /* no children */
      },
  _definePage_default_31
  )
    ],
  },
  {
    path: '/mypage',
    /* internal name: 'mypage' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: '',
        name: 'mypage',
        component: () => import('/var/www/d25_f_web_svcs/src/pages/mypage/index.vue'),
        /* no children */
      },
  _definePage_default_32
  ),
      {
        path: 'components',
        /* internal name: 'mypage-components' */
        /* no component */
        children: [
          {
            path: 'Confirm',
            name: 'mypage-components-confirm',
            component: () => import('/var/www/d25_f_web_svcs/src/pages/mypage/components/Confirm.vue'),
            /* no children */
          },
  _mergeRouteRecord(
          {
            path: 'subscrGuide',
            name: 'mypage-components-subscr-guide',
            component: () => import('/var/www/d25_f_web_svcs/src/pages/mypage/components/subscrGuide.vue'),
            /* no children */
          },
  _definePage_default_33
  ),
  _mergeRouteRecord(
          {
            path: 'subscrList',
            name: 'mypage-components-subscr-list',
            component: () => import('/var/www/d25_f_web_svcs/src/pages/mypage/components/subscrList.vue'),
            /* no children */
          },
  _definePage_default_34
  )
        ],
      },
  _mergeRouteRecord(
      {
        path: 'faq',
        name: 'mypage-faq',
        component: () => import('/var/www/d25_f_web_svcs/src/pages/mypage/faq.vue'),
        /* no children */
      },
  _definePage_default_35
  ),
  _mergeRouteRecord(
      {
        path: 'inquiry',
        name: 'mypage-inquiry',
        component: () => import('/var/www/d25_f_web_svcs/src/pages/mypage/inquiry.vue'),
        /* no children */
      },
  _definePage_default_36
  ),
  _mergeRouteRecord(
      {
        path: 'inquiryGuest',
        name: 'mypage-inquiry-guest',
        component: () => import('/var/www/d25_f_web_svcs/src/pages/mypage/inquiryGuest.vue'),
        /* no children */
      },
  _definePage_default_37
  ),
  _mergeRouteRecord(
      {
        path: 'inquiryList',
        name: 'mypage-inquiry-list',
        component: () => import('/var/www/d25_f_web_svcs/src/pages/mypage/inquiryList.vue'),
        /* no children */
      },
  _definePage_default_38
  ),
  _mergeRouteRecord(
      {
        path: 'inquiryView',
        name: 'mypage-inquiry-view',
        component: () => import('/var/www/d25_f_web_svcs/src/pages/mypage/inquiryView.vue'),
        /* no children */
      },
  _definePage_default_39
  ),
  _mergeRouteRecord(
      {
        path: 'moreinfo',
        name: 'mypage-moreinfo',
        component: () => import('/var/www/d25_f_web_svcs/src/pages/mypage/moreinfo.vue'),
        /* no children */
      },
  _definePage_default_40
  ),
  _mergeRouteRecord(
      {
        path: 'myinfo',
        name: 'mypage-myinfo',
        component: () => import('/var/www/d25_f_web_svcs/src/pages/mypage/myinfo.vue'),
        /* no children */
      },
  _definePage_default_41
  ),
  _mergeRouteRecord(
      {
        path: 'profile',
        name: 'mypage-profile',
        component: () => import('/var/www/d25_f_web_svcs/src/pages/mypage/profile.vue'),
        /* no children */
      },
  _definePage_default_42
  ),
      {
        path: 'purchaseComplete',
        name: 'mypage-purchase-complete',
        component: () => import('/var/www/d25_f_web_svcs/src/pages/mypage/purchaseComplete.vue'),
        /* no children */
      },
  _mergeRouteRecord(
      {
        path: 'snsPw',
        name: 'mypage-sns-pw',
        component: () => import('/var/www/d25_f_web_svcs/src/pages/mypage/snsPw.vue'),
        /* no children */
      },
  _definePage_default_43
  ),
  _mergeRouteRecord(
      {
        path: 'subscr',
        name: 'mypage-subscr',
        component: () => import('/var/www/d25_f_web_svcs/src/pages/mypage/subscr.vue'),
        /* no children */
      },
  _definePage_default_44
  ),
  _mergeRouteRecord(
      {
        path: 'subscrFinish',
        name: 'mypage-subscr-finish',
        component: () => import('/var/www/d25_f_web_svcs/src/pages/mypage/subscrFinish.vue'),
        /* no children */
      },
  _definePage_default_45
  ),
  _mergeRouteRecord(
      {
        path: 'subscrFinishBill',
        name: 'mypage-subscr-finish-bill',
        component: () => import('/var/www/d25_f_web_svcs/src/pages/mypage/subscrFinishBill.vue'),
        /* no children */
      },
  _definePage_default_46
  ),
  _mergeRouteRecord(
      {
        path: 'subscrFinishBillEnd',
        name: 'mypage-subscr-finish-bill-end',
        component: () => import('/var/www/d25_f_web_svcs/src/pages/mypage/subscrFinishBillEnd.vue'),
        /* no children */
      },
  _definePage_default_47
  ),
  _mergeRouteRecord(
      {
        path: 'subscrFinishBillFail',
        name: 'mypage-subscr-finish-bill-fail',
        component: () => import('/var/www/d25_f_web_svcs/src/pages/mypage/subscrFinishBillFail.vue'),
        /* no children */
      },
  _definePage_default_48
  ),
  _mergeRouteRecord(
      {
        path: 'subscrFinishFail',
        name: 'mypage-subscr-finish-fail',
        component: () => import('/var/www/d25_f_web_svcs/src/pages/mypage/subscrFinishFail.vue'),
        /* no children */
      },
  _definePage_default_49
  ),
  _mergeRouteRecord(
      {
        path: 'subscrFinishMonth',
        name: 'mypage-subscr-finish-month',
        component: () => import('/var/www/d25_f_web_svcs/src/pages/mypage/subscrFinishMonth.vue'),
        /* no children */
      },
  _definePage_default_50
  ),
  _mergeRouteRecord(
      {
        path: 'subscrFinishMonthEnd',
        name: 'mypage-subscr-finish-month-end',
        component: () => import('/var/www/d25_f_web_svcs/src/pages/mypage/subscrFinishMonthEnd.vue'),
        /* no children */
      },
  _definePage_default_51
  ),
  _mergeRouteRecord(
      {
        path: 'subscrFinishMonthFail',
        name: 'mypage-subscr-finish-month-fail',
        component: () => import('/var/www/d25_f_web_svcs/src/pages/mypage/subscrFinishMonthFail.vue'),
        /* no children */
      },
  _definePage_default_52
  ),
  _mergeRouteRecord(
      {
        path: 'subscrFinishPoint',
        name: 'mypage-subscr-finish-point',
        component: () => import('/var/www/d25_f_web_svcs/src/pages/mypage/subscrFinishPoint.vue'),
        /* no children */
      },
  _definePage_default_53
  ),
  _mergeRouteRecord(
      {
        path: 'subscrFinishPointEnd',
        name: 'mypage-subscr-finish-point-end',
        component: () => import('/var/www/d25_f_web_svcs/src/pages/mypage/subscrFinishPointEnd.vue'),
        /* no children */
      },
  _definePage_default_54
  ),
  _mergeRouteRecord(
      {
        path: 'subscrFinishPointFail',
        name: 'mypage-subscr-finish-point-fail',
        component: () => import('/var/www/d25_f_web_svcs/src/pages/mypage/subscrFinishPointFail.vue'),
        /* no children */
      },
  _definePage_default_55
  ),
  _mergeRouteRecord(
      {
        path: 'subscrGuest',
        name: 'mypage-subscr-guest',
        component: () => import('/var/www/d25_f_web_svcs/src/pages/mypage/subscrGuest.vue'),
        /* no children */
      },
  _definePage_default_56
  ),
  _mergeRouteRecord(
      {
        path: 'subscrPayment',
        name: 'mypage-subscr-payment',
        component: () => import('/var/www/d25_f_web_svcs/src/pages/mypage/subscrPayment.vue'),
        /* no children */
      },
  _definePage_default_57
  ),
  _mergeRouteRecord(
      {
        path: 'subscrPaymentView',
        name: 'mypage-subscr-payment-view',
        component: () => import('/var/www/d25_f_web_svcs/src/pages/mypage/subscrPaymentView.vue'),
        /* no children */
      },
  _definePage_default_58
  ),
  _mergeRouteRecord(
      {
        path: 'subscrPoint',
        name: 'mypage-subscr-point',
        component: () => import('/var/www/d25_f_web_svcs/src/pages/mypage/subscrPoint.vue'),
        /* no children */
      },
  _definePage_default_59
  ),
  _mergeRouteRecord(
      {
        path: 'subscrRepo',
        name: 'mypage-subscr-repo',
        component: () => import('/var/www/d25_f_web_svcs/src/pages/mypage/subscrRepo.vue'),
        /* no children */
      },
  _definePage_default_60
  ),
  _mergeRouteRecord(
      {
        path: 'subscrRepoMonth',
        name: 'mypage-subscr-repo-month',
        component: () => import('/var/www/d25_f_web_svcs/src/pages/mypage/subscrRepoMonth.vue'),
        /* no children */
      },
  _definePage_default_61
  )
    ],
  },
  {
    path: '/saju',
    /* internal name: 'saju' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: '',
        name: 'saju',
        component: () => import('/var/www/d25_f_web_svcs/src/pages/saju/index.vue'),
        /* no children */
      },
  _definePage_default_62
  ),
      {
        path: 'components',
        /* internal name: 'saju-components' */
        /* no component */
        children: [
          {
            path: 'PopAddGroup',
            name: 'saju-components-pop-add-group',
            component: () => import('/var/www/d25_f_web_svcs/src/pages/saju/components/PopAddGroup.vue'),
            /* no children */
          },
          {
            path: 'PopGroupDel',
            name: 'saju-components-pop-group-del',
            component: () => import('/var/www/d25_f_web_svcs/src/pages/saju/components/PopGroupDel.vue'),
            /* no children */
          },
          {
            path: 'PopMemo',
            name: 'saju-components-pop-memo',
            component: () => import('/var/www/d25_f_web_svcs/src/pages/saju/components/PopMemo.vue'),
            /* no children */
          },
          {
            path: 'PopMemoList',
            name: 'saju-components-pop-memo-list',
            component: () => import('/var/www/d25_f_web_svcs/src/pages/saju/components/PopMemoList.vue'),
            /* no children */
          },
          {
            path: 'PopMemoReg',
            name: 'saju-components-pop-memo-reg',
            component: () => import('/var/www/d25_f_web_svcs/src/pages/saju/components/PopMemoReg.vue'),
            /* no children */
          },
          {
            path: 'PopSajuGroupChange',
            name: 'saju-components-pop-saju-group-change',
            component: () => import('/var/www/d25_f_web_svcs/src/pages/saju/components/PopSajuGroupChange.vue'),
            /* no children */
          },
          {
            path: 'PopSajuGroupSetting',
            name: 'saju-components-pop-saju-group-setting',
            component: () => import('/var/www/d25_f_web_svcs/src/pages/saju/components/PopSajuGroupSetting.vue'),
            /* no children */
          },
          {
            path: 'sajuFilter',
            name: 'saju-components-saju-filter',
            component: () => import('/var/www/d25_f_web_svcs/src/pages/saju/components/sajuFilter.vue'),
            /* no children */
          },
          {
            path: 'sajuFilterNew',
            name: 'saju-components-saju-filter-new',
            component: () => import('/var/www/d25_f_web_svcs/src/pages/saju/components/sajuFilterNew.vue'),
            /* no children */
          },
          {
            path: 'sajuGrid',
            name: 'saju-components-saju-grid',
            component: () => import('/var/www/d25_f_web_svcs/src/pages/saju/components/sajuGrid.vue'),
            /* no children */
          },
          {
            path: 'sajuList',
            name: 'saju-components-saju-list',
            component: () => import('/var/www/d25_f_web_svcs/src/pages/saju/components/sajuList.vue'),
            /* no children */
          },
          {
            path: 'sajuOption',
            name: 'saju-components-saju-option',
            component: () => import('/var/www/d25_f_web_svcs/src/pages/saju/components/sajuOption.vue'),
            /* no children */
          },
          {
            path: 'Snackbar',
            name: 'saju-components-snackbar',
            component: () => import('/var/www/d25_f_web_svcs/src/pages/saju/components/Snackbar.vue'),
            /* no children */
          },
          {
            path: 'userGroup',
            name: 'saju-components-user-group',
            component: () => import('/var/www/d25_f_web_svcs/src/pages/saju/components/userGroup.vue'),
            /* no children */
          },
          {
            path: 'userGroupSwipe',
            name: 'saju-components-user-group-swipe',
            component: () => import('/var/www/d25_f_web_svcs/src/pages/saju/components/userGroupSwipe.vue'),
            /* no children */
          },
          {
            path: 'userGroupSwipe_2',
            name: 'saju-components-user-group-swipe2',
            component: () => import('/var/www/d25_f_web_svcs/src/pages/saju/components/userGroupSwipe_2.vue'),
            /* no children */
          }
        ],
      }
    ],
  },
  {
    path: '/schedule_app',
    /* internal name: 'schedule-app' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: '',
        name: 'schedule-app',
        component: () => import('/var/www/d25_f_web_svcs/src/pages/schedule_app/index.vue'),
        /* no children */
      },
  _definePage_default_63
  ),
  _mergeRouteRecord(
      {
        path: 'dev',
        name: 'schedule-app-dev',
        component: () => import('/var/www/d25_f_web_svcs/src/pages/schedule_app/dev.vue'),
        /* no children */
      },
  _definePage_default_64
  )
    ],
  },
  {
    path: '/withdrawal',
    /* internal name: 'withdrawal' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: 'withdrawComplete',
        name: 'withdrawal-withdraw-complete',
        component: () => import('/var/www/d25_f_web_svcs/src/pages/withdrawal/withdrawComplete.vue'),
        /* no children */
      },
  _definePage_default_65
  )
    ],
  }
]
