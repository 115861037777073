<script setup>
import { DEV_MODE } from '@/main';
import FiveStarAppComponent from '../manse/components/FiveStarAppComponent.vue';
import axios from 'axios';
import fiveMixin from '@/composables/fiveMixin';

definePage({ meta: { layout: 'empty' } });

const route = useRoute()

const { ...five } = fiveMixin();

const paramData = ref({
  year: '',
  month: '',
  day: '',
  hour: '',
  min: '',
  gender: null,
  userName: '',
  isLunar: false,
  lunar: null,
  isLeapYear: null,
  bornAreaId: null,
  birth_time: null,
  birth: null,
  birthTime: null,
  unknownTime: null,
  option1: true,
  option2: true,
  option3: false,
  option4: true,
  addressTxt: null,
  lang: 'KOR',
  langYN: 'N',
  userGroupId: null,
  shortly: false,
  blCheck: 'N',
});
const sajuData = ref({});
const inputdata = ref({});
const transdata = ref({});
const birthData = ref({});
const birthEightTxt = ref('');
const daeunsData = ref({});

const langMode = ref('CHNKOR');

/** 페이지 크기조절 */
const manseAppStyle = ref({});
/** 오행도 크기조절 */
const fiveStarStyle = ref({});

const isPageLoading = ref(true);

const accessToken = ref(null);
const authUserId = ref(null);
const guestUserId = ref(null);
const webviewPadding = 16;

onMounted(async () => {
  const vAppWrap = document.querySelector('.v-application__wrap');
  if (vAppWrap) vAppWrap.classList.add('custom-wrap-style');
  updateScale();
  window.addEventListener('resize', updateScale);
  window.addEventListener('message', handleMessage);
  isPageLoading.value = false;
});

onUnmounted(() => {
  const vAppWrap = document.querySelector('.v-application__wrap');
  if (vAppWrap) vAppWrap.classList.remove('custom-wrap-style');
  window.removeEventListener('resize', updateScale);
  window.removeEventListener('message', handleMessage);
});

watch(guestUserId, async (newValue) => {
  if (newValue) {
    await getSajuParams(newValue);
    await getManseForce(newValue);
  }
});

/** 화면 크기에 맞춰 컴포넌트 크기 조정 */
const updateScale = () => {
  const scale = window.innerWidth < 500 ? (window.innerWidth - webviewPadding * 2) / 500 : 1;
  const height = 500 * scale;

  fiveStarStyle.value = {
    transform: `scale(${scale})`,
    transformOrigin: 'center',
    width: '500px',
    minWidth: '500px',
    borderRadius: `${webviewPadding}px`,
  };

  manseAppStyle.value = {
    height: `${height}px`,
    borderRadius: `${webviewPadding}px`,
  };
};

const handleMessage = async (event) => {
  try {
    let data;

    if (typeof event.data === 'string') {
      data = JSON.parse(event.data);
    } else {
      data = event.data;
    }

    // React DevTools 메시지 무시
    if (data.source === 'react-devtools-content-script') {
      return;
    }

    if (data) {
      // guestUserId.value = data.guestUserId;
      accessToken.value = data.accessToken;
      // authUserId.value = data.authUserId;
      guestUserId.value = route.params.id;
    }
  } catch (e) {
    console.error('Invalid message received:', `${e}`);
  }
};

const getDetailAPI = async (id) => {
  let url = `/users/groupGuestUsers/${id}`;
  axios.defaults.headers.common['Authorization'] = accessToken.value;
  const response = await axios.get(url);
  return response.data;
};

const getSajuParams = async (id) => {
  if (!id) return;

  try {
    const result = await getDetailAPI(id);

    convertManseForceParams(result);
  } catch (e) {
    DEV_MODE && console.error('사주정보 조회 오류:', e);
  };
};

const padNumber = (num) => num.toString().padStart(2, '0');
const convertManseForceParams = (result) => {
  const dateTime = result.integrationBirth.split('T');
  const [year, month, day] = dateTime[0].split('-');
  const [hour, min] = dateTime[1]?.split(':');
  
  paramData.value.year = year;
  paramData.value.month = month.padStart(2, '0');
  paramData.value.day = day.padStart(2, '0');
  paramData.value.hour = hour.padStart(2, '0');
  paramData.value.min = min.padStart(2, '0');
  paramData.value.gender = result.gender === 'MEN' ? 1 : 0;
  paramData.value.userName = result.guestUserName;
  paramData.value.isLunar = result.isLuna === null ? false : result.isLuna;
  paramData.value.lunar = result.lunar;
  paramData.value.isLeapYear = result.isLeapYear;
  paramData.value.bornAreaId = result.bornAreaId;
  paramData.value.unknownTime = result.unknownTime
  paramData.value.option1 = result.option1;
  paramData.value.option2 = result.option2;
  paramData.value.option3 = result.option3;
  paramData.value.option4 = result.option4;
  paramData.value.option5 = result.option5;
  paramData.value.addressTxt = result.bornArea;
  paramData.value.birth = `${year}-${padNumber(month)}-${padNumber(day)}`
  // paramData.value.lang = result.lang
  // paramData.value.langYN = result.langYN
  // paramData.value.userGroupId = result.userGroupId
  
  if (result.birthTime === '25:00') {
    paramData.value.birthTime = result.birthTime;
    paramData.value.unknownTime = 'Y';
  } else {
    paramData.value.birthTime = `${padNumber(hour)}:${padNumber(min)}`;
    paramData.value.unknownTime = 'N';
  }
  if (!paramData.value.isLeapYear) paramData.value.isLeapYear = false;
  if (paramData.value.bornAreaId === null) delete paramData.value.bornAreaId;
}

const extractBirthEightTxt = (birthData) => {
  const skyValues = [
    five.getHanjaTxt(birthData.sky.year.value, 'sky'),
    five.getHanjaTxt(birthData.sky.month.value, 'sky'),
    five.getHanjaTxt(birthData.sky.day.value, 'sky'),
    birthData.sky.hour?.value !== '-'
      ? five.getHanjaTxt(birthData.sky.hour.value, 'sky')
      : '-',
  ];
  const earthValues = [
    five.getHanjaTxt(birthData.earth.year.value, 'earth'),
    five.getHanjaTxt(birthData.earth.month.value, 'earth'),
    five.getHanjaTxt(birthData.earth.day.value, 'earth'),
    birthData.earth.hour?.value !== '-'
      ? five.getHanjaTxt(birthData.earth.hour.value, 'earth')
      : '-',
  ];

  birthEightTxt.value = skyValues.join('') + earthValues.join('');  
};

const fastSearchAPI = async (params) => {
  axios.defaults.headers.common['Authorization'] = accessToken.value;
  let url = `/fast/manseForce/`;
  const filteredParams = Object.fromEntries(
    Object.entries(params).filter(([key, value]) => value != null),
  )

  const response = await axios.get(url, {params: filteredParams});

  return response.data;
};

const getManseForce = async (id) => {
  if (!id) return;
  
  try {
    const result = await fastSearchAPI(paramData.value);
    sajuData.value = result;
    inputdata.value = result.inputdata;
    transdata.value = result.transdata;
    birthData.value = result.birthganji;
    daeunsData.value = result.daeuns;
    extractBirthEightTxt(result.birthganji);
  } catch (e) {
    DEV_MODE && console.error('만세력 조회 오류:', e);
  }
};
</script>

<template>
  <div class="manse-app" :style="manseAppStyle">
    <div class="five-star" :style="fiveStarStyle">
      <FiveStarAppComponent
        :birthData="birthData"
        :birthEightTxt="birthEightTxt"
      />
    </div>
  </div>
</template>

<style>
.custom-wrap-style {
  min-height: fit-content !important;
}

.manse-app {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: fit-content;
  width: 100%;
  overflow: hidden;
  pointer-events: none;
  user-select: none;

  .five-star {
    background-color: #fff;
    max-width: 500px;

    .pro-star-box {
      border: none;
    }
  }
}
</style>