<script setup>
// 🧩 일간 팝업 - 로그인 후 보이는 내용들

import IlganPopOutro from './IlganPopOutro.vue';
import page1 from './ilganPopup/page1.vue'
import page2 from './ilganPopup/page2.vue'
import page3 from './ilganPopup/page3.vue'
import page4 from './ilganPopup/page4.vue'
import page5 from './ilganPopup/page5.vue'
import page6 from './ilganPopup/page6.vue'

import IlganPagingBtn from './IlganPagingBtn.vue'

const { ...five } = fiveMixin()

const emit = defineEmits(['showIntro'])

const props = defineProps({
    ilganData:{
        type: Object,
    }
})

const close = () => {
  emit('close')
}

/** 페이지에 따른 컴포넌트 렌더링 */
// 매핑
const pages = {
  1: page1,
  2: page2,
  3: page3,
  4: page4,
  5: page5,
  6: page6,
}
const curPage = ref(1)
const currentPage = ref(pages[curPage.value])
const updatePage = (no) => {
  curPage.value = no
  if (pages[no]) {
    currentPage.value = pages[no]
  }
}

// 페이지에 맞는 데이터 전달하기
const currentPageData = computed(() => {
  return props.ilganData?.pages?.[curPage.value - 1] || {}
})

// 마지막 페이지 데이터 전달하기
const outroData = computed(() => {
    return props.ilganData?.pages?.[6]
})

// 마지막 표지로 이동
const showLast = ref(false)
/** outro로 이동 */
const goToOutro = () => {
    showLast.value = true;
}
const noShowOutro = () => {
    showLast.value = false;
}

// 다시보기
const goToFirst = () =>{
    showLast.value = false;
    // updatePage(1)
    curPage.value = 1;
    emit('showIntro')
}

// 컬러 클래스 매핑을 위한 현재 일간 전달
const curIlgan = computed(() => {
    return props.ilganData.data.ilgan
})

// 아이콘 전달
const curIcon = computed(() => {
    return props.ilganData.icon
})

/** Page 컴포넌트 갯수 */
const numberOfPages = Object.keys(pages).length;

/** 왼쪽 스와이프 → 다음 페이지 이동 */
const onSwipeLeft = () => {
    if (curPage.value < numberOfPages) {
        updatePage(curPage.value + 1)
    } else if (curPage.value = numberOfPages) {
        curPage.value += 1
        goToOutro()
    } else {
        return;
    }
}

/** 오른쪽 스와이프 → 이전 페이지 이동 */
const onSwipeRight = () => {
    if (curPage.value === 1) {
        goToFirst()
    } else if (curPage.value > 1 && curPage.value <= numberOfPages) {
        updatePage(curPage.value - 1)
    } else if (curPage.value > numberOfPages) {
        updatePage(numberOfPages)
        noShowOutro()
    } else {
        return;
    }
}

/** 통합 제스처 핸들러 */
const {
  handleStart,
  handleMove,
  handleEnd
} = useGesture({
  onSwipeLeft: onSwipeLeft,  // 왼쪽 스와이프 → 다음 페이지 이동
  onSwipeRight: onSwipeRight, // 오른쪽 스와이프 → 이전 페이지 이동
  SWIPE_THRESHOLD: 150,
})

</script>

<template>
    <div v-if="!showLast" class=""
        @mousedown="handleStart"
        @mousemove="handleMove"
        @mouseup="handleEnd"
        @touchstart="handleStart"
        @touchmove="handleMove"
        @touchend="handleEnd"  
    >
        <div class="pop-tit-box cont">
            <div class="ganji-box" :class="five.getColorFive(props.ilganData.data.ilgan)" >
                {{  five.getHanjaTxt(props.ilganData.data.ilgan, 'sky') }}
            </div>
            <div class="con-tit">
                <span class="main-tit ganji-txt" :class="five.getColorFive(props.ilganData.data.ilgan)">
                    {{ props.ilganData.icon }} {{ props.ilganData.title }}
                </span>
                <span class="sub-tit" >
                    {{ props.ilganData.subtitle }}
                </span>
            </div>
          <button class="dial-close ilgan" @click="close"></button>
        </div>
        <div class="pop-detail-body cont">
            <div class="ilgan-cont">
                <component
                    :is="currentPage"
                    v-if="currentPage"
                    :contents="currentPageData"
                    :curIlgan="curIlgan"
                    :IlganKC="props.ilganData.title"
                />
            </div>
        </div>
    </div>
        <div v-if="showLast" class="">
        <div class="pop-tit-box">
          <button class="dial-close ilgan" @click="close"></button>
        </div>
        <div class="pop-detail-body cont"
            @mousedown="handleStart"
            @mousemove="handleMove"
            @mouseup="handleEnd"
            @touchstart="handleStart"
            @touchmove="handleMove"
            @touchend="handleEnd"  
        >
            <IlganPopOutro 
                :outroData="outroData"
                :curIcon="curIcon"
                :curIlgan="curIlgan"
                @goToFirst="goToFirst"
            />
        </div>
    </div>
        <div class="paging-btn">
            <IlganPagingBtn 
            :curPage="curPage"
            @updateIndexNo="updatePage"
            @showOutro="goToOutro"
            @noShowOutro="noShowOutro"
            @showCover="goToFirst"
            />
        </div>





</template>