<script setup>
import { onMounted } from 'vue'
import { useStore } from 'vuex'
import moment from 'moment'

import { DEV_MODE } from '@/main'

/** API */
import { fastSearch } from '@/api/Eoway'
import { getDetail, getGrouplist, getGroupUserlist } from '@/api/UserGroup'

/** composables */
import fiveMixin from '@/composables/fiveMixin'
import useFunctions from '@/composables/useFunctions'
import { useCalendar, useCalendarFilter, useGesture } from '@/composables'

/** 컴포넌트 */
import FullCalendar from '@fullcalendar/vue3'
import ComLatest from '@/components/manse/ComLatest.vue'
import CalendarHeader from '@/components/calendar/CalendarHeader.vue'
import SajuClockPopup from '@/components/calendar/SajuClockPopup.vue'
import ToggleBox from '@/components/calendar/ToggleBox.vue'
import TodayLuckInnerView from '@/components/calendar/TodayLuckInnerView.vue'
import AlertComponent from '@/pages/manse/components/Alert.vue'
import CalendarSidebar from '@/components/calendar/CalendarSidebar.vue'

/** 스타일 */
import '@/assets/nangman_style/calendar/calendar_web.scss';
import { addContentLogAPI } from '@/api/logServiceApi'

definePage({ meta: { layout: 'manse' } })

const store = useStore()
const userId = ref(null)
userId.value = store.getters['user/id']

const { getFiveClass, getGanjiColorFive, ...five } = fiveMixin();
const { ...useFunc } = useFunctions();

/** 로딩 인디케이터 상태 */
const isLoading = ref(false);
/** '사주시계' 팝업 상태 */
const isPopSajuClock = ref(false);
/** '사주시계' 참조 */
const currentClockRef = ref(null);
/** alert 참조 */
const dialogAlert = ref(null);
const dialogTitle = ref('');
const dialogText = ref('');

/** 일진 보기*/
const isDailyFortuneCalendarOpen = ref(false);

const isInnerViewOpen = ref(false);

const sajuListParam = ref({
  birthStart: null,
  birthEnd: null,
  startDate: null,
  endDate: null,
  genderOption: 0,
  page: 1,
  size: 10,
  searchKeyWord: '',
  sortOption: null,
  userGroupId: null,
  activeOpt1: false,
  activeOpt2: false,
  activeOpt3: false,
  activeOpt4: false,
  fiveEles: [],
  gender: null,
  filterFlag: false,
  isFavorite: null,
})

const sajuParamData = ref({
  year: '',
  month: '',
  day: '',
  hour: '',
  min: '',
  gender: null,
  userName: '',
  isLunar: false,
  lunar: null,
  isLeapYear: null,
  bornAreaId: null,
  birth_time: null,
  birth: null,
  birthTime: null,
  unknownTime: null,
  option1: true,
  option2: true,
  option3: false,
  option4: true,
  addressTxt: null,
  lang: 'KOR',
  langYN: 'N',
  userGroupId: null,
  shortly: false,
  blCheck: 'N',
});

const sajuData = ref({});

const showDialogAlert = (title, text = '', nextUrl = '') => {
  dialogTitle.value = title;
  dialogText.value = text;
  dialogAlert.value.openAlertDialog();
};

const openInnerView = () => {
  showDialogAlert('일진달력 서비스 오픈 준비 중\n여러분의 일상에 새로운 의미를 더해드릴게요.\n많은 기대와 관심으로 조금만 기다려주세요!');
  return;
  
  if (!userId.value) return;
  if (info) selectedDateInfo.value = info;
  if (isInnerViewOpen) {
    const dataKey = moment(info.date).format('YYYYMMDD');
    dailyLuckData.value = monthlyLuckData[dataKey];
    isInnerViewOpen.value = true;
  } // 이너뷰 열기
}

/** 달력 필터 */
const {
  isLunarChecked,
  isSeasonChecked,
  isHolidayChecked,
  isKorChecked,
  isColorChecked,
  toggleOptions,
} = useCalendarFilter({isKorCheckedDefault: true});

/** 달력 생성 */
const {
  calendarOptions,
  refCalendar,
  viewTitle,
  selectedDate,
  selectedData,
  firstSeason,
  firstSeasonMonth,
  prev,
  next,
  isToday,
  changeViewTitle,
  closeInnerView,
  setPrevSelectedDate,
} = useCalendar({
  isLoading,
  isLunarChecked,
  isColorChecked,
  isSeasonChecked,
  isKorChecked,
  isHolidayChecked,
  calendarType: 'web',
  isInnerViewOpen,
  isDailyFortuneCalendarOpen,
  userId,
  sajuData,
  openInnerView,
});

/** 통합 제스처 핸들러 */
const {
  handleStart,
  handleMove,
  handleEnd
} = useGesture({
  onSwipeLeft: next,
  onSwipeRight: prev,
});

onMounted(async () => {
  if (DEV_MODE) {
    const groupList = await getGrouplist({});
  
    if (groupList && groupList.length > 0) {
      sajuListParam.userGroupId = groupList[0].userGroupId;
      const result = await getGroupUserlist(sajuListParam);
      const mySajuList = result.content;
      if (mySajuList && mySajuList.length > 0) {
        const guestUserId = mySajuList[mySajuList.length - 1].guestUserId;
        if (guestUserId) {
          await getSajuParams(guestUserId);
          await getManseForce();
        }
      }
    }
  }
})

const getSajuParams = async (id) => {
  if (!id) return;

  try {
    const result = await getDetail(id);

    const dateTime = result.integrationBirth.split('T');
    const [year, month, day] = dateTime[0].split('-');
    const [hour, min] = dateTime[1]?.split(':');    
    
    sajuParamData.value.year = year;
    sajuParamData.value.month = month.padStart(2, '0');
    sajuParamData.value.day = day.padStart(2, '0');
    sajuParamData.value.hour = hour.padStart(2, '0');
    sajuParamData.value.min = min.padStart(2, '0');
    sajuParamData.value.gender = result.gender === 'MEN' ? 1 : 0;
    sajuParamData.value.userName = result.guestUserName;
    sajuParamData.value.isLunar = result.isLuna === null ? false : result.isLuna;
    sajuParamData.value.lunar = result.lunar;
    sajuParamData.value.isLeapYear = result.isLeapYear;
    sajuParamData.value.bornAreaId = result.bornAreaId;
    sajuParamData.value.birthTime = result.birthTime;
    sajuParamData.value.unknownTime = result.unknownTime
    sajuParamData.value.option1 = result.option1;
    sajuParamData.value.option2 = result.option2;
    sajuParamData.value.option3 = result.option3;
    sajuParamData.value.option4 = result.option4;
    sajuParamData.value.option5 = result.option5;
    sajuParamData.value.addressTxt = result.bornArea;
    // sajuParamData.value.lang = result.lang
    // sajuParamData.value.langYN = result.langYN
    // sajuParamData.value.userGroupId = result.userGroupId
  } catch (e) {
    DEV_MODE && console.error('사주정보 조회 오류:', e);
  };
};

const padNumber = (num) => num.toString().padStart(2, '0');

const convertManseForceParams = () => {
  const { year, month, day, hour, min } = sajuParamData.value;

  sajuParamData.value.birth = `${year}-${padNumber(month)}-${padNumber(day)}`;  
  
  if (sajuParamData.value.birthTime === '25:00') {
    sajuParamData.value.unknownTime = 'Y';
  } else {
    sajuParamData.value.birthTime = `${padNumber(hour)}:${padNumber(min)}`;
  }
  if (!sajuParamData.value.unknownTime) sajuParamData.value.unknownTime = 'N';
  if (!sajuParamData.value.isLeapYear) sajuParamData.value.isLeapYear = false;
  if (sajuParamData.value.bornAreaId === null) delete sajuParamData.value.bornAreaId;
}

const getManseForce = async () => {
  convertManseForceParams();

  try {    
    const result = await fastSearch(sajuParamData.value);    
    sajuData.value = result;
  } catch (e) {
    DEV_MODE && console.error('만세력 조회 오류:', e);
  }
}

/** TODAY 버튼 클릭 */
const today = () => {
  refCalendar.value.getApi().gotoDate(new Date())
  setPrevSelectedDate()
  selectedDate.value = moment().format('YYYY-MM-DD')
  changeViewTitle()
}

/** 사주시계 팝업 상태관리 */
const setSajuClock = (state) => {
  if (state) addContentLogAPI('C_saju_watch');
  isPopSajuClock.value = state;
}

const createFiveElements = (fiveData) => {
  if (!fiveData) return;
};

/** '일진달력' 버튼 클릭 */
const clickTodayLuckCalendar = () => {
  // isDailyFortuneCalendarOpen = true;
  openInnerView();
};
</script>

<template>
  <div class="calendar-wrap web v2">
    <div class="content-main mb-large">
      <section class="inner-base-calendar v2">
        <div class="com-tit-exp short" />
        <div class="calendar-sidebar-layout">
          <CalendarSidebar
            :isDailyFortuneCalendarOpen="isDailyFortuneCalendarOpen"
            :toggleOptions="toggleOptions"
            :sajuData="sajuData"
            :isOpenSajubox="isOpenSajubox"
            @openDailyFortune="clickTodayLuckCalendar"
            @closeDailyFortune="isDailyFortuneCalendarOpen = false"
            @openSajuBox="openPopMySaju"
            @closeSajuBox="isOpenSajubox = !isOpenSajubox"
          />

          <div class="calendar-main">
            <CalendarHeader
              :calendarType="'v2'"
              :title="viewTitle"
              :isToday="isToday()"
              :solarTerm="firstSeason"
              :solarTermMonth="firstSeasonMonth"
              :isColorChecked="isColorChecked"
              @prev="prev"
              @next="next"
              @today="today"
              @clock="setSajuClock(true)"
            />

            <div class="sec-sch-body">
              <v-row
                class="fill-height"
                @mousedown="handleStart"
                @mousemove="handleMove"
                @mouseup="handleEnd"
                @touchstart="handleStart"
                @touchmove="handleMove"
                @touchend="handleEnd"
              >
                <v-col>
                  <v-sheet height="700">
                    <FullCalendar
                      ref="refCalendar"
                      class="full-calendar web"
                      :options="calendarOptions"
                    />
                  </v-sheet>
                </v-col>
              </v-row>
            </div>
          </div>
        </div>
      </section>
    </div>

    <div v-if="isLoading" class="loading-full-wrap">
      <div class="inner-box">
        <div ref="loadingContainer" class="loading-container">
          <v-progress-circular :size="50" :width="3" color="primary" indeterminate />
        </div>
      </div>
    </div>

    <SajuClockPopup
      v-if="isPopSajuClock"
      ref="currentClockRef"
      @closeClock="setSajuClock(false)"
    />

    <AlertComponent
      ref="dialogAlert"
      :title="dialogTitle"
      :text="dialogText"
    />
  </div>
</template>