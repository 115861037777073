<script setup>
import { useAnalysisStore } from '@/store/modules/analysis'

import FiveStartComponent from '@/pages/manse/components/FiveStartComponent.vue'

import ContentsList from '@/pages/analysis/components/ContentsList.vue'

import { defineProps } from 'vue'
const props = defineProps({
  birthData: {
    type: Object,
  },
  birthEightTxt: {
    type: String,
  },
})

const store = useAnalysisStore()

const indexNo = ref(1)

const updateIndexNo = (id) => {
  indexNo.value = id
}

const rootPage = ref('analysis')
</script>

<template>
  <div
    v-if="store.selectedMenu.endsWith('my')"
    class="chart-section full-height full-center five-round-bg"
  >
    <FiveStartComponent
      ref="starRef"
      :birthData="birthData"
      :birthEightTxt="birthEightTxt"
      :rootPage="rootPage"
      @skyDay-click="handleSkyDayClick"
      @skyDay-reset="resetSkyDay"
      @open-ilgan="openIlgan"
      @close-ilgan="closePopIlgan()"
      @openPopFiveStar="openPopFiveStar"
      @closePopFiveStar="closePopFiveStar"
      @clickIlganButton="clickIlganButton"
    />
  </div>
  <div v-if="store.selectedMenu.endsWith('lunar')" class="chart-section">22</div>
  <div v-if="store.selectedMenu.endsWith('hap')" class="chart-section">33</div>
  <div v-if="store.selectedMenu.endsWith('deaun')" class="chart-section">44</div>
  <div v-if="store.selectedMenu.endsWith('sin')" class="chart-section">55</div>
</template>
<style>
@import '/src/assets/nangman_style/personality.scss';
</style>
